function setupQuestionnaireSubmission() {
  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_submit_questionnaire')) {
      event.preventDefault();
      var button = event.target;

      var form = button.closest('form');
      removeElementsByClass('error-speech-bubble');

      var isValid = form.checkValidity();
      if (isValid == false) {
        form.reportValidity();
        return;
      }

      var forms = button.closest('.popup-model__right').querySelectorAll('form');
      var fields = {};
      var cssErrorClass = '';

      for (var i = 0; i < forms.length; i++) {
        var form = forms[i];
        var formData = new FormData(form);
        var iterator = formData.entries();
        var current = iterator.next();
        while (!current.done) {
          var pair = current.value;
          if (typeof (fields[pair[0]]) !== 'undefined') {
            fields[pair[0]] = fields[pair[0]] + '|||-|||' + pair[1];
          } else {
            fields[pair[0]] = pair[1];
          }
          if (pair[0] == '_id') {
            cssErrorClass = '.questionnaire_' + pair[1] + '-';
          }
          current = iterator.next();
        }
      }

      fields['_referer'] = window.location.href;

      var sendData = new FormData(form);
      sendData.append('data', JSON.stringify(fields));

      var options = {
        method: 'POST',
        body: sendData
      };

      button.disabled = true;
      var url = '/' + getCurrentLocale() + '/' + button.getAttribute('data-backend');

      sendRequest(url, options).then(function (success) {
        button.disabled = false;
        popupGotoSection(button, 0);
        formTogglePopupSuccess(button);
        console.log(success);
      }).catch(function (errors) {
        var form = button.closest('form');
        handleFormErrors(errors.error, form, cssErrorClass);
        button.disabled = false;
        console.log(errors);
      });
    }
  });
}

setupQuestionnaireSubmission();

function setupSummaryEditSection() {
  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_summary-edit-section')) {
      event.preventDefault();
      var button = event.target;
      var index = button.getAttribute('data-sectionindex') - 1;
      popupGotoSection(button, index);
    }
  });
}
setupSummaryEditSection();

function setupQuestionnaireSummary(section) {
  var summary = section.querySelector('.questionnaire-summary');
  if (summary) {

    var model = section.closest('.popup-model__right');
    var screenHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    var bullets = model.querySelector('.popup-bullets').offsetHeight;
    var title = section.querySelector('h2').offsetHeight;
    var buttons = section.querySelector('.popup-model__btn-group-container').offsetHeight;

    summary.style.height = (screenHeight - 63  - title - buttons - bullets) / 2 + 'px';

    var summaryTitle = section.querySelector('h2').innerText;
    var html = [];
    html = html.concat([
      '<table class="w-100">',
      '<tbody>',
    ]);
    

    var sections = model.querySelectorAll('section[data-index]');

    for (var i = 0; i < sections.length; i++) {
      var title = sections[i].querySelector('h2').innerText;
      if (title == summaryTitle) {
        break;
      }
      var dataIndex = sections[i].getAttribute('data-index');
      html = html.concat([
        '<tr>',
          '<td class="text-left v-align-top"><div class="u_mb-1 u_pr-05"><b>' + title + '</b></div></td>',
          '<td class="text-right v-align-top"><div class="u_mb-1 white-space-nowrap is-a-link js_summary-edit-section" data-sectionindex="' + dataIndex + '">' + jsTranslations['Edit'] + '</div></td>',
        '</tr>'
      ]);

      var form = sections[i].querySelector('form');
      var formData = new FormData(form);
      var iterator = formData.entries();
      var current = iterator.next();
      var prevLabel = '';
      while (!current.done) {
        var pair = current.value;
        var field = model.querySelector('[name="'+pair[0]+'"]');
        if (field.getAttribute('type') !== 'hidden') {
          var label = field.getAttribute('data-question');
          if (label.slice(-1) != ':') {
            label = label + ':';
          }
          var currentLabel = label;
          if (label == prevLabel) {
            currentLabel = '';
          }
          prevLabel = label;
          html = html.concat([
            '<tr>',
              '<td class="text-left v-align-top"><div class="u_mb-05 u_pr-05">' + currentLabel + '</div></td>',
              '<td class="text-right v-align-top"><div class="u_mb-05 white-space-nowrap">' + pair[1] + '</div></td>',
            '</tr>'
          ]);
        }
        current = iterator.next();
      }

      html = html.concat([
        '<tr>',
          '<td class="text-left v-align-top"><div>&nbsp;</div></td>',
          '<td class="text-right v-align-top"><div>&nbsp;</div></td>',
        '</tr>'
      ]);
    }

    html = html.concat([
      '</tbody>',
      '</table>',
    ]);
    summary.innerHTML = html.join('\n');

  }
}

function questionnaireShowHiddenFields(show, hiddenItem) {
  if (show == true) {
    hiddenItem.classList.remove('d-none');
    var fields = hiddenItem.querySelectorAll('[data-required]');
  } else {
    hiddenItem.classList.add('d-none');
    var fields = hiddenItem.querySelectorAll('[required]');
  }

  Array.from(fields).forEach(function (field) {
    if (show == true) {
      field.removeAttribute('data-required');
      field.setAttribute('required', '');
    } else {
      field.removeAttribute('required');
      field.setAttribute('data-required', '');
      if (field.type == 'radio') {
        field.checked = false;
      }
    }
    if (field.hasAttribute('data-hide-id')) {
      var showId = field.getAttribute('data-hide-id');
      var hiddenChildItems = document.querySelectorAll('.questionnaire-model [data-show-id="'+showId+'"]');
      Array.from(hiddenChildItems).forEach(function (hiddenChildItem) {
        questionnaireShowHiddenFields(false, hiddenChildItem);
      });
    }
  });
}

function questionnaireShowHiddenQuestions_radio() {
  var radios = document.querySelectorAll('.questionnaire-model input[type="radio"][data-hide-id]');
  Array.from(radios).forEach(function (radio) {
    var showId = radio.getAttribute('data-hide-id');
    document.addEventListener('input', function (event) {
      if (event.target && event.target.matches('input[type="radio"][name="'+radio.name+'"]')) {
        event.preventDefault();
        var element = event.target;
        var hiddenItems = document.querySelectorAll('.questionnaire-model [data-show-id="'+showId+'"]');
        Array.from(hiddenItems).forEach(function (hiddenItem) {
          if (element.value == radio.value) {
            questionnaireShowHiddenFields(true, hiddenItem);
          } else {
            questionnaireShowHiddenFields(false, hiddenItem);
          }
        });
      }
    });
  });
}

questionnaireShowHiddenQuestions_radio();
function launchVideoPlayer() {

  document.addEventListener('click', function(event){
    if(event.target && event.target.matches('.js_video-thumbnail, .js_video-thumbnail *')) {

      event.preventDefault();

      var videoPlayerContainer = document.querySelector('.js_video-container');
      var videoPlayer = document.querySelector('.js_product-page-video-player');
      var overlay = document.querySelector('.js_product-page-overlay');
      var closeVideo = document.querySelector('.js_close-video');

      // pause the video when it's closed.
      function pauseVideo() {
        videoPlayer.setAttribute('src', videoPlayer.getAttribute('src'));
      };

      // close video player
      function closeVideoPlayer() {
        videoPlayerContainer.classList.remove('product-page__video-player--is-visible');
        overlay.classList.remove('product-page__overlay--is-visible');
        pauseVideo();
      };

      // launch video player
      function openVideoPlayer() {
        var parentElm = event.target;
        while (parentElm.querySelector('.js_video-thumbnail') === null) {
            parentElm = parentElm.parentElement;
        }
        var linkToAssign = parentElm.querySelector('[data-video-url]').getAttribute('data-video-url');
        videoPlayer.setAttribute('src', linkToAssign);
        videoPlayerContainer.classList.add('product-page__video-player--is-visible');
        overlay.classList.add('product-page__overlay--is-visible');
      };

      openVideoPlayer();
      
      overlay.addEventListener('click', function() {
        closeVideoPlayer();
      });

      closeVideo.addEventListener('click', function() {
        closeVideoPlayer();
      });
    };
  });
};

launchVideoPlayer();
var contactForm = document.querySelectorAll('.form__nav-contact');

if (contactForm) {

    function contactFormErrors(errors) {
        Array.from(contactForm).forEach(function (contact) {
            for (var key in errors) {
                var node = createErrorNode(errors[key]);

                if (key == 'form') {
                    contact.appendChild(node);
                } else {
                    var elem = contact.querySelector('.contact-' + key);
                    elem.parentElement.appendChild(node);
                }
            }
        });
    }

    var submitBtn = document.querySelector('.js_contact-submit');

    if (submitBtn) {
        Array.from(contactForm).forEach(function (contact) {
            var submitBtn = contact.querySelector('.js_form-submit');

            if (submitBtn) {

                submitBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    var form = submitBtn.closest('form');
                    var isValid = form.checkValidity();
                    if (isValid == false) {
                        form.reportValidity();
                        return false;
                    }

                    var btnValue = submitBtn.value;
                    submitBtn.disabled = true;
                    submitBtn.value = submitBtn.getAttribute("data-sending");

                    removeElementsByClass('error-speech-bubble');

                    var formData = new FormData(contact);

                    var options = {
                        method: 'POST',
                        credentials: 'include',
                        body: formData
                    };

                    var formType = submitBtn.closest('.popup-model[data-model]');
                    if (formType !== null) {
                        formType = formType.getAttribute('data-model');
                    } else {
                        formType = 'contact';
                    }

                    sendRequest('/' + getCurrentLocale() + '/' + formType + '/submit', options).then(function (success) {
                        if (submitBtn.closest('.popup-model') === null) {
                            var successPage = contact.parentElement.querySelector('.contact-model-success');
                            formToggleSuccess(contact, successPage);
                        } else {
                            closeModel(formType);
                            if (formType == 'contact') {

                                if ((typeof (website_contact_header_success_page) !== 'undefined') && (contactFormFromNav == true)) {
                                    document.cookie = "thank_you_page=contact; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
                                    setTimeout(function () {
                                        window.location.href = website_contact_header_success_page;
                                    }, 250);
                                }
                                else if ((typeof (website_contact_success_page) !== 'undefined') && (contactFormFromNav == false)) {
                                    document.cookie = "thank_you_page=contact; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
                                    setTimeout(function () {
                                        window.location.href = website_contact_success_page;
                                    }, 250);
                                }
                                else {
                                    openModel('contact-success');
                                }
                            }
                            else {
                                document.cookie = "thank_you_page=contact; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
                                setTimeout(function () {
                                    window.location.href = '/' + getCurrentLocale() + '/success-contact' + uniqueContactForm;
                                }, 250);
                            }
                        }
                    }).catch(function (errors) {
                        contactFormErrors(errors.error);
                        submitBtn.disabled = false;
                        submitBtn.value = btnValue;
                    });

                });
            }
        });
    }
}

function openUniqueContact() {
    if (typeof (uniqueContactForm) !== 'undefined') {
        document.addEventListener('click', function (event) {
            var className = 'open-contact' + uniqueContactForm;
            if (event.target && event.target.matches('.' + className + ', .' + className + ' *')) {
                event.preventDefault();
                openModel('contact' + uniqueContactForm);
            }
        });
    }
}
openUniqueContact();

function openContactViaPimcoreLink() {
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('a[href="#open-contact"], a[href="#open-contact"] *')) {
            event.preventDefault();
            openModel('contact');
        }
    });
}
openContactViaPimcoreLink();
function getParameterByName(name) {
    var url = window.location.href;
    var newName = name.replace(/[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + newName + '(=([^&#]*)|&|#|$)');
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getActiveLimit() {
    return document.querySelector('.pagination__page-counts strong').innerHTML;
}

//Used to take an object with key value pairing and then convert them to a formData object.
function prepareFormData(params) {
    var formData = new FormData;

    for(var key in params) {
        formData.append(key, params[key]);
    }

    return formData;
}

//Function used to remove elements by class name.
function removeElementsByClass(className) {
    var elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
    }
}

//Function used to create error messages for form elements
function createErrorNode(text) {
    var node = document.createElement('div');
    var mainSpan = document.createElement('span');
    var span = document.createElement('span');
    var textnode = document.createTextNode(text);
    var errortext = document.createTextNode('Error: ');
    node.appendChild(mainSpan);
    mainSpan.appendChild(span);

    span.classList.add('bold');
    span.appendChild(errortext);
    mainSpan.appendChild(textnode);
    node.className = 'error-speech-bubble';

    return node;
}

function activateSubmitButton(form, submitBtn) {
    var isValid = form.checkValidity();
    if (isValid) {
        submitBtn.disabled = false;
    } else {
        submitBtn.disabled = true;
    }
}

function validateForms() {
    var forms = document.querySelectorAll('.js_form_validation');

    Array.from(forms).forEach(function(form) {
        var submitBtn = form.querySelector('.js_form-submit');
        var inputs = form.querySelectorAll('input, textarea');

        for(var i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('input', function(event) {
                activateSubmitButton(form, submitBtn);
            });
        }

        var selects = form.querySelectorAll('select');

        for(var j = 0; j < selects.length; j++) {
            selects[j].addEventListener('change', function(event) {
                activateSubmitButton(form, submitBtn);
            });
        }
    })
}

function formToggleSuccess(form, successPage) {
    if(successPage.classList.contains('d-none')) {
        successPage.classList.add('d-block');
        successPage.classList.remove('d-none');

        form.classList.add('d-none');
        form.classList.remove('d-block');
    } else {
        successPage.classList.add('d-none');
        successPage.classList.remove('d-block');

        form.classList.add('d-block');
        form.classList.remove('d-none');
    }
}

function formTogglePopupSuccess(form) {

    var model = form.closest('.popup-container');

    if (hasClass(model, 'popup-mobile-slider')) {
        slideHome();
    } else {
        model.classList.add('d-none');
    }

    var inputs = model.querySelectorAll('input');
    for(var i = 0; i < inputs.length; i++) {
        if (inputs[i].type == 'text') {
            inputs[i].value = '';
        }
        else if (inputs[i].type == 'checkbox') {
            inputs[i].checked = false;
        }
        else if (inputs[i].type == 'radio') {
            inputs[i].checked = false;
        }
    }

    var inputs = model.querySelectorAll('textarea');
    for(var i = 0; i < inputs.length; i++) {
        inputs[i].value = '';
    }

    var selects = model.querySelectorAll('select');
    for(var j = 0; j < selects.length; j++) {
        selects[j].value = '';
    }

    var icon = model.getAttribute('data-icon');
    var title = model.getAttribute('data-title');
    var message = model.getAttribute('data-message');
    openModelMessage(title, message, icon);

}

function togglePageSlider(slider) {
    if (slider) {
        toggleClass(slider, 'step-1');
        removeClass(slider, 'step-2');
        removeClass(slider, 'step-3');
    }
}

function handleFormErrors(errors, form, prefix) {
    for(var key in errors) {
        var node = createErrorNode(errors[key]);

        if(key == 'form') {
            form.appendChild(node);
        } else {
            var elem = form.querySelector(prefix + key);
            elem.parentElement.appendChild(node);
        }
    }
}

function sendRequest(url, options) {
    return new Promise(function(resolve, reject) {
        fetch(url,options)
            .then(function (res) {
                return res.json();
            }).then(function (res) {
            if (res.success) {
                resolve(res);
            } else {
                reject(res);
            }
        }).catch(reject);
    });
}

function getCurrentLocale() {
    var locale = window.location.pathname.split('/')[1];

    if(locale) {
        return locale;
    }

    return 'en';
}

//Used to redirect to the same url but with a new locale
function redirectWithLocale(locale) {
    return window.location.href.replace(/\/\/(.*?)\/(.*?)(\/|$)/, function (a, b, c) {
        return "//" + b + '/' + locale + '/';
    });
}

var jsTranslations = document.getElementById('js-translations');
if(jsTranslations) {
    jsTranslations = JSON.parse(jsTranslations.innerHTML);
}

validateForms();

function multiSelectSetup() {
    document.addEventListener('mousedown', function (event) {
        if (event.target && event.target.matches('select[data-multiple]')) {
            var select = event.target;
            select.setAttribute('multiple', 'multiple');
            var size = select.getAttribute('data-size');
            select.setAttribute('size', size);
            select.removeAttribute('data-multiple');
            select.removeAttribute('data-size');
            select.closest('.field__select').classList.add('hide-dropdown-icon');

            var options = select.querySelectorAll('option');
            Array.from(options).forEach(function(option) {
                option.selected = false;
            });
        }

        if (event.target && event.target.matches('select[multiple] option')) {
            var opt = event.target;
            if (opt.selected){
                opt.removeAttribute('selected');
                opt.selected = false;
            } else {
                opt.setAttribute('selected', '');
                opt.selected = true;
            }
            event.preventDefault();
        }
    });
}

multiSelectSetup();
var informationForm = document.querySelector('form.information-page');

if(informationForm) {
    var accountBtn = informationForm.querySelector('.js_form-submit');

    accountBtn.addEventListener('click', function(event) {
        removeElementsByClass('error-speech-bubble');
        event.preventDefault();

        var formData = new FormData(informationForm);

        var options = {
            method: 'POST',
            credentials: 'include',
            body: formData
        };

        sendRequest('/' + getCurrentLocale() + '/api/customer/portal/informationSave', options).then(function(success) {
           accountBtn.value = jsTranslations.userFormSuccess;
           var savedText = informationForm.querySelector('.success-text');
           savedText.classList.add('d-block');
           savedText.classList.remove('d-none');
        }).catch(function(errors) {
            handleFormErrors(errors.error, informationForm, '#user-');
        });
    });
}
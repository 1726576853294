/**
 * New eCommerce Filters
 */

function scrollToShop() {
    var url = window.location.href;
    if (url.indexOf('?') != -1) {
        var shopView = document.getElementById('shop-view');
        if (shopView) {
            var scrollTop = shopView.offsetTop - 40;
            window.scrollTo(0, scrollTop);
        }
    }
}

function filtersTrigger() {
    document.addEventListener('change', function(event) {
        if (event.target.matches('#js_filterfield input[type=checkbox], #js_filterfield input[type=radio]')) { 
            document.getElementById('js_filterfield').submit();
        }
    });
}

function sortByDropdownTrigger() {
    var sortBy = document.querySelector('.sort-by');

    if (sortBy) {
        
        if (document.querySelector('.sort-for-eCommerce')) {

            var orderChoices = document.querySelectorAll('.sort-container input');

            if (orderChoices) {

                document.addEventListener('change',function(event){
            
                    if(event.target && event.target.matches('.sort-container input')){
                        event.preventDefault();
                        var item = event.target;
                        window.location.href = window.location.href.split('?')[0] + "?" + item.getAttribute('data-filter-param');
                    }

                });

            }
        }
    }
}

filtersTrigger();
sortByDropdownTrigger();

ready(function() {
    scrollToShop();
});

function sortByZIndexToggle(toggle) {

    var sortByZindex = document.querySelector('.sort-by-zindex');

    if (sortByZindex) {

        if (toggle == true) {
            sortByZindex.classList.add('sort-by-zindex-zero');

            var sortBy = sortByZindex.querySelector('.sort-by')
            if (sortBy) {

                var dropDown = sortBy.parentElement.querySelector('ul');
                var svg = sortBy.parentElement.querySelector('svg');
    
                if(dropDown.classList.contains('d-none') == false) {
                    dropDown.classList.remove('d-block');
                    dropDown.classList.add('d-none');
                    svg.setAttribute('transform', 'rotate(0)')
                }
            }

        } else {
            sortByZindex.classList.remove('sort-by-zindex-zero');
        }
    }
}
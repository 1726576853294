if (document.getElementById("configurator-quote-slider")) {
  /*
    Minimum browser support
    ------------------------
  
    Chrome              8+
    Edge                12+
    Firefox             3.6+
    Internet Explorer   10+
    Opera               7+
    Safari              7+
  */

  function optionsCreator(data, unit) {
    return data.map(function (item) {
      return '<option value="' + item + '">' + item + ' ' + unit + '</option>';
    }).join('');
  };

  var emptyOption = '<option value="">Select</option>';


  function startConfigurator() {
    configuratorVaribles();

    loadEl.innerHTML = emptyOption + optionsCreator(load, 'kg');
    suspEl.innerHTML = emptyOption + optionsCreator(suspension, '');
    extendBarEl.innerHTML = emptyOption + optionsCreator(limitSwitch, '');
    electricSpeedEl.innerHTML = emptyOption + optionsCreator(electricSpeed, '');
    limitSwitchEl.innerHTML = emptyOption + optionsCreator(limitSwitch, '');
    ctrlEl.innerHTML = emptyOption + optionsCreator(control, '');

    loadEl.addEventListener('change', handleLoadChange);
    numFallsEl.addEventListener('change', handleNumFallsChange);
    liftSpeedEl.addEventListener('change', handleLiftSpeedChange);
    liftHeightEl.addEventListener('change', handleLiftHeightChange);
    phaseEl.addEventListener('change', handlePhaseChange);
    freqEl.addEventListener('change', handleFreqChange);
    voltEl.addEventListener('change', handleVoltChange);
    suspEl.addEventListener('change', handleSuspensionChange);
    ctrlEl.addEventListener('change', handleControlChange);
    ctrlCableEl.addEventListener('change', handleCtrlCableChange);
    extendBarEl.addEventListener('change', handleExtendBarChange);
    limitSwitchEl.addEventListener('change', handleLimitSwitchChange);
    electricSpeedEl.addEventListener('change', handleElectricSpeedChange);
  }

  function configuratorVaribles() {
    loadEl = document.getElementById('configuratorLoad');
    numFallsEl = document.getElementById('configuratorNumFalls');
    liftSpeedEl = document.getElementById('configuratorLiftSpeed');
    liftHeightEl = document.getElementById('configuratorLiftHeight');

    phaseEl = document.getElementById('configuratorPhase');
    freqEl = document.getElementById('configuratorFreq');
    voltEl = document.getElementById('configuratorVolt');
    suspEl = document.getElementById('configuratorSuspension');
    extendBarEl = document.getElementById('configuratorExtendBar');
    electricSpeedEl = document.getElementById('configuratorElectricSpeed');
    limitSwitchEl = document.getElementById('configuratorLimitSwitch');
    ctrlEl = document.getElementById('configuratorControl');
    ctrlCableEl = document.getElementById('configuratorCtrlCable');

    bodyCodeEl = document.getElementById('configuratorBodyCode');
    bodyCodeDescEl = document.getElementById('configuratorBodyCodeDesc');
    bodyQtyEl = document.getElementById('configuratorBodyQty');
    bodyUnitPriceEl = document.getElementById('configuratorBodyUnitPrice');
    bodyTotalPriceEl = document.getElementById('configuratorBodyTotalPrice');

    suspCodeEl = document.getElementById('configuratorSuspCode');
    suspDescEl = document.getElementById('configuratorSuspDesc');
    suspQtyEl = document.getElementById('configuratorSuspQty');
    suspUnitPriceEl = document.getElementById('configuratorSuspUnitPrice');
    suspTotalPriceEl = document.getElementById('configuratorSuspTotalPrice');

    extendBarRowEl = document.getElementById('configuratorExtendBarRow');
    extendBarCodeEl = document.getElementById('configuratorExtendBarCode');
    extendBarDescEl = document.getElementById('configuratorExtendBarDesc');
    extendBarQtyEl = document.getElementById('configuratorExtendBarQty');
    extendBarUnitPriceEl = document.getElementById('configuratorExtendBarUnitPrice');
    extendBarTotalPriceEl = document.getElementById('configuratorExtendBarTotalPrice');

    chainLengthRowEl = document.getElementById('configuratorChainLengthRow');
    chainLengthCodeEl = document.getElementById('configuratorChainLengthCode');
    chainLengthDescEl = document.getElementById('configuratorChainLengthDesc');
    chainLengthQtyEl = document.getElementById('configuratorChainLengthQty');
    chainLengthUnitPriceEl = document.getElementById('configuratorChainLengthUnitPrice');
    chainLengthTotalPriceEl = document.getElementById('configuratorChainLengthTotalPrice');

    chainBagRowEl = document.getElementById('configuratorChainBagRow');
    chainBagCodeEl = document.getElementById('configuratorChainBagCode');
    chainBagDescEl = document.getElementById('configuratorChainBagDesc');
    chainBagQtyEl = document.getElementById('configuratorChainBagQty');
    chainBagUnitPriceEl = document.getElementById('configuratorChainBagUnitPrice');
    chainBagTotalPriceEl = document.getElementById('configuratorChainBagTotalPrice');

    controlRowEl = document.getElementById('configuratorControlRow');
    controlLabelEl = document.getElementById('configuratorControlLabel');
    controlCodeEl = document.getElementById('configuratorControlCode');
    controlDescEl = document.getElementById('configuratorControlDesc');
    controlQtyEl = document.getElementById('configuratorControlQty');
    controlUnitPriceEl = document.getElementById('configuratorControlUnitPrice');
    controlTotalPriceEl = document.getElementById('configuratorControlTotalPrice');

    limitSwitchRowEl = document.getElementById('configuratorLimitSwitchRow');
    limitSwitchCodeEl = document.getElementById('configuratorLimitSwitchCode');
    limitSwitchDescEl = document.getElementById('configuratorLimitSwitchDesc');
    limitSwitchQtyEl = document.getElementById('configuratorLimitSwitchQty');
    limitSwitchUnitPriceEl = document.getElementById('configuratorLimitSwitchUnitPrice');
    limitSwitchTotalPriceEl = document.getElementById('configuratorLimitSwitchTotalPrice');

    totalEl = document.getElementById('configuratorTotal');
  }
}
function toggleSideBar() {
  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js__sidebar-toggle-button, .js__sidebar-toggle-button *')) {
      event.preventDefault();
      document.querySelector(".side-bar__dropdown-icon").classList.toggle("side-bar__dropdown-icon--is-active");
      var sideBarItems = document.querySelector(".js_side-bar-items");
      sideBarItems.classList.toggle("side-bar__items--is-visible");
    }
  });
}
toggleSideBar();
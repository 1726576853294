ready(function() {
    function setVideoModalContent(btn) {
        var html = [];
        var host = btn.getAttribute('video-host');
        var path = '';

        if(host === 'youtube') {
            path = 'https://www.youtube.com/embed/' + btn.getAttribute('video-id');
        } else if (host === 'vimeo') {
            path = 'https://player.vimeo.com/video/' + btn.getAttribute('video-id');
        } else if (host === 'asset') {
            path = btn.getAttribute('video-path');
        }


        html = html.concat([
            '<div class="iframe-container">',
            '<iframe src="' + path + '?autoplay=1" frameborder="0" allowfullscreen >',
            '</iframe>',
            '</div>'
        ]);

        return html.join('\n');

    }

    var modalVideos = document.querySelectorAll('.modal-video');

    if(modalVideos) {
        Array.from(modalVideos).forEach(function(btn) {
            btn.addEventListener('click', function(event) {
                var modal = new tingle.modal({
                    footer: false,
                    closeMethods: ['overlay', 'escape'],
                    cssClass: ['video-modal'],

                    onOpen: function () {

                        // the checkOverflow() doesn't seem to work unless a resize is triggered.
                        window.dispatchEvent(new Event('resize'));
                    },

                    onClose: function () {

                        // there's a bug where if you close a modal by clicking the backdrop, then
                        // open another modal, the content inside the modal is selected, so we're
                        // clearing the selection on close.
                        removeElementsByClass('video-modal');

                    },

                });

                var html = setVideoModalContent(btn);
                modal.setContent(html);

                modal.open();
            });
        })
    }
});
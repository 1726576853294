var newsForm = document.querySelector('.newsletter-form');

if(newsForm) {
    //TODO - Make these generic enough similar to validateForms in order to allow for one function to handle them all.
    function newsFormErrors(errors) {
        for(var key in errors) {
            var node = createErrorNode(errors[key]);

            if(key == 'form') {
                newsForm.appendChild(node);
            } else {
                var elem = newsForm.querySelector('.news-' + key);
                elem.parentElement.appendChild(node);
            }
        }
    }

    var submitBtn = newsForm.querySelector('.js_form-submit');

    if(submitBtn) {
        submitBtn.addEventListener('click', function(event) {
            event.preventDefault();

            removeElementsByClass('error-speech-bubble');

            var formData = new FormData(newsForm);

            var options = {
                method: 'POST',
                credentials: 'include',
                body: formData
            };

            sendRequest('/' + getCurrentLocale() + '/newsletter/submit', options).then(function(success) {
                var successPage = document.querySelector('.newsletter-success');
                formToggleSuccess(newsForm, successPage);
            }).catch(function(errors) {
                newsFormErrors(errors.error);
            });
        });
    }
}
function eeProductImpression() {

    var productImpressions = document.querySelectorAll('.ga-tm-product-impression');
    if (productImpressions.length > 0) {
        var observer = new IntersectionObserver(function (entries) {
            Array.from(entries).forEach(function (entry) {
                if (entry.isIntersecting === true) {
                    var impression = entry.target.querySelector('.ga-tm-pa-data');
                    if (impression.getAttribute('data-seen') !== 'seen') {
                        impression.setAttribute('data-seen', 'seen');

                        var trackingParams = {};
                        trackingParams['item_id'] = String(impression.getAttribute('data-ecid'));
                        trackingParams['item_name'] = impression.getAttribute('data-ecname');
                        trackingParams['index'] = Number(impression.getAttribute('data-ePosition'));

                        if (impression.getAttribute('data-ecprice') !== null) {
                            trackingParams['price'] = Number(impression.getAttribute('data-ecprice'));
                        }
                        if (impression.getAttribute('data-ecbrand') !== null) {
                            trackingParams['item_brand'] = impression.getAttribute('data-ecbrand');
                        }
                        if (impression.getAttribute('data-eccategory') !== null) {
                            eeGetCategories(trackingParams, impression.getAttribute('data-eccategory'));
                        }
                        if (impression.getAttribute('data-eclist') !== null) {
                            trackingParams['item_list_name'] = impression.getAttribute('data-eclist');
                        }

                        dataLayer.push({ 'ecommerce': null });
                        dataLayer.push({
                            'event': 'view_item_list',
                            'ecommerce': {
                                'currency': impression.getAttribute('data-currency'),
                                'items': [trackingParams]
                            }
                        });

                        console.log('view_item_list', trackingParams);
                    }
                }
            });
        });

        Array.from(productImpressions).forEach(function (box) {
            observer.observe(box);
        });
    }
}
eeProductImpression();

function eeGetCategories(trackingParams, impression) {
    var eccategory = JSON.parse(impression);
    eccategory = eccategory.reverse();
    Array.from(eccategory).forEach(function (category, index) {
        if (category !== "") {
            trackingParams['item_category' + (((index + 1) != 1) ? (index + 1) : '')] = category;
        }
    });
    return trackingParams;
}

function eeAutoCompleteImpression(link) {

    if (link.getAttribute('data-id') !== null) {

        var trackingParams = {};
        trackingParams['item_id'] = String(link.getAttribute('data-id'));
        trackingParams['item_name'] = link.getAttribute('data-name');
        trackingParams['index'] = Number(link.getAttribute('data-position'));
        if (link.getAttribute('data-brand') !== null) {
            trackingParams['item_brand'] = link.getAttribute('data-brand');
        }
        if (link.getAttribute('data-eccategory') !== null) {
            eeGetCategories(trackingParams, link.getAttribute('data-eccategory'));
        }

        var userType = 'Guest';
        var autoComplete = 'Autocomplete';
        if (typeof (shopUserType) !== 'undefined') {
            userType = shopUserType;
            autoComplete = autoCompleteListName;
        }

        if (eeTrackingForNA) {
            var gaProductList = autoComplete + ' | ' + userType;
        } else {
            var gaProductList = autoComplete;
        }
        trackingParams['item_list_name'] = gaProductList;

        // Send Product Impression
        dataLayer.push({ 'ecommerce': null });
        dataLayer.push({
            'event': 'view_item_list',
            'ecommerce': {
                'currency': link.getAttribute('data-currency'),
                'items': [trackingParams]
            }
        });

        console.log('view_item_list', trackingParams);
    }
}

function eeAutoCompleteClick(link) {

    if (link.getAttribute('data-id') !== null) {

        var trackingParams = {};
        trackingParams['item_id'] = String(link.getAttribute('data-id'));
        trackingParams['item_name'] = link.getAttribute('data-name');
        trackingParams['index'] = Number(link.getAttribute('data-position'));
        if (link.getAttribute('data-brand') !== null) {
            trackingParams['item_brand'] = link.getAttribute('data-brand');
        }
        if (link.getAttribute('data-eccategory') !== null) {
            eeGetCategories(trackingParams, link.getAttribute('data-eccategory'));
        }

        var userType = 'Guest';
        var autoComplete = 'Autocomplete';
        if (typeof (shopUserType) !== 'undefined') {
            userType = shopUserType;
            autoComplete = autoCompleteListName;
        }

        if (eeTrackingForNA) {
            var gaProductList = autoComplete + ' | ' + userType;
        } else {
            var gaProductList = autoComplete;
        }
        trackingParams['item_list_name'] = gaProductList;

        sessionStorage.setItem('gaProductList', gaProductList);

        // Send Product click
        dataLayer.push({ 'ecommerce': null });
        dataLayer.push({
            'event': 'select_item',
            'ecommerce': {
                'items': [trackingParams]
            },
            'eventCallback': function () {
                window.location.href = link.getAttribute('data-href');
            }
        });


        console.log('select_item', trackingParams);

    } else {
        window.location.href = link.getAttribute('data-href');
    }
}

function productTrackingTrigger() {

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.product-tracking, .product-tracking *')) {

            event.preventDefault();
            var item = event.target;

            // Go to Product Page:
            while (item.hasAttribute('data-url') == false) {
                item = item.parentNode;
                if (item.tagName.toUpperCase() == 'BODY') {
                    break;
                }
            }
            if (item.hasAttribute('data-url')) {
                eeProductActionClick(item);
            }
        }
    });
}
productTrackingTrigger();

function eeProductActionClick(item) {
    var productImpressions = document.querySelectorAll('.ga-tm-product-impression');
    if (productImpressions.length > 0) {

        var impression = item.querySelector('.ga-tm-pa-data');
        var trackingParams = {};

        trackingParams['item_id'] = String(impression.getAttribute('data-ecid'));
        trackingParams['item_name'] = impression.getAttribute('data-ecname');
        trackingParams['index'] = Number(impression.getAttribute('data-ePosition'));

        if (impression.getAttribute('data-ecbrand') !== null) {
            trackingParams['item_brand'] = impression.getAttribute('data-ecbrand');
        }

        if (impression.getAttribute('data-eccategory') !== null) {
            eeGetCategories(trackingParams, impression.getAttribute('data-eccategory'));
        }

        var gaProductList = '';
        if (impression.getAttribute('data-eclist') !== null) {
            trackingParams['item_list_name'] = impression.getAttribute('data-eclist');
            gaProductList = trackingParams['item_list_name'];
        }
        sessionStorage.setItem('gaProductList', gaProductList);

        dataLayer.push({ 'ecommerce': null });
        dataLayer.push({
            'event': 'select_item',
            'ecommerce': {
                'items': [trackingParams]
            },
            'eventCallback': function () {
                window.location.href = item.getAttribute('data-url');
            }
        });

        console.log('select_item', trackingParams);

    } else {
        window.location.href = item.getAttribute('data-url');
    }
}

function eeProductActionDetail() {

    if (typeof (productDetailView) !== 'undefined') {

        var trackingParams = {};
        trackingParams['item_id'] = String(productDetailView['id']);
        trackingParams['item_name'] = productDetailView['name'];
        if (typeof (productDetailView['brand']) !== 'undefined') {
            trackingParams['item_brand'] = productDetailView['brand'];
        }
        if (typeof (productDetailView['categories']) !== 'undefined') {
            eeGetCategories(trackingParams, productDetailView['categories']);
        }

        var gaProductList = sessionStorage.getItem('gaProductList');
        if (gaProductList !== null) {
            trackingParams['item_list_name'] = gaProductList;
        } else {
            var userType = 'Guest';
            var external = 'Autocomplete';
            if (typeof (shopUserType) !== 'undefined') {
                userType = shopUserType;
                external = externalListName;
            }
            if (eeTrackingForNA) {
                var gaProductList = external + ' | ' + userType;
            } else {
                var gaProductList = external;
            }
            trackingParams['item_list_name'] = gaProductList;
        }

        dataLayer.push({ 'ecommerce': null });
        dataLayer.push({
            'event': 'view_item',
            'ecommerce': {
                'items': [trackingParams]
            }
        });

        console.log('view_item', trackingParams);

        if (eeTrackingForNA) {
            if (typeof (purchasableProducts) !== 'undefined') {
                logCustomImpressions(purchasableProducts, 'Purchasable Products');
            }

            if (typeof (specialOrderProducts) !== 'undefined') {
                logCustomImpressions(specialOrderProducts, 'Special Order Products');
            }
        }
    }
}
eeProductActionDetail();

function logCustomImpressions(impressions, listName) {

    var list = listName;
    if (typeof (shopUserType) !== 'undefined') {
        list = list + ' | ' + shopUserType;
    }

    Array.from(impressions).forEach(function (impression) {
        var trackingParams = {};
        trackingParams['item_id'] = String(impression['id']);
        trackingParams['item_name'] = impression['name'];
        if (typeof (impression['variant']) !== 'undefined') {
            trackingParams['item_variant'] = impression['variant'];
        }
        trackingParams['index'] = Number(impression['position']);
        if (typeof (impression['price']) !== 'undefined') {
            trackingParams['price'] = Number(impression['price']);
        }
        if (typeof (impression['brand']) !== 'undefined') {
            trackingParams['item_brand'] = impression['brand'];
        }
        if (typeof (impression['categories']) !== 'undefined') {
            eeGetCategories(trackingParams, impression['categories']);
        }
        if (typeof (impression['brand']) !== 'undefined') {
            trackingParams['item_brand'] = impression['brand'];
        }
        trackingParams['item_list_name'] = list;

        dataLayer.push({ 'ecommerce': null });
        dataLayer.push({
            'event': 'view_item_list',
            'ecommerce': {
                'currency': impression['currency'],
                'items': [trackingParams]
            }
        });

        console.log('view_item_list', trackingParams);
    });
}

function addToCartTracking(impression, type, qty, url) {

    var trackingParams = {};
    if (type == 'select-box') {
        trackingParams['item_id'] = String(document.querySelector('div[data-range-id]').getAttribute('data-range-id'));
        trackingParams['quantity'] = Number(document.getElementById('product-page-quantity-input').value);
    }
    else if (type == 'code-table') {
        trackingParams['item_id'] = String(document.querySelector('div[data-range-id]').getAttribute('data-range-id'));
        trackingParams['quantity'] = 1;
    }
    else if (type == 'checkout-row') {
        trackingParams['item_id'] = String(impression.getAttribute('data-range-id'));
        trackingParams['quantity'] = qty;
    }

    trackingParams['item_name'] = impression.getAttribute('data-range-name');
    trackingParams['price'] = Number(impression.getAttribute('data-price-number'));
    trackingParams['item_variant'] = impression.getAttribute('data-gencode');
    if (impression.getAttribute('data-brand') !== null) {
        trackingParams['item_brand'] = impression.getAttribute('data-brand');
    }
    if (impression.getAttribute('data-eccategory') !== null) {
        eeGetCategories(trackingParams, impression.getAttribute('data-eccategory'))
    }

    var gaProductList = sessionStorage.getItem('gaProductList');
    var listName = '';
    if (gaProductList !== null) {
        listName = gaProductList;
    }
    trackingParams['item_list_name'] = listName;

    dataLayer.push({ 'ecommerce': null });
    dataLayer.push({
        'event': 'add_to_cart',
        'ecommerce': {
            'currency': impression.getAttribute('data-currency'),
            'items': [trackingParams]
        },
        'eventCallback': function () {
            if (type == 'checkout-row') {
                window.location.href = url;
            }
        }
    });

    console.log('add_to_cart', trackingParams);

}

function removeFromCartTracking(impression, url, qty) {

    var trackingParams = {};
    trackingParams['item_name'] = impression.getAttribute('data-range-name');
    trackingParams['item_id'] = String(impression.getAttribute('data-range-id'));
    trackingParams['price'] = Number(impression.getAttribute('data-price-number'));
    if (impression.getAttribute('data-brand') !== null) {
        trackingParams['item_brand'] = impression.getAttribute('data-brand');
    }
    if (impression.getAttribute('data-eccategory') !== null) {
        eeGetCategories(trackingParams, impression.getAttribute('data-eccategory'))
    }
    trackingParams['item_variant'] = impression.getAttribute('data-gencode');
    trackingParams['quantity'] = qty;

    dataLayer.push({ 'ecommerce': null });
    dataLayer.push({
        'event': 'remove_from_cart',
        'ecommerce': {
            'items': [trackingParams]
        },
        'eventCallback': function () {
            window.location.href = url;
        }
    });

    console.log('remove_from_cart', trackingParams);
}

function checkoutStepTracking(step, url, option) {

    var products = [];

    var productData = JSON.parse(getProductsInCart);

    Array.from(productData).forEach(function (product) {

        var trackingParams = {};

        trackingParams['item_name'] = product['rangeName'];
        trackingParams['item_id'] = String(product['rangeId']);
        trackingParams['price'] = Number(product['priceNumeric']);
        if (product['brandName'] != '') {
            if (typeof (product['brandName']) !== 'undefined') {
                trackingParams['item_brand'] = product['brandName'];
            }
        }

        if (product['eccategory'] != '') {
            if (typeof (product['eccategory']) !== 'undefined') {
                var eccategory = product['eccategory'];
                if (step == 4) {
                    eccategory = eccategory.split("|||");
                }
                eeGetCategories(trackingParams, JSON.stringify(eccategory));
            }
        }

        trackingParams['item_variant'] = product['code'];
        trackingParams['quantity'] = product['qty'];

        products.push(trackingParams);

    });

    dataLayer.push({ 'ecommerce': null });
    dataLayer.push({
        'event': 'begin_checkout',
        'ecommerce': {
            'items': products
        },
        'eventCallback': function () {
            if (url != '') {
                window.location.href = url;
            }
        }
    });

    console.log('begin_checkout', step, option, products);
}

function purchasesTracking(ordernumber, totalPrice, taxAmount, shippingCharge, products) {

    var cleanProducts = JSON.parse(products);
    var items = [];

    Array.from(cleanProducts).forEach(function (product) {
        var eccategory = product['eccategory'];
        eccategory = eccategory.split("|||");
        eeGetCategories(product, JSON.stringify(eccategory));
        delete product['eccategory'];
        items.push(product);
    });

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        'event': 'purchase',
        'ecommerce': {
            'transaction_id': String(ordernumber),
            'affiliation': 'Tractel Online Store',
            'value': totalPrice,
            'tax': taxAmount,
            'shipping': shippingCharge,
            'currency': "USD",
            'items': JSON.parse(products),
        }
    });

    console.log('purchase', {
        'event': 'purchase',
        'ecommerce': {
            'transaction_id': String(ordernumber),
            'affiliation': 'Tractel Online Store',
            'value': totalPrice,
            'tax': taxAmount,
            'shipping': shippingCharge,
            'currency': "USD",
            'items': JSON.parse(products),
        }
    });
}
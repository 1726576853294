function megamenuInitialized() {

    var body = document.body;

    var eventList = ["click", "mouseover"];
    Array.from(eventList).forEach(function (eventItem) {

        if (eventItem == "mouseover") {
            var query = 'body:not(.mega-menu-mobile-nav-trigger) .mega-menu__container [data-menu-level] .menu-expandable[data-mm-id], body:not(.mega-menu-mobile-nav-trigger) .mega-menu__container [data-menu-level]  .menu-expandable[data-mm-id] *';
        } else {
            var query = 'body.mega-menu-mobile-nav-trigger .mega-menu__container [data-menu-level] .menu-expandable[data-mm-id], body.mega-menu-mobile-nav-trigger .mega-menu__container [data-menu-level]  .menu-expandable[data-mm-id] *';
        }

        document.addEventListener(eventItem, function (event) {
            if (event.target && (event.target.matches(query))) {
                event.preventDefault();
                var item = event.target;
                var selectedElm = item.closest('[data-mm-id]');
                var selectedId = selectedElm.getAttribute('data-mm-id');
                var levelElm = item.closest('[data-menu-level]');
                var levelNum = levelElm.getAttribute('data-menu-level');

                var menuLevel1 = document.querySelector('.mega-menu__container [data-menu-level="1"]');
                var menuLevel2 = document.querySelector('.mega-menu__container [data-menu-level="2"]');
                if (levelNum == 1) {
                    var activeNavs = document.querySelectorAll('.mega-menu__container [data-menu-level] .active-nav');
                    Array.from(activeNavs).forEach(function (activeNav) {
                        activeNav.classList.remove('active-nav');
                    });
                    selectedElm.classList.add('active-nav');

                    var menuItems = document.querySelectorAll('.mega-menu__container [data-mm-pid], .mega-menu__container [data-mm-ppid]');
                    Array.from(menuItems).forEach(function (menuItem) {
                        menuItem.classList.add('menu-hidden');
                    });
                    document.querySelector('.mega-menu__container [data-mm-pid="' + selectedId + '"]').classList.remove('menu-hidden');
                    menuLevel2.classList.add('mega-menu__v-divider');
                    menuLevel2.classList.remove('menu-level-active');
                    menuLevel1.classList.add('menu-level-active');

                    if (hasClass(body, 'mega-menu-mobile-nav-trigger')) {
                        document.querySelector('.mega-menu__mobile-back[data-back="products"]').classList.remove('menu-hidden');
                        menuLevel1.classList.add('d-none');
                    }
                }

                if (levelNum == 2) {
                    var activeNavs = document.querySelectorAll('.mega-menu__container [data-menu-level="2"] .active-nav');
                    Array.from(activeNavs).forEach(function (activeNav) {
                        activeNav.classList.remove('active-nav');
                    });
                    selectedElm.classList.add('active-nav');

                    var menuItems = document.querySelectorAll('.mega-menu__container [data-mm-ppid]');
                    Array.from(menuItems).forEach(function (menuItem) {
                        menuItem.classList.add('menu-hidden');
                    });
                    document.querySelector('.mega-menu__container [data-mm-ppid="' + selectedId + '"]').classList.remove('menu-hidden');
                    document.querySelector('.mega-menu__container [data-menu-level="2"]').classList.add('menu-level-active');

                    if (hasClass(body, 'mega-menu-mobile-nav-trigger')) {
                        var subProduct = document.querySelector('.mega-menu__mobile-back[data-back="sub-products"]');
                        subProduct.classList.remove('menu-hidden');
                        subProduct.querySelector('span').innerText = ' ' + menuLevel2.querySelector('.menu-mega__header').innerText.replace(/(\r\n|\n|\r)/gm, "");
                        menuLevel2.classList.add('d-none');
                    }
                }
            }
        });

        if (eventItem == "mouseover") {
            document.addEventListener(eventItem, function (event) {
                if (event.target && (event.target.matches('body:not(.mega-menu-mobile-nav-trigger) .mega-menu__container [data-menu-level] .menu-mega__link:not(.menu-expandable)[data-mm-id], body:not(.mega-menu-mobile-nav-trigger) .mega-menu__container [data-menu-level]  .menu-mega__link:not(.menu-expandable)[data-mm-id] *'))) {
                    event.preventDefault();
                    var item = event.target;

                    var levelElm = item.closest('[data-menu-level]');
                    var levelNum = levelElm.getAttribute('data-menu-level');
                    var menuLevel1 = document.querySelector('.mega-menu__container [data-menu-level="1"]');
                    var menuLevel2 = document.querySelector('.mega-menu__container [data-menu-level="2"]');

                    if (levelNum == 1) {
                        var activeNavs = document.querySelectorAll('.mega-menu__container [data-menu-level] .active-nav');
                        Array.from(activeNavs).forEach(function (activeNav) {
                            activeNav.classList.remove('active-nav');
                        });

                        menuLevel1.classList.remove('menu-level-active');
                        
                        var menuItems = document.querySelectorAll('.mega-menu__container [data-mm-pid], .mega-menu__container [data-mm-ppid]');
                        Array.from(menuItems).forEach(function (menuItem) {
                            menuItem.classList.add('menu-hidden');
                        });
                    }

                    if (levelNum == 2) {
                        var activeNavs = document.querySelectorAll('.mega-menu__container [data-menu-level="2"] .active-nav');
                        Array.from(activeNavs).forEach(function (activeNav) {
                            activeNav.classList.remove('active-nav');
                        });

                        menuLevel2.classList.remove('menu-level-active');
                        
                        var menuItems = document.querySelectorAll('.mega-menu__container [data-mm-ppid]');
                        Array.from(menuItems).forEach(function (menuItem) {
                            menuItem.classList.add('menu-hidden');
                        });
                    }

                }
            });
        }
    });


    document.addEventListener('mouseover', function (event) {
        if (event.target && (event.target.matches('.mega-menu__container .mega-menu__brand-link a[data-mm-id]'))) {
            event.preventDefault();
            var item = event.target;

            if (item.closest('.menu-mega__header') === null) {
                var selectedElm = item.closest('[data-mm-id]');
                var selectedId = selectedElm.getAttribute('data-mm-id');

                var activeNavs = document.querySelectorAll('.mega-menu__container .mega-menu__brand');
                Array.from(activeNavs).forEach(function (activeNav) {
                    activeNav.classList.add('d-none');
                });
                document.querySelector('.mega-menu__container .mega-menu__brand[data-mm-brandid="' + selectedId + '"]').classList.remove('d-none');

            }
        }
    });

    document.addEventListener('mouseover', function (event) {
        if (event.target && (event.target.matches('.mega-menu__container .mega-menu__industry-solutions-link a[data-mm-id]'))) {
            event.preventDefault();
            var item = event.target;

            if (item.closest('.menu-mega__header') === null) {
                var selectedElm = item.closest('[data-mm-id]');
                var selectedId = selectedElm.getAttribute('data-mm-id');

                var activeNavs = document.querySelectorAll('.mega-menu__container .mega-menu__industry-solutions');
                Array.from(activeNavs).forEach(function (activeNav) {
                    activeNav.classList.add('d-none');
                });
                document.querySelector('.mega-menu__container .mega-menu__industry-solutions[data-mm-industrysolutionsid="' + selectedId + '"]').classList.remove('d-none');

            }
        }
    });

    document.addEventListener('mouseover', function (event) {
        if (event.target && (event.target.matches('.mega-menu__container a.menu-news-link'))) {
            event.preventDefault();
            var item = event.target;

            var menuItems = document.querySelectorAll('div[data-news-id]');
            Array.from(menuItems).forEach(function (menuItem) {
                menuItem.classList.add('d-none');
            });

            var id = item.getAttribute('data-mm-id');
            document.querySelector('div[data-news-id="' + id + '"]').classList.remove('d-none');
        }
    });

    document.addEventListener('click', function (event) {

        if (event.target && (event.target.matches('.js_mega-menu-mobile-nav, .js_mega-menu-mobile-nav *'))) {
            event.preventDefault();
            var item = event.target;
            if (hasClass(item, 'js_mega-menu-mobile-nav') == false) {
                item = item.parentElement;
            }
            var icon = item.querySelector('.icon');
            var portal = document.querySelector('.customer-portal-top');



            if (hasClass(body, 'mega-menu-mobile-nav-trigger')) {

                var menuItems = document.querySelectorAll('.mega-menu__container');
                Array.from(menuItems).forEach(function (menuItem) {
                    menuItem.classList.remove('d-block');
                });

                document.querySelector('.mega-menu-mobile-nav-trigger .nav__main').classList.remove('d-none');

                var hideItems = document.querySelectorAll('.js-mobile-hide-nav-items > *');
                Array.from(hideItems).forEach(function (hideItem) {
                    hideItem.classList.add('menu-hidden');
                });

                var hideItems = document.querySelectorAll('.js-mobile-hide-nav-items');
                Array.from(hideItems).forEach(function (hideItem) {
                    hideItem.classList.remove('d-none');
                });

                document.querySelector('.mega-menu__container [data-menu-level="1"]').classList.remove('d-none');

                setTimeout(function () {
                    body.classList.remove('mega-menu-mobile-nav-trigger');
                    icon.classList.remove('i_close');
                    icon.classList.add('i_menu');
                }, 20);


            } else {
                body.classList.add('mega-menu-mobile-nav-trigger');
                icon.classList.remove('i_menu');
                icon.classList.add('i_close');
            }
            body.classList.remove('mega-menu-mobile-brand-trigger');
        }

        if (event.target && (event.target.matches('.mega-menu__our-brand-mobile, .mega-menu__our-brand-mobile *'))) {
            event.preventDefault();

            if (hasClass(body, 'mega-menu-mobile-nav-trigger')) {
                if (hasClass(body, 'mega-menu-mobile-nav-trigger')) {
                    body.classList.add('mega-menu-mobile-brand-trigger');

                    setTimeout(function () {
                        document.querySelector('.mega-menu__blue').classList.add('mega-menu__blue-timeout');
                    }, 20);

                } else {
                    body.classList.remove('mega-menu-mobile-brand-trigger');
                }
            } else {
                body.classList.remove('mega-menu-mobile-brand-trigger');
            }
        }

        if (event.target && (event.target.matches('.mega-menu__blue-mobile-hide'))) {
            event.preventDefault();
            var blueClose = document.querySelector('.mega-menu__blue');
            blueClose.scrollTo(0, 0);
            blueClose.classList.remove('mega-menu__blue-timeout');
            setTimeout(function () {
                body.classList.remove('mega-menu-mobile-brand-trigger');
            }, 400);
        }

        if (event.target && (event.target.matches('.mega-menu-mobile-nav-trigger .nav__main .nav__item a, .mega-menu-mobile-nav-trigger .nav__main .nav__item a *'))) {
            event.preventDefault();
            var item = event.target.closest('li');
            var navId = item.getAttribute('data-navid');
            item.closest('.nav__main').classList.add('d-none');
            var menu = document.querySelector('.mega-menu__container[data-navid="' + navId + '"]');
            menu.classList.remove('is-open');
            menu.classList.add('d-block');

        }

        if (event.target && (event.target.matches('.mega-menu__mobile-back, .mega-menu__mobile-back *'))) {
            event.preventDefault();
            var item = event.target;
            if (hasClass(item, 'mega-menu__mobile-back') == false) {
                item = item.closest('.mega-menu__mobile-back');
            }
            var navId = item.getAttribute('data-back');
            var menuLevel1 = document.querySelector('.mega-menu__container [data-menu-level="1"]');
            var menuLevel2 = document.querySelector('.mega-menu__container [data-menu-level="2"]');

            if (navId == 'nav') {
                item.closest('.mega-menu__container').classList.remove('d-block');
                document.querySelector('.mega-menu-mobile-nav-trigger .nav__main').classList.remove('d-none');
            } else if (navId == 'products') {
                item.classList.add('menu-hidden');
                menuLevel1.classList.remove('d-none');
                var menuItems = document.querySelectorAll('.mega-menu__container [data-mm-pid], .mega-menu__container [data-mm-ppid]');
                Array.from(menuItems).forEach(function (menuItem) {
                    menuItem.classList.add('menu-hidden');
                });
            } else if (navId == 'sub-products') {
                item.classList.add('menu-hidden');
                menuLevel2.classList.remove('d-none');
                var menuItems = document.querySelectorAll('.mega-menu__container [data-mm-ppid]');
                Array.from(menuItems).forEach(function (menuItem) {
                    menuItem.classList.add('menu-hidden');
                });
            }


        }

    });

}
megamenuInitialized()
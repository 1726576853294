function distributerForm() {

    var distributerForm = document.querySelector('.form__nav-distributer');

    if (distributerForm) {

        function distributerFormErrors(errors) {
            for(var i = 0; i < errors.length; i++) {
                
                for (var key in errors[i]) {
                    var node = createErrorNode(errors[i][key]);

                    if (key == 'form') {
                        distributerForm.appendChild(node);
                    } else {
                        var elem = distributerForm.querySelector('.distributer-' + key);
                        elem.parentElement.appendChild(node);
                    }
                }
            }
        }

        var submitBtn = document.querySelector('.js_distributer-submit');

        submitBtn.addEventListener('click', function(event) {
            event.preventDefault();
            submitBtn.disabled = true;
            removeElementsByClass('error-speech-bubble');

            var formData = new FormData(distributerForm);

            var options = {
                method: 'POST',
                credentials: 'include',
                body: formData
            };

            sendRequest('/' + getCurrentLocale() + '/customer/portal/distributerSubmit', options).then(function(success) {
                formTogglePopupSuccess(distributerForm);
                submitBtn.disabled = false;
            }).catch(function(errors) {
                distributerFormErrors(errors.error);
                submitBtn.disabled = false;
            });
        });

    }

    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.open-distributer, .open-distributer *')){
            event.preventDefault();

            openModel('distributer');
        }
    });


}

distributerForm();
var newsletterForm = document.getElementById('newsletter-signup');

function prepareFormData(params) {
    var formData = new FormData;

    for(var key in params) {
        formData.append(key, params[key]);
    }

    return formData;
}

function newsLetterAction() {
    //TODO - When we know for sure what we are doing with the data. Possibly sendgrid.
}

function getFormData() {
    var messages = {};

    var name = document.getElementById('newsletter-name').value;
    var email = document.getElementById('newsletter-email').value;
    var industry = document.getElementById('newsletter-industry').value;
    var country = document.getElementById('newsletter-country').value;

    if(!name || !name.length > 0) {
        messages.name = 'Name cannot be empty';
    }

    if(!email || !email.length > 0) {
        messages.email = 'Email cannot be empty';
    }

    if(!industry || !industry.length > 0) {
        messages.industry = 'Industry cannot be empty';
    }

    if(!country || !country.length > 0) {
        messages.country = 'Country cannot be empty';
    }

    return messages;
}

if(newsletterForm) {
    var newsletterBtn = document.getElementById('newsletter-submit');

    newsletterBtn.addEventListener('click', function (event) {
        event.preventDefault();
        if(!Object.keys(hasErrors).length) {
            newsLetterAction();
        } else {
            //TODO - when we get the design for form fail.
        }
    });
}
function showPopup(content, delay, showOnce, popupId) {

    var openPopup = true;

    if (showOnce) {
        if (getCookie("popup_" + popupId) != "") {
            openPopup = false;
        }
        document.cookie = "popup_" + popupId + "=1; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/";
    }

    if (openPopup) {
        var modal = new tingle.modal({
            footer: false,
            cssClass: ['popup-modal'],

            onOpen: function () {

                // the checkOverflow() doesn't seem to work unless a resize is triggered.
                window.dispatchEvent(new Event('resize'));


                var close = document.querySelector(".tingle-modal__close");
                var modalContent = document.querySelector(".tingle-modal-box__content");
                modalContent.querySelector(".close-box").appendChild(close);
                modalContent.querySelector(".tingle-modal__close").classList.add('tingle-modal__close-show');
            },

            onClose: function () {

                // there's a bug where if you close a modal by clicking the backdrop, then
                // open another modal, the content inside the modal is selected, so we're
                // clearing the selection on close.
                removeElementsByClass('popup-modal');

            },
        });

        modal.setContent('<div class="close-box"></div>' + content);

        if (delay == '' ) {
            delay = 4;
        } else {
            delay = Number(delay);
        }

        setTimeout(function(){ 
            modal.open();
        }, delay*1000);
    }

}

function showYesNoPopup(content, yesLabel, noLabel, yesFunction, yesParameters, noFunction, noParameters) {

    var modal = new tingle.modal({
        footer: true,
        cssClass: ['popup-modal'],

        onOpen: function () {

            // the checkOverflow() doesn't seem to work unless a resize is triggered.
            window.dispatchEvent(new Event('resize'));


            var close = document.querySelector(".tingle-modal__close");
            var modalContent = document.querySelector(".tingle-modal-box__content");
            modalContent.querySelector(".close-box").appendChild(close);
            modalContent.querySelector(".tingle-modal__close").classList.add('tingle-modal__close-show');
        },

        onClose: function () {

            // there's a bug where if you close a modal by clicking the backdrop, then
            // open another modal, the content inside the modal is selected, so we're
            // clearing the selection on close.
            removeElementsByClass('popup-modal');

        },
    });

    modal.setContent('<div class="close-box"></div>' + content);

    if (yesLabel != '') {
        modal.addFooterBtn(yesLabel, '', function() {
            if (yesFunction != '') {
                if (yesParameters != '') {
                    window[yesFunction](yesParameters);
                } else {
                    window[yesFunction]();
                }
            }
            modal.close();
        });
    }

    if (noLabel != '') {
        modal.addFooterBtn(noLabel, '', function() {
            if (noFunction != '') {
                if (noParameters != '') {
                    window[noFunction](noParameters);
                } else {
                    window[noFunction]();
                }
            }
            modal.close();
        });
    }

    modal.open();

}
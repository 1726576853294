function buildQuoteActions() {
    //Check if there's an element with remove-quote class. If there is means we are removing an item.
    document.addEventListener('click', function (event) {

        if (event.target && event.target.matches('.remove-quote, .remove-quote > *')) {

            event.preventDefault();
            var item = event.target;
            var product_id = item.getAttribute('product-id');

            var params = {
                product_id: product_id
            };

            quoteAction('remove', params).then(function (success) {

                var model = item.closest('.popup-container');
                if (hasClass(model, 'popup-mobile-slider')) {
                    if (model.querySelectorAll('.remove-quote').length <= 1) {
                        slideHome();
                    }
                }
            }).catch(function (error) {

            });
        }
    });


    //Check if there's an element with update-quote class. If there is means there's an item to update.
    document.addEventListener('click', function (event) {

        if (event.target && event.target.matches('.update-quote')) {
            event.preventDefault();
            var item = event.target;

            var product_id = item.getAttribute('product-id');
            var params = {
                product_id: product_id
            };

            var increment = false;

            if (item.classList.contains('increment')) {
                increment = true;
            }

            params['increment'] = increment;

            quoteAction('update', params).then(function (success) {

            }).catch(function (error) {

            });

        }
    });


    document.addEventListener('change', function (event) {

        if (event.target && event.target.matches('.add-to-quote-slider-item__quantity-input')) {
            event.preventDefault();
            var item = event.target;

            var product_id = item.getAttribute('product-id');
            var qty = item.value;

            var params = {
                product_id: product_id,
                qty: qty,
                replace: true
            };

            quoteAction('add', params).then(function (success) {

            }).catch(function (error) {

            });

        }
    });


}

buildQuoteActions();

//Used to take the cart data and build the html for each item.
function prepareCartHtml(data) {

    var html = [];
    var quoteClass = 'add-to-quote-slider';
    
    html = html.concat([
        '<table class="quote__table">',
            '<tbody>'
    ]);

    data.forEach(function (item, index) {
        html = html.concat([
            '<tr class="quote__order-row-details quote__order-row">',
                '<td class="quote__order-thumbail-container">',
                    '<img src="' + item.image + '" alt="" class="quote__order-thumbnail">',
                '</td>',
                '<td class="quote__info quote__order-description">'
        ]);


        if (item.sparePart == true) {
            html = html.concat(['<a class="primary-link-underline"><b>' + item.name + '</b></a>']);
        } else {
            html = html.concat(['<a class="primary-link-underline" href="' + item.link + '"><b>' + item.name + '</b></a>']);
        }

        html = html.concat(['<div class="quote__order-qty">' + jsTranslations['Qty'] + ': <b>' + item.qty + '</b></div>']);

        if ((item.sparePart == true) || (item.total == '')) {
            html = html.concat(['<div>&nbsp;</div>']);
         } else {
            html = html.concat(['<div class="quote__order-product-total">' + jsTranslations['Total'] + ': <b>' + item.total + '</b></div>']);
        }

        html = html.concat([
                '</td>',
                '<td class="quote__order-remove"><span class="quote__order-remove-btn remove-quote" product-id="' + item.id + '">&#10005;</span></td>',
            '</tr>',
        ]);
    });

    html = html.concat([
            '</tbody>',
        '</table>',
    ]);

    return html.join('\n');
}

function openNoQuoteMessage() {
    closeModel('quote');
    openModel('no-quotes');
}

function cartToEnquiry() {
    if(window.location.href.indexOf("?checkenquiry=1") > -1) {
        var path = window.location.pathname.split('?checkenquiry=1')[0];
        window.history.replaceState({}, document.title, path);

        if (cartToEnquiryList) {
            if (cartToEnquiryList.length > 0) {
                openModel('cart-to-enquiry');
            }
        }
    }

    document.addEventListener('click',function(event){
        
        if(event.target && event.target.matches('#js_cart_to_enquiry')){

            event.preventDefault();

            var params = {
                product_list: JSON.stringify(cartToEnquiryList),
            };

            closeModel('cart-to-enquiry');

            quoteAction('addItems', params).then(function (success) {
                openModel('quote');
            }).catch(function (error) {
                console.log(error);
            });

        }

    });
}
cartToEnquiry();

//Used to get the cart.
function getCart() {
    return new Promise(function (resolve, reject) {
        quoteAction('getCart', []).then(function (success) {
            var addToQuoteSlider = document.querySelectorAll('.js_add-to-quote-slider');

            Array.from(addToQuoteSlider).forEach(function(quoteSlider) {
                var quoteContainer = quoteSlider.querySelector('.add-to-quote-slider__items');
                if(success.length) {
                    var html = prepareCartHtml(success);
                    quoteContainer.innerHTML = html;
                } else {
                    var html = '';
                    closeModel('quote');
                }
                quoteContainer.innerHTML = html;
            });

            resolve('done');

        }).catch(function (error) {
            reject('error');
        });
    });
}
getCart();

/*
    Used to make requests to quote end points.
    Action is the controller action you wish to execute whilst params are the post data.
 */
function quoteAction(action, params) {
    if(action !== 'submit') {
        var formData = this.prepareFormData(params);
    } else {
        var formData = new FormData(params);
    }

    return new Promise(function (resolve, reject) {
        var cartController = 'quote';

        fetch('/' + getCurrentLocale() + '/' + cartController + '/' + action, {
            method: 'POST',
            credentials: 'include',
            body: formData
        }).then(function (res) {
            return res.json();
        }).then(function (res) {
            if (res.success) {
                if(action !== 'getCart' && action !== 'submit') {
                    getCart();
                }
                resolve(res.data);
            } else {
                reject(res.error);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}


function cartAction(action, params) {
    if(action !== 'submit') {
        var formData = this.prepareFormData(params);
    } else {
        var formData = this.prepareFormData(params);
    }

    return new Promise(function (resolve, reject) {
        var cartController = 'cart';

        fetch('/' + getCurrentLocale() + '/' + cartController + '/' + action, {
            method: 'POST',
            credentials: 'include',
            body: formData
        }).then(function (res) {
            return res.json();
        }).then(function (res) {
            if (res.success) {
                if(action !== 'getCart' && action !== 'submit') {
                    getCart();
                }
                resolve(res.data);
            } else {
                reject(res.error);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}
function toggleSlider() {
    var addToQuoteSlider = document.querySelectorAll('.js_add-to-quote-slider');
    if (addToQuoteSlider) {
        Array.from(addToQuoteSlider).forEach(function(quoteSlider) {
            toggleClass(quoteSlider, 'step-1');
            removeClass(quoteSlider, 'step-2');
            removeClass(quoteSlider, 'step-3');

            if(quoteSlider.classList.contains('desktop-quote')) {
                var close = document.querySelector('.add-to-quote-slider__close');
                if(quoteSlider.classList.contains('step-1')) {
                    if(close) {
                        close.classList.add('is-open');
                    }
                } else {
                    if(close) {
                        close.classList.remove('is-open');
                    }
                }
            }
        });
    }
    quoteSliderOpen();
}

var quoteClose = document.querySelectorAll('.js_close-quote');

if(quoteClose) {
    Array.from(quoteClose).forEach(function(close) {
        close.addEventListener('click', function(event) {
            toggleSlider();
        });
    })
}

function addQuoteFunctionality() {

    document.addEventListener('click',function(event){
        
        if(event.target && event.target.matches('.product-teaser, .product-teaser *, button, button.add-quote > span')){

            event.preventDefault();
            var item = event.target;

            if (item.matches('.add-quote, .add-quote *')) {

                closeModel('product-accessories');

                // Add to Quote:

                // check for inline data attribute (product tables)
                if (item.getAttribute('data-product-id')) {
                    var product_id = item.getAttribute('data-product-id');
                    var qty = 1;
                } else if (item.parentElement.getAttribute('data-product-id')) {
                    var product_id = item.parentElement.getAttribute('data-product-id');
                    var qty = 1;
                } else {
                    var parentElm = item;
                    while (parentElm.querySelector('.product-id') === null) {
                        parentElm = parentElm.parentElement;
                    }
                    var product_id = parentElm.querySelector('.product-id').value;
                    var qty = item.parentElement.querySelector('.quote-qty');
                    qty = qty ? qty.value : 1;
                }

                if (!product_id) {
                    console.log('Product ID not found for item');
                    return;
                }

                var params = {
                    product_id: product_id,
                    qty: qty
                };

                quoteAction('add', params).then(function (success) {
                    openModel('quote');
                }).catch(function (error) {
                    console.log(error);
                });

            } else if(item.matches('.field, .field *')){

                //get the correct compare check box for product teaser
                while (item.hasAttribute('data-product-id') == false) {
                    item = item.parentNode;
                }

                //get the id of the product we want to compare
                var product_id = item.getAttribute('data-product-id');

                //check/uncheck hidden the checkbox
                var checkElm = document.getElementById("compareProductCheckboxId" + product_id);
                checkElm.checked = (checkElm.checked == true) ? false : true;
                
                //get current compare list from localstorage
                var compareIdList = getCompareIdList();

                if(checkElm.checked == true){
                    //add to list when checked
                    var index = compareIdList.indexOf(product_id);
                    if (index == -1) {
                    compareIdList.push(product_id);
                    }
                } else{
                    //remove from list when unchecked
                    var index = compareIdList.indexOf(product_id);
                    if (index > -1) {
                        compareIdList.splice(index, 1);
                    }
                }

                //store ids of products we want to compare
                localStorage.setItem('compare'+compareCatId, JSON.stringify(compareIdList));

                if (window.location.search.indexOf('compare=') > -1) {
                    //if we are on the compare page then reload it to update the compare list
                    productComparePage(true);
                } else {
                    if(checkElm.checked == true){
                        checkElm.closest('.product-teaser').classList.add('added-to-compare');
                    } else{
                        checkElm.closest('.product-teaser').classList.remove('added-to-compare');
                    }
                    toggleCompareButton();
                }

            } else {
                
                // Go to Product Page:
                while(item.hasAttribute('data-url') == false) {
                    item = item.parentNode;
                    if (item.tagName.toUpperCase() == 'BODY') {
                        break;
                    }
                }
                if (item.hasAttribute('data-url')) {
                    eeProductActionClick(item);
                }
            }
         }
     });

    document.addEventListener('click', function (event) {

        if (event.target && event.target.matches('.add-list-to-quote, .add-list-to-quote *')) {

            event.preventDefault();
            var item = event.target;
            var parentElm = item;
            while (parentElm.getAttribute('data-quote-list') === null) {
                parentElm = parentElm.parentElement;
            }

            var idList = parentElm.getAttribute('data-quote-list');
            idList = idList.split(',');

            productList = [];
            for (var i = 0; i < idList.length; i++) {
                var item = {
                    'id': idList[i],
                    'qty': 1
                };
                productList.push(item);
            }

            var params = {
                product_list: JSON.stringify(productList),
            };

            quoteAction('addOrUpdateItems', params).then(function (success) {
                openModel('quote');
            }).catch(function (error) {
                console.log(error);
            });

        }
    });

}

addQuoteFunctionality();

function productComparePage(replaceURL){
    var compareIdList = getCompareIdList().join(",");
    var url = window.location.href.split('?')[0];

    if (compareIdList != "") {
        url = url+"?compare="+compareIdList;
    }

    if (replaceURL) {
        window.location.replace(url);
    } else {
        window.location.href = url;
    }
}

function productCompareUnselectAll() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.compare-unselect-all')){
            event.preventDefault();

            var back = document.getElementById('compare-back-btn');

            if (back) {
                localStorage.setItem('compare'+compareCatId, JSON.stringify([]));
                window.location.href = back.getAttribute('href');
            } else {
                window.history.back();
            }
            
        }
    });
}
productCompareUnselectAll();

function quoteFormErrors(errors) {
    var forms = document.querySelectorAll('.quote-form');
    Array.from(forms).forEach(function(form) {
        for(var key in errors) {
            var node = createErrorNode(errors[key]);

            if(key == 'form') {
                form.appendChild(node);
                var scrollTop = form.offsetTop
            } else {
                var elem = form.querySelector('.quote-' + key);
                elem.parentElement.appendChild(node);
                var scrollTop = elem.parentElement.offsetTop
            }

            
            document.querySelectorAll('.add-to-quote-slider__slide').forEach(function (slider) {
                slider.scrollTop = scrollTop - 40;
            });
        }
    });
}

var submitQuoteBtn = document.querySelectorAll('.js_add-to-quote-submit');

if(submitQuoteBtn) {
    Array.from(submitQuoteBtn).forEach(function(btn) {
        btn.addEventListener('click', function (event) {
            event.preventDefault();
            var form = btn.closest('form');
            var isValid = form.checkValidity();
            if (isValid == false) {
                form.reportValidity();
                return false;
            }
            removeElementsByClass('error-speech-bubble');
            var btnValue = btn.value;
            btn.disabled = true;
            btn.value = btn.getAttribute("data-sending");

            var quoteForm = btn.closest('.quote-form');

            quoteAction('submit', quoteForm).then(function (success) {
                triggerEnquiryLeft();
                closeModel('quote');

                if (typeof (website_product_enquiry_success_page) !== 'undefined') {

                    document.cookie = "thank_you_page=quote; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
                    setTimeout(function() {
                        window.location.href = website_product_enquiry_success_page;
                    }, 250);

                } else {
                    openModel('product-enquiry-success');
                }

                var addToQuoteSlider = document.querySelectorAll('.js_add-to-quote-slider');

                Array.from(addToQuoteSlider).forEach(function(quoteSlider) {
                    var quoteContainer = quoteSlider.querySelector('.add-to-quote-slider__items');
                    quoteContainer.innerHTML = '';
                });

            }).catch(function (error) {
                quoteFormErrors(error);
                btn.disabled = false;
                btn.value = btnValue;
            });
        });
    });
}

var mobileCloseQuote = document.querySelector('.mobile-close-quote');

if(mobileCloseQuote) {
    mobileCloseQuote.addEventListener('click', function(close) {
        var mobileQuote = document.querySelector('.mobile-quote .add-to-quote-slider');

        if(mobileQuote) {
            mobileQuote.classList.remove('step-2');
            mobileQuote.classList.remove('step-3');
            getCart();
        }

        var currentSlide = document.querySelector('.slide-nav__slide.is-active');
        var homeSlide = document.querySelector('.home-slide');

        currentSlide.classList.remove('is-active');
        homeSlide.classList.add('is-open');
    });
}

function productChildSelector() {
    var selector = document.getElementById('product-page-child-selector');

    if(selector) {
        selector.addEventListener('change', function() {
            var index = selector.selectedIndex;
            var options = selector.options;
            var selectedOption = options[index];
            var price = selectedOption.getAttribute('data-price-number');

            document.getElementById('product-page-price').innerText = selectedOption.getAttribute('data-price');

            var discountAmount = Number(selectedOption.getAttribute('data-discount'));
            var discountElement = document.getElementById('product-page-discount');
            discountElement.classList.remove('d-block', 'd-none');
            if ((discountAmount > 0) && (price != '')) {
                discountElement.classList.add('d-block');
            } else {
                discountElement.classList.add('d-none');
            }
            discountElement.querySelector('.price-rrp').innerText = selectedOption.getAttribute('data-rrp');
            discountElement.querySelector('.price-discount').innerText = discountAmount;

            var displayFields = document.querySelectorAll('.display-price');
            Array.from(displayFields).forEach(function (field) {
                field.classList.remove('d-block', 'd-none');
                if (price == '') {
                    field.classList.add('d-none');
                } else {
                    field.classList.remove('d-none');
                }
            });

            var button = document.getElementById('product-page-add-button');
            button.setAttribute('data-id', selector.value);

            var cartEnabled = selectedOption.getAttribute('data-cart-enabled');
            var isSpecial = selectedOption.getAttribute('data-special');

            if ((price == '') || (cartEnabled == 0) || (isSpecial == 1)) {
                button.setAttribute('data-carttype', 'quote');
                button.innerText = jsTranslations['Add to Quote'];
            } else {
                button.setAttribute('data-carttype', 'eCommerce');
                button.innerText = jsTranslations['Add to Cart'];
            }

            var inStockMsg = document.getElementById('in-stock-message');

            if (inStockMsg) {
                var qty = selectedOption.getAttribute('data-qty');
                var leadVal = selectedOption.getAttribute('data-lead-time');
                var leadTime = document.getElementById('lead-time');

                if (qty == 0) {
                    inStockMsg.classList.add('d-none');
                } else {
                    inStockMsg.classList.remove('d-none');
                }

                leadTime.innerHTML = leadVal;
            }

        });
    }

    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('#product-page-add-button')){
            event.preventDefault();

            var button = event.target;
            var selector = document.getElementById('product-page-child-selector');
            var input = document.getElementById('product-page-quantity-input');
            
            if (selector) {
                var params = {
                    product_id: selector.value,
                    qty: Number(input.value)
                };
            } else {
                var params = {
                    product_id: button.getAttribute("data-id"),
                    qty: Number(input.value)
                };
            }

            if (button.getAttribute('data-carttype') == 'eCommerce') {

                if (selector) {
                    var index = selector.selectedIndex;
                    var options = selector.options;
                    var selectedOption = options[index];
                    showItemAddedModel(
                        selectedOption.getAttribute('data-name'),
                        selectedOption.getAttribute('data-price')
                    );
                } else {
                    showItemAddedModel(
                        document.querySelector('.product-detail__product-title').innerText,
                        document.getElementById('product-page-price').innerText
                    );
                }

                cartAction('add', params).then(function (success) {
                    addToCartTracking(selector.options[selector.selectedIndex], 'select-box', 0, '');
                    setCartTotal(success);
                }).catch(function (error) {
                    console.log(error);
                });
            }
            else {
                quoteAction('add', params).then(function (success) {
                    openModel('quote');
                }).catch(function (error) {
                    console.log(error);
                });
            }

        }
    });

}
productChildSelector();

function priceTables() {
    var priceTables = document.querySelectorAll('.price-table');
    Array.from(priceTables).forEach(function (table) {
        var priceCells = table.querySelectorAll('.price-row');
        if (priceCells.length == 0) {
            table.querySelector('.price-column').innerText = '';
        }
    });

    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.add-cart-link')){
            event.preventDefault();

            var button = event.target;
            var params = {
                product_id: button.getAttribute('data-product-id'),
                qty: 1
            };

            closeModel('product-accessories');

            showItemAddedModel(
                button.getAttribute('data-name'),
                button.getAttribute('data-price')
            );

            cartAction('add', params).then(function (success) {
                addToCartTracking(button, 'code-table', 0, '');
                setCartTotal(success);
            }).catch(function (error) {
                console.log(error);
            });
        }
    });
}
priceTables();

function view3DImages(){

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.product-3d-image, .product-3d-image *')) {
            event.preventDefault();

            var item = event.target;

            if (hasClass(item, 'product-3d-image') == false) {
                item = item.closest('.product-3d-image');
            }

            var local = item.getAttribute('data-local');

            var modal = new tingle.modal({
                footer: false,
                cssClass: ['popup-modal'],

                onOpen: function () {

                    // the checkOverflow() doesn't seem to work unless a resize is triggered.
                    window.dispatchEvent(new Event('resize'));


                    var close = document.querySelector(".tingle-modal__close");
                    var modalContent = document.querySelector(".tingle-modal-box__content");
                    modalContent.querySelector(".close-box").appendChild(close);
                    modalContent.querySelector(".tingle-modal__close").classList.add('tingle-modal__close-show');
                },

                onClose: function () {

                    // there's a bug where if you close a modal by clicking the backdrop, then
                    // open another modal, the content inside the modal is selected, so we're
                    // clearing the selection on close.
                    removeElementsByClass('popup-modal');

                },

            });

            var footer = '';
            if ((threeDImageData.length > 0) || (threeDCadData.length > 0)) {
                var index = 0;
                footer += '<div class="toggle toggle-previous"></div>';
                footer += '<div class="dots">';
                Array.from(threeDImageData).forEach(function(imageData) {
                    footer += '<div class="dot ' + (index==0 ? 'selected' : '') + '" data-type="image" data-folder-id="'+imageData['id']+'" data-product-name="'+imageData['name']+'" data-local="'+local+'"></div>';
                    index = index + 1;
                });
                Array.from(threeDCadData).forEach(function(imageData) {
                    footer += '<div class="dot ' + (index==0 ? 'selected' : '') + '" data-type="stl-file" data-stl-id="'+imageData['id']+'" data-product-name="'+imageData['name']+'" data-local="'+local+'"></div>';
                    index = index + 1;
                });
                footer += '</div>';
                footer += '<div class="toggle toggle-next"></div>';
            }

            if (threeDImageData.length > 0) {
                var initialName = threeDImageData[0]['name'];
                var initialUrl = '/3dimages/'+ threeDImageData[0]['id'];
            } else {
                var initialName = threeDCadData[0]['name'];
                var initialUrl = '/3dcad/'+ threeDCadData[0]['id'];
            }

            html = '';
            html = [
                '<div id="threeDImageHeading" class="f_bold t_color--highlight">'+initialName+'</div>',
                '<div class="close-box"></div>',
                '<div class="container-3d-images">',
                    '<iframe id="threeDImageFrame" src="'+initialUrl+'?local='+local+'" class="responsive-iframe" allowfullscreen="allowfullscreen"></iframe>',
                '</div>',
                '<div class="footer-3d-images">',
                footer,
                '</div>',
            ];

            modal.setContent(html.join('\n'));
            modal.open();
        }
    });
}
view3DImages();

function Toggle3DImage(){
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.footer-3d-images .toggle-previous, .footer-3d-images .toggle-next, .footer-3d-images .dot')) {
            event.preventDefault();
            var item = event.target;
            var selectedDot = item.parentElement.querySelector('.selected');
            var newDot = null;
            if (hasClass(item, 'toggle-previous')) {
                newDot = selectedDot.previousSibling;
            }
            else if (hasClass(item, 'toggle-next')) {
                newDot = selectedDot.nextSibling;
            }
            else if (hasClass(item, 'dot')) {
                newDot = item;
            }

            if (newDot !== null) {
                newDot.classList.add('selected');
                selectedDot.classList.remove('selected');
                if (newDot.getAttribute('data-type') == 'image') {
                    document.getElementById('threeDImageFrame').src = '/3dimages/'+newDot.getAttribute('data-folder-id')+'?local='+newDot.getAttribute('data-local');
                } else {
                    document.getElementById('threeDImageFrame').src = '/3dcad/'+newDot.getAttribute('data-stl-id')+'?local='+newDot.getAttribute('data-local');
                }
                document.getElementById('threeDImageHeading').innerText = newDot.getAttribute('data-product-name');
            }
        }
    });
}
Toggle3DImage();

function setMultiTable(index) {
    var tables = document.querySelectorAll('.multi-table-tab');
    Array.from(tables).forEach(function (table) {
        table.classList.add('d-none');
        if (hasClass(table, ('multi-table-index-'+index))) {
            table.classList.remove('d-none');
        }
    });
}

function toggleMultiTable() {
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.js_multi-table-carousel .swiper-slide')) {
            event.preventDefault();
            var index = event.target.getAttribute('data-active');
            setMultiTable(index);

            var tabs = document.querySelectorAll('.js_multi-table-carousel .swiper-slide');
            Array.from(tabs).forEach(function (tab) {
                tab.classList.remove('active');
                if (tab.getAttribute('data-active') == index) {
                    tab.classList.add('active');
                }
            });
        }
    });

    document.addEventListener('change', function (event) {
        if (event.target && event.target.matches('.js_multi-table-select')) {
            event.preventDefault();
            var index = event.target.value;
            setMultiTable(index);
        }
    });

    var tableSelectOption = document.querySelectorAll('.js_multi-table-select option');
    Array.from(tableSelectOption).forEach(function (option) {
        var id = option.getAttribute("data-codetable-index");
        if (id !== null) {
            var table = document.querySelector(".multi-table-index-" + id);
            if (table === null) {
                console.log(id);
                var affectedCtA = document.querySelectorAll('[data-codetable-index="' + id + '"]');
                Array.from(affectedCtA).forEach(function (cta) {
                    cta.classList.add('d-none');
                });
            }
        }
    });
}
toggleMultiTable();

function toggleTechnicalInfo() {
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.technical-info, .technical-info *')) {
            event.preventDefault();
            event.stopPropagation();
            var item = event.target;
            var parentElm = item;
            while (hasClass(parentElm, 'technical-info') == false) {
                parentElm = parentElm.parentElement;
            }
            var id = parentElm.getAttribute('data-id');

            var tables = document.querySelectorAll('.product-accessories-tables');
            Array.from(tables).forEach(function (table) {
                table.classList.add('d-none');
                if (table.getAttribute('data-id') == id) {
                    table.classList.remove('d-none');
                }
            });
            openModel('product-accessories');
        }
    });
}
toggleTechnicalInfo();

function setupTrackedResources() {
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.js_trackedResources, .js_trackedResources *')) {
            event.preventDefault();
            event.stopPropagation();
            openModel('tracked-resources-model');
        }
    });

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.js_tracked-resources-submit')) {
            event.preventDefault();
            var submitBtn = event.target;
            var form = submitBtn.closest('form');
            var isValid = form.checkValidity();
            if (isValid == false) {
                form.reportValidity();
                return false;
            }
            submitBtn.disabled = true;
            removeElementsByClass('error-speech-bubble');

            var form = document.querySelector('.tracked-resources');
            var formData = new FormData(form);

            var options = {
                method: 'POST',
                credentials: 'include',
                body: formData
            };


            sendRequest('/' + getCurrentLocale() + '/formssubmit/tracked-resources', options).then(function(success) {
                document.cookie = "thank_you_page=trackedresources; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
                setTimeout(function() {
                    window.location.href = website_tracked_resources_success_page;
                }, 250);
            }).catch(function(errors) {
                handleFormErrors(errors.error, form, '.tracked-resources-')
                submitBtn.disabled = false;
            });
        }
    });
}
setupTrackedResources();
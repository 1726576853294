/* 
    Path to Views is in app/Resources/views/Partials/Quote/BlueWater/fg-series-safety-gate-quote.html.twig
*/

var bluewaterFgSeriesSafetyGateQuoteSlider = document.querySelector('#bluewater-fg-series-safety-gate-quote-slider');

if (bluewaterFgSeriesSafetyGateQuoteSlider) {

    function triggerBluewaterFgSeriesSafetyGateQuote() {
        document.addEventListener('input', function (event) {
            if (event.target.matches('#bluewater-fg-series-safety-gate-quote-slider .fg-series-length, #bluewater-fg-series-safety-gate-quote-slider .fg-series-finish')) {
                event.preventDefault();
                var haveLength = false;
                var haveFinish = false;

                bluewaterFgSeriesSafetyGateQuoteSlider.querySelectorAll(".fg-series-length, .fg-series-finish").forEach(function (item) {

                    if (hasClass(item, 'fg-series-length')) {
                        if (item.value != "") {
                            haveLength = true;
                        }
                    }

                    if (hasClass(item, 'fg-series-finish')) {
                        if (item.checked) {
                            haveFinish = true;
                        }
                    }
                });
                if (haveLength && haveFinish) {
                    bluewaterFgSeriesSafetyGateQuoteSlider.querySelector(".js_continue-to-form").classList.remove("d-none");
                } else {
                    bluewaterFgSeriesSafetyGateQuoteSlider.querySelector(".js_continue-to-form").classList.add("d-none");
                }
            }
        });
    }
    triggerBluewaterFgSeriesSafetyGateQuote();
}
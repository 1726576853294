function toggleReturnsForm() {
  
  document.addEventListener('click', function(event) {
    if (event && event.target.matches('.js_return-checkbox')) {

      var returnsCheckbox = event.target;
      var returnsForm = returnsCheckbox.closest('.returns__group').querySelector('.js_return-form');

      if (returnsCheckbox.checked == true){
        returnsForm.classList.add('returns__form--is-visible');
        setRequireReturnFields(returnsForm, true);
      } else {
        returnsForm.classList.remove('returns__form--is-visible');
        setRequireReturnFields(returnsForm, false);
      }

    }
  });
}

toggleReturnsForm();

function setRequireReturnFields(form, enable) {

  var inputs = form.querySelectorAll('input, textarea');
  for(var i = 0; i < inputs.length; i++) {
    inputs[i].required = enable;
  }

  var selects = form.querySelectorAll('select');
  for(var j = 0; j < selects.length; j++) {
      selects[j].required = enable;
  }

}


function returnForm() {

  var returnForm = document.querySelector('.form__nav-returnForm');

  if (returnForm) {

      function returnFormErrors(errors) {
          for(var i = 0; i < errors.length; i++) {
              
              for (var key in errors[i]) {
                  var node = createErrorNode(errors[i][key]);

                  if (key == 'form') {
                      returnForm.appendChild(node);
                  } else {
                      var elem = returnForm.querySelector('.return-' + key);
                      elem.parentElement.appendChild(node);
                  }
              }
          }
      }

      var submitBtn = document.querySelector('.js_returnForm-submit');

      submitBtn.addEventListener('click', function(event) {
          event.preventDefault();
          submitBtn.disabled = true;
          removeElementsByClass('error-speech-bubble');

          var formData = new FormData(returnForm);

          var options = {
              method: 'POST',
              credentials: 'include',
              body: formData
          };

          sendRequest('/' + getCurrentLocale() + '/customer/portal/returnOrderSubmit', options).then(function(success) {
              window.location.href = '/' + getCurrentLocale() + '/customer/portal/orderhistorydetails?showreturns=s&id=' + success.data;
              submitBtn.disabled = false;
          }).catch(function(errors) {
              returnFormErrors(errors.error);
              submitBtn.disabled = false;
          });
      });

  }

}

returnForm();

function showReturnSuccessMessage() {
  var returns = document.querySelector('.js_popup[data-model="returns-message"]');
  if (returns) {
    openModel('returns-message');
    var path = window.location.pathname + "?id=" + returns.getAttribute("data-order-id");
    window.history.replaceState({}, document.title, path);
  }
}
showReturnSuccessMessage();
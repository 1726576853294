var videos = document.querySelectorAll('.product-videos.inline-video');

if(videos) {
    Array.from(videos).forEach(function(item) {
        var playBtn = item.querySelector('.video-teaser-controls__play-button');
        var videoPlayer = item.querySelector('.video_player');
        var content = item.querySelector('.content');


        playBtn.addEventListener('click', function(btn) {
            removeClass(videoPlayer, 'd-none');
            addClass(content, 'd-none');
            removeClass(item, 'active');
        });
    });
}
function webinarForm() {

    var webinarForm = document.querySelector('.webinar-form');

    if (webinarForm) {

        var submitBtn = document.querySelector('.js_webinar-submit');

        submitBtn.addEventListener('click', function(event) {
            event.preventDefault();
            submitBtn.disabled = true;
            removeElementsByClass('error-speech-bubble');

            var formData = new FormData(webinarForm);

            var options = {
                method: 'POST',
                credentials: 'include',
                body: formData
            };

            var form = document.querySelector('.webinar-form');

            sendRequest('/' + getCurrentLocale() + '/formssubmit/webinar-signup', options).then(function(success) {
                document.cookie = "thank_you_page=contact; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
                setTimeout(function() {
                    window.location.href = website_webinar_success_page;
                }, 250);
            }).catch(function(errors) {
                handleFormErrors(errors.error, form, '.webinar-')
                submitBtn.disabled = false;
            });
        });

    }
}

webinarForm();
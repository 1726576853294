function toggleModalCheckout() {
  document.addEventListener('click',function(event){
    if(event.target && event.target.matches('.js_order-summary__checkout-cta, .js_order-summary__checkout-cta *')) {
        event.preventDefault();
        if (userLoggedIn == false) {
          openModel('checkout-type');
        } else {
          gotoCheckoutPage();
        }
    }
  });

}
toggleModalCheckout();
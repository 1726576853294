/* 
    Path to Views is in app/Resources/views/Partials/Quote/BlueWater/ladder-guard-quote.html.twig
*/

var bluewaterLadderGuardQuoteSlider = document.querySelector('#bluewater-ladderguard-quote-slider');

if (bluewaterLadderGuardQuoteSlider) {

    bluewaterLadderGuardQuoteSlider.querySelector("#js_ladderguard-frame-selector").addEventListener('change', function (event) {
        event.preventDefault();
        if (this.value == "") {
            bluewaterLadderGuardQuoteSlider.querySelector(".js_button-continue-step2").classList.add("d-none");
        } else {
            bluewaterLadderGuardQuoteSlider.querySelector(".js_button-continue-step2").classList.remove("d-none");
        }
    });

    bluewaterLadderGuardQuoteSlider.querySelectorAll(".js_button-continue-step2").forEach(function (button) {
        button.addEventListener('click', function (event) {
            event.preventDefault();
            bluewaterLadderGuardQuoteSlider.querySelector(".step1").classList.add("d-none");
            bluewaterLadderGuardQuoteSlider.querySelector(".step2").classList.remove("d-none");
            document.querySelectorAll('.login-slider__slide').forEach(function (slider) {
                slider.scrollTop = 0;
            });
        });
    });

}
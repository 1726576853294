/**
 * Initialises the rollover effect on add to quote buttons for products, so that the text is shown
 */
function initProductAddToQuoteRollover()
{
    var addQuoteIcons = document.querySelectorAll('.product-teaser');

    if (addQuoteIcons) {
        Array.from(addQuoteIcons).forEach(function (item) {
            var target = item.querySelector('.product-teaser__add');
            if (target) {
                item.addEventListener('mouseover', function (event) {
                    var text = target.querySelector('span.add-quote');
                    if (text) {
                        text.style.opacity = 1;
                    }

                });

                item.addEventListener('mouseout', function (event) {
                    var text = target.querySelector('span.add-quote');
                    if (text) {
                        text.style.opacity = 0;
                    }
                });
            }
        });
    }
}

function updateProductHtml(data) {
    var productContainer = document.querySelector('.product-container');
    var html = [];
    var dClass = '';


    if (data.length) {
        data.forEach(function (item) {
            var productLayout = getCookies("product_layout");
            var keyFactsAvailable = (item.factA || item.factB || item.factC || item.factD || item.factE);
            if(enableListView===false){
                productLayout = 1;
                keyFactsAvailable = false;
            }
            var checkboxDisplay = (keyFactsAvailable)? "":"d-none";
            var enquiryDisplay = (productLayout != 1) ? "":"d-none";
            var quoteDisplay = (productLayout == 1) ? "":"d-none";
            
            html = html.concat([
                '<div class="product-teaser-container col-12 ',
            ]);

            if(enableListView){
                html = html.concat([
                    productLayout == 1 ? 'col-sm-12 col-md-6 col-lg-4 col-xl-3' : '',
                    ' product-teaser-container__with-listview',
                ]);
            } else {
                html = html.concat([
                    'col-sm-6 col-md-4 col-lg-3 col-xl-3',
                    ' product-teaser-container__without-listview',
                ]);
            }

            html = html.concat([
                '">',
            ]);

            html = html.concat([
               '<div class="product-teaser ',
                    productLayout == 1 ? 'grid-view' : 'list-view',
                '" data-url="' + item.link + '">',
            ]);

            if(enableListView){
               html = html.concat([
                    '<div class="product-teaser__grid-header">',
                        '<div class="field field__checkbox product-teaser__field" data-product-id="'+ item.id +'">',
                            '<label class="'+ checkboxDisplay +'">',
                                '<input type="checkbox" id="compareProductCheckboxId'+ item.id +'" name="compareProduct" value="'+ item.id +'" autocomplete="off">',
                                '<span class="checkbox"></span>',
                                '<span class="checkbox-text f_body product-teaser__checkbox-label">Compare</span>',
                            '</label>',
                        '</div>',
                    '</div>',
                    '<div class="product-teaser__body u_centred-text">',
                        '<h2 class="product-teaser__grid-title">' + item.name + '</h2>',
                    '</div>'
                ]);
            } else {

                if (item.canAddToQuote) {
                    html = html.concat([
                        '<span class="product-teaser__add">',
                            '<span class="icon i_basket"></span>',
                            '<input class="product-id" value="' + item.id + '" type="hidden" data-empty="false">',
                            '<span class="fs_seedling f_bold t_color--highlight add-quote '+quoteDisplay+'"><span class="add-quote-short-text">' + jsTranslations['Add to Quote'] + '</span></span>',
                        '</span>'
                    ]);
                }
            }

            html = html.concat([
                '<div class="product-teaser__image image-display u_flex u_flex--v-centre u_flex--h-centre ">',
                    '<picture>',
                        '<img src="' + item.image + '">',
                    '</picture>',
                '</div>',
            ]);

            if(enableListView){
                html = html.concat([
                    '<div class="key-facts">'
                ]);

                if (keyFactsAvailable) {
                    html = html.concat([
                        '<ul>',
                            item.factA != null? '<li class="key-facts__item">'+ item.factA + '</li>':"",
                            item.factB != null? '<li class="key-facts__item">'+ item.factB + '</li>':"",
                            item.factC != null? '<li class="key-facts__item key-facts__list">'+ item.factC + '</li>':"",
                            item.factD != null? '<li class="key-facts__item key-facts__list">'+ item.factD + '</li>':"",
                            item.factE != null? '<li class="key-facts__item key-facts__list">'+ item.factE + '</li>':"",
                        '</ul>',
                    ]);
                }

                html = html.concat([
                    '</div>'
                ]);

                html = html.concat([
                    '<p class="product-teaser__price is-bold">',
                    '</p>',
                    '<div class="product-teaser__view-product u_centred-text">',
                        '<a class="product-teaser__grid-link is-a-link is-bold is-a-link--not-underlined" href="' + item.link + '">',
                            jsTranslations['View more'],
                        '</a>',
                    '</div>'
                ]);

            } else {
                html = html.concat([
                    '<div class="product-teaser__body u_centred-text">',
                        '<h2 class="fs_cactus">' + item.name + '</h2>'
                ]);
                if(keyFactsAvailable) html = html.concat(['<div class="divider"></div>']);
                if(item.genCode)    html = html.concat(['<span class="fs_seedling t_color--mid"><b>' + item.genCode + '</b></span>']);
                html = html.concat([
                    '</div>'
                ]);
    
                if (item.longMarketingText) {
                    html = html.concat([
                        '<div class="long-marketing-text">',
                            '<h3>' + jsTranslations['Description']+':</h3>',
                            '<div class="long-marketing-text__info">', item.longMarketingText.replace(/(<([^>]+)>)/ig,"").substr(0,225).replace(/(?:\r\n|\r|\n)/g, '<br>'), '...</div>',
                        '</div>'
                    ]);
                }
    
                html = html.concat([
                    '<div class="product-teaser__view-product u_centred-text f_bold fs_seedling t_color--highlight">',
                        '<a href="' + item.link + '">' + jsTranslations['View Product'] + '</a>',
                    '</div>'
                ]);
            }

            html = html.concat([
                    '</div>',
                '</div>'
            ])
        });
    }

    //TODO - Add a rebind function. Might be better to add it to the quote branch instead.

    productContainer.innerHTML = html.join('\n');
    initProductAddToQuoteRollover();
}

function getCookies(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "1";
}

function getFilterGetter(options)
{
    var data = '';

    if (options.limit) {
        delete options.limit;
    }

    Object.keys(options).map(function (key, index) {
        data += '&' + key + '=' + options[key];
    });

    return data;
}

function updatePaginationHtml(data, lastPage, params)
{
    var getter = this.getFilterGetter(params);

    var paginationContainers = document.querySelectorAll('.pagination__main');

    var html = [
        '<svg xmlns="http://www.w3.org/2000/svg" class="before" transform=\'translate(0 180)\' style="fill:rgba(0, 158, 226, 1); opacity: .25;" viewBox="0 0 27.1 8.8"><path d="M22.7 0v3.8H0v1.3h22.7v3.8l4.4-4.4L22.7 0z"/></svg>',
        '<span class="pagination__number pagination__number--active u_flex u_flex--h-centre u_flex--v-centre">1</span>'
    ];

    if (data.length > 0 && data[0] != 0) {
        if (data.length > 0) {
            html = html.concat([
                '<span class="pagination__number-break">&hellip;</span>'
            ])
        }

        data.forEach(function (page) {
            html = html.concat([
                '<a href="?page=' + page + '&limit=20&from=page' + getter + '" class="f_light pagination__number u_flex u_flex--h-centre u_flex--v-centre">' + page + '</a>'
            ]);
        });

        if (lastPage < 1) {
            html = html.concat([
                '<svg xmlns="http://www.w3.org/2000/svg" class="after" style="fill:rgba(0, 158, 226, 1); opacity: .25;" viewBox="0 0 27.1 8.8"><path d="M22.7 0v3.8H0v1.3h22.7v3.8l4.4-4.4L22.7 0z"/></svg>'
            ]);
        } else {
            html = html.concat([
                '<a href="?page=2&limit=20&from=arrow' + getter + '">',
                '<svg xmlns="http://www.w3.org/2000/svg" class="after" style="fill:rgba(0, 158, 226, 1);" viewBox="0 0 27.1 8.8"><path d="M22.7 0v3.8H0v1.3h22.7v3.8l4.4-4.4L22.7 0z"/></svg>',
                '</a>'
            ]);
        }
    }

    paginationContainers.forEach(function (item) {
        item.innerHTML = html.join('\n');
    });
}

function updateShowingHtml(data)
{
    var showingContainers = document.querySelectorAll('.pagination__results-count');

    var html = [
        jsTranslations.Showing + ' <strong class="t_color--dark">' + data.from + ' - ' + data.to + '</strong> ' + jsTranslations['of'] + ' <strong class="t_color--dark">' + data.count
    ];

    Array.from(showingContainers).forEach(function (item) {
        item.innerHTML = html.join('\n');
    });
}

function updateLimits(params)
{
    var getter = this.getFilterGetter(params);

    var limits = document.querySelectorAll('.pagination__page-counts a');

    Array.from(limits).forEach(function (item) {
        var href = item.href;
        item.href = href + getter;
    });
}

function getActiveLimit()
{
    return document.querySelector('.pagination__page-counts strong').innerText.trim();
}

function updateProductTypes(productTypes, selected)
{
    var checked = selected === 0 ? 'checked' : '';
    var html = [
        '<li class="filter-dropdown__item f_bold">',
        '<label class="u_flex u_flex--h-spread">',
        '<span class="heading">' + jsTranslations.none + '</span>',
        '<input type="radio" name="filter" class="js_filter-input" data-filterid="product-type" value="0" ' + checked + '>',
        '</label>',
        '</li>'
    ];

    var productTypeDropdown = document.querySelector('.product-type');

    productTypes.forEach(function (item) {
        checked = selected === item.id ? 'checked' : '';
        html = html.concat([
            '<li class="filter-dropdown__item f_bold">',
            '<label class="u_flex u_flex--h-spread">',
            '<span class="heading">' + item.name + '</span>',
            '<input type="radio" name="filter" class="js_filter-input" data-filterid="product-type" value="' + item.id + '" ' + checked + '>',
            '</label>',
            '</li>'
        ]);
    });

    productTypeDropdown.innerHTML = html.join('\n');
}

function getActiveCategory()
{
    var categoryChoices = document.querySelectorAll('ul.category li input');

    var selected = Array.from(categoryChoices).filter(function (choice) {
        return choice.checked;
    });

    return selected[0] ? selected[0].value : 0;
}

//Used to take an object with key value pairing and then convert them to a formData object.
function prepareFormData(params)
{
    var formData = new FormData;

    for (var key in params) {
        formData.append(key, params[key]);
    }

    return formData;
}

function closeOpenedFilter()
{
    var openedFilter = document.querySelector('.js_filter.is-open');
    var sortBy = document.querySelector('.sort-container .d-block');

    if (openedFilter) {
        openedFilter.classList.remove('is-open');
    }

    if (sortBy) {
        var svg = sortBy.parentElement.querySelector('svg');

        sortBy.classList.add('d-none');
        sortBy.classList.remove('d-block');
        svg.setAttribute('transform', 'rotate(0)')
    }
}

function onFilterDropdownChange(event)
{
    //TODO - Need to update Sort by to use filter id then have it attached to params here rather than later.
    var self = event.target;
    var value = self.value;
    var option = self.getAttribute('data-filterid');

    options[option.replace('-', '')] = value;

    /*
        Check if none was selected for this category or not.
        If it was then need to check and delete the current value for category.
        Else we can just add.
     */
    if (value !== 0) {
        options[option.replace('-', '')] = value;
    } else {
        if (options[option.replace('-', '')]) {
            delete options[option.replace('-', '')];
        }
    }

    //If we are changing the category, reset the selected product type as this will change.
    if (options.category > 0 && option === 'category') {
        delete options.producttype;
    }

    filterProducts(options).then(function (success) {

    }).catch(function (error) {

    });
}

function filterProducts(params)
{
    params['limit'] = this.getActiveLimit();

    if (!params.category || params.category === 0) {
        params.category = getActiveCategory();
    }

    var formData = this.prepareFormData(params);

    return new Promise(function (resolve, reject) {
        fetch('/' + getCurrentLocale() + '/category/filter', {
            method: 'POST',
            credentials: 'include',
            body: formData
        }).then(function (res) {
            return res.json();
        }).then(function (res) {
            if (res.success) {
                updateProductHtml(res.data.products);
                updatePaginationHtml(res.data.pages, res.data.lastPage, params);
                updateShowingHtml(res.data.showing);
                updateLimits(params);
                updateProductTypes(res.data.productTypes, res.data.selected.producttype);
                setCompareCheckboxes();
                productTeaserMethod();
                toggleCompareButton();
                readjustProductTeaserHeight();
                resolve(res.data);
                closeOpenedFilter();
            } else {
                reject(res.error);
            }
        }).catch(function (error) {
            reject(error);
        });
    });
}


// initialise the product add to quote rollover
initProductAddToQuoteRollover();


var filterTriggers = document.querySelectorAll('.js_filter-trigger');
if (filterTriggers.length) {

    document.addEventListener('change', function(event) {
        if (event.target.matches('.js_filter-input')) {
            onFilterDropdownChange(event);
        }
    });

}

// initialise the filters
var filterOptions = document.querySelectorAll('.js_filter-input');
if (filterOptions) {
    var options = {};
    var sortBy = document.querySelector('.sort-by');

    if (sortBy) {
        var orderChoices = document.querySelectorAll('.sort-container input');

        if (orderChoices) {

            if (cartType != 'eCommerce') {
                document.addEventListener('change',function(event){
            
                    if(event.target && event.target.matches('.sort-container input')){
                        event.preventDefault();
                        var item = event.target;

                        var isElasticSearch = getUrlParameter('q');
                        var isCompare = getUrlParameter('compare');
                        var orderType = item.getAttribute('orderType');

                        if (isElasticSearch != '') {
                            location.replace(setUrlParameter('order', orderType));
                        } else if (isCompare != '') {
                            location.replace(setUrlParameter('ordertype', orderType));
                        } else {
                            options.orderkey = item.getAttribute('orderkey');
                            options.ordertype = orderType;

                            filterProducts(options).then(function (success) {

                            }).catch(function (error) {

                            });
                        }
                    }

                });
            }
        }
    }
}

function showHideFilters() {
    var filters = document.querySelectorAll('.product-filter__section-title');
    if (filters.length == 0) {
        var lfb = document.getElementById('left-filter-box');
        if (lfb) {
            lfb.classList.add('left-filter-box-hide');
        }
        var rrb = document.getElementById('right-results-box');
        if (rrb) {
            rrb.classList.remove('col-lg-9');
            rrb.classList.add('col-lg-12');
        }
    }

    if (typeof isCategoryMinVersion !== 'undefined') {
        if (isCategoryMinVersion == true) {
            document.getElementById('listGridBtns').parentNode.classList.add('d-none');
        }
    }
}
showHideFilters();
var customQuoteButtons = document.querySelectorAll('button[data-slider]');

if (customQuoteButtons.length > 0) {
    Array.from(customQuoteButtons).forEach(function(button) {
        button.addEventListener('click', function(event) { 
            event.preventDefault();
            customSliderId = button.getAttribute("data-slider");
            customSliderId = "bluewater-" + customSliderId.replace(/\s+/g, '-').toLowerCase() + "-slider";
            document.querySelector('#' + customSliderId).classList.add('step-1');
            document.body.classList.add('is-open');
        });
    });
}

function triggerBluewaterQuotes() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.open-guardrail-quote, .open-guardrail-quote *, .open-ladderguard-quote, .open-ladderguard-quote *, .open-lifeline-quote, .open-lifeline-quote *, .open-mobile-anchor-point-quote, .open-mobile-anchor-point-quote *, .open-flatbed-trailer-safety-rail-quote, .open-flatbed-trailer-safety-rail-quote *, .open-skylight-defender-quote, .open-skylight-defender-quote *')){
            event.preventDefault();
            var item = event.target;
            var customSliderId = "";
            
            // "Guardrail Quote,Ladderguard Quote,Lifeline Quote,Skylight Defender Quote,Mobile Anchor Point,Flatbed Trailer Safety Rail"
            if (item.classList.contains("open-guardrail-quote")) {
                customSliderId = "guardrail-quote";
            }
            if (item.classList.contains("open-ladderguard-quote")) {
                customSliderId = "ladderguard-quote";
            }
            if (item.classList.contains("open-lifeline-quote")) {
                customSliderId = "lifeline-quote";
            }
            if (item.classList.contains("open-flatbed-trailer-safety-rail-quote")) {
                customSliderId = "flatbed-trailer-safety-rail-quote";
            }
            if (item.classList.contains("open-mobile-anchor-point-quote")) {
                customSliderId = "mobile-anchor-point-quote";
            }
            if (item.classList.contains("open-skylight-defender-quote")) {
                customSliderId = "skylight-defender-quote";
            } 
            if (customSliderId != "") {

                // close all open sliders
                document.querySelector('.js_close-contact').click();
                document.querySelector('.js_close-feedback').click();
                var closeAllSlider = document.querySelectorAll('.js_close-slider');
                if (closeAllSlider) {
                    Array.from(closeAllSlider).forEach(function (close) {
                        close.click();
                    })
                }

                // open triggered slider
                customSliderId = "bluewater-" + customSliderId + "-slider";
                document.querySelector('#' + customSliderId).classList.add('step-1');
                document.body.classList.add('is-open');
            }
        }
    });
}

triggerBluewaterQuotes();

var continueToFormButtons = document.querySelectorAll('.js_continue-to-form');

if (continueToFormButtons.length > 0) {

    function quoteToText(form) {

        var name = form.getAttribute("name");
        var quote = "";

        if ((name == "bluewater-skylight-defender-quote-form") || (name == "bluewater-ladder-guard-quote-form")) {

            var fields = form.querySelector("section.quote-section").querySelectorAll('input, select');
            Array.from(fields).forEach(function (field) {

                if (field.tagName.toUpperCase() == 'INPUT') {
                    if (field.type == 'checkbox') {
                        if (field.checked == true) {
                            quote = quote + field.getAttribute("name") + ": " + "yes\n";
                        }
                    }

                    if (field.type == 'radio') {
                        if (field.checked == true) {
                            quote = quote + field.getAttribute("name") + ": " + "yes\n";
                        }
                    }

                    if (field.type == 'text') {
                        if (field.value != "") {
                            quote = quote + field.getAttribute("name") + ": " + field.value + "\n";
                        }
                    }
                } else {
                    if (field.value != "") {
                        quote = quote + field.getAttribute("name") + ": " + field.value + "\n";
                    }
                }
            });

        } else if (name == "bluewater-fg-series-safety-gate-quote-form") {

            var fields = form.querySelector("section.quote-section").querySelectorAll('input, select');
            Array.from(fields).forEach(function (field) {

                if (field.tagName.toUpperCase() == 'INPUT') {

                    if (field.type == 'radio') {
                        if (field.checked == true) {
                            quote = quote + field.getAttribute("name") + ": " + field.value + "\n";
                        }
                    }

                    if (field.type == 'text') {
                        if (field.value != "") {
                            quote = quote + field.getAttribute("name") + ": " + field.value + "\n";
                        }
                    }
                } else {
                    if (field.value != "") {
                        quote = quote + field.getAttribute("name") + ": " + field.value + "\n";
                    }
                }
            });

        } else if (name == "bluewater-guardrail-quote-form") {

            var grQuote = localStorage.getItem('grquote');
            try {
                grQuote = JSON.parse(grQuote);
                if (grQuote === null){
                    grQuote = {};
                }
            } catch (error) {
                grQuote = {};
            }

            for (var key in grQuote) {
                var section = grQuote[key];
                quote = quote + section["title"] + ":\n";

                for (var field in section["fields"]) {
                    var value = section["fields"][field];
                    quote = quote + field + ": " + value + "\n";
                }

                quote = quote + "\n\n";
            }
        }

        return quote;
    }

    Array.from(continueToFormButtons).forEach(function (button) {
        button.addEventListener('click', function (event) {
            event.preventDefault();
            var item = event.target;
            while(item.parentNode.tagName.toUpperCase() !== 'BODY') {
                item = item.parentNode;
                if (item.tagName.toUpperCase() == 'FORM') {
                    var form = item;
                    form.querySelector(".personal-section").classList.remove("d-none");
                    form.querySelector(".quote-section").classList.add("d-none");
                    document.querySelectorAll('.login-slider__slide').forEach(function (slider) {
                        slider.scrollTop = 0;
                    });

                    form.querySelector("input[name='raq-pool']").value = quoteToText(form);
                    break;
                }
            }
        });
    })
}

var goBackToQuoteButtons = document.querySelectorAll('.js_go-back-to-quote');

if (goBackToQuoteButtons.length > 0) {
    Array.from(goBackToQuoteButtons).forEach(function (button) {
        button.addEventListener('click', function (event) {
            event.preventDefault();
            var item = event.target;
            while(item.parentNode.tagName.toUpperCase() !== 'BODY') {
                item = item.parentNode;
                if (item.tagName.toUpperCase() == 'FORM') {
                    item.querySelector(".personal-section").classList.add("d-none");
                    item.querySelector(".quote-section").classList.remove("d-none");
                    break;
                }
            }
        });
    })
}

var selfValidateBlocks = document.querySelectorAll('.js_self-validate');

if (selfValidateBlocks.length > 0) {

    function quoteFormErrors(selfValidateBlock, errors) {
        for (var key in errors) {
            var node = createErrorNode(errors[key]);
            var scrollTop = 0;
            if (key == 'form') {
                selfValidateBlock.appendChild(node);
            } else {
                var elem = selfValidateBlock.querySelector('.quote-' + key);
                elem.parentElement.appendChild(node);
                scrollTop = elem.parentElement.offsetTop - 40;
            }
            document.querySelectorAll('.login-slider__slide').forEach(function (slider) {
                slider.scrollTop = scrollTop;
            });
        }
    }

    Array.from(selfValidateBlocks).forEach(function (selfValidateBlock) {

        var form = selfValidateBlock;
        while(form.parentNode.tagName.toUpperCase() !== 'BODY') {
            form = form.parentNode;
            if (form.tagName.toUpperCase() == 'FORM') {
                break;
            }
        }

        var submitBtn = form.querySelector("input[type='submit']");
        var fields = form.querySelectorAll('input, select');
        
        Array.from(fields).forEach(function (field) {
            field.classList.add("quote-" + field.getAttribute("name"));
            field.addEventListener('input', function(event) {
                activateSubmitButton(form, submitBtn);
            });
        });


        if(submitBtn) {

            submitBtn.addEventListener('click', function(event) {
                event.preventDefault();
                submitBtn.disabled = true;
                removeElementsByClass('error-speech-bubble');

                var formData = new FormData();
                var formValues = [];
                var terms = "";
                var token = "";

                var fields = form.querySelector(".personal-section").querySelectorAll('input, select, textarea');
                form.querySelector("input[name='quote-type']").value = form.getAttribute("name").replace("bluewater-", "").replace("-quote-form", "");

                Array.from(fields).forEach(function (field) {
                    var fieldName = field.getAttribute("name");

                    if (fieldName == "terms") {

                        formData.append('terms', field.value);

                    } else if (fieldName  == "_token") {

                        formData.append('_token', field.value);

                    } else if (fieldName != "send-form") {

                        formValues.push([
                            fieldName,
                            field.value
                        ]);

                    }
                });

                formData.append('data', JSON.stringify(formValues));

                var options = {
                    method: 'POST',
                    credentials: 'include',
                    body: formData
                };

                sendRequest('/' + getCurrentLocale() + '/quote-form/submit', options).then(function(success) {
                    selfValidateBlock.classList.add('d-none');

                    if (form.querySelector('.js_go-back-to-quote')) {
                        form.querySelector('.js_go-back-to-quote').classList.add('d-none');
                    }

                    var successPage = form.parentElement.querySelector('.quote-success');
                    successPage.classList.remove("d-none");
                    submitBtn.disabled = false;
                }).catch(function(errors) {
                    quoteFormErrors(selfValidateBlock, errors.error);
                    submitBtn.disabled = false;
                });
            });
        }
    })
}
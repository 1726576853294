if (document.getElementById("configurator-quote-slider")) {
  
  var state = {
    load: null,
    numFalls: null,
    liftSpeed: null,
    liftHeight: 3,
    phase: null,
    freq: null,
    suspension: "",
    ctrlCable: 2,
    total: null
  };

  var load = [250, 500, 1000, 2000];

  var numFalls = {
    250: [1],
    500: [2, 1],
    1000: [2, 1],
    2000: [2]
  };

  var liftSpeedValue = ['4', '5', '8', '10', '1/4', '2/8', '8/2'];

  // load_numfalls
  var liftSpeed = {
    '250_1': [3],
    '500_1': [2, 6],
    '500_2': [1],
    '1000_1': [2, 5],
    '1000_2': [0, 4],
    '2000_2': [0, 4]
  };

  // load_numFalls_liftSpeed
  var phase = {
    '250_1_3': [3],
    '500_1_2': [3],
    '500_1_6': [3],
    '500_2_1': [3],
    '1000_1_2': [3],
    '1000_1_5': [3],
    '1000_2_0': [3],
    '1000_2_4': [3],
    '2000_2_0': [3],
    '2000_2_4': [3]
  };

  // load_numFalls_liftSpeed_phase
  var frequency = {
    '250_1_3_1': [50],
    '250_1_3_3': [50],
    '500_1_2_1': [50],
    '500_1_2_3': [50],
    '500_1_6_1': [50],
    '500_1_6_3': [50],
    '500_2_1_1': [50],
    '500_2_1_3': [50],
    '1000_1_2_1': [50],
    '1000_1_2_3': [50],
    '1000_1_5_1': [50],
    '1000_1_5_3': [50],
    '1000_2_0_1': [50],
    '1000_2_0_3': [50],
    '1000_2_4_1': [50],
    '1000_2_4_3': [50],
    '2000_2_0_1': [50],
    '2000_2_0_3': [50],
    '2000_2_4_1': [50],
    '2000_2_4_3': [50]
  }

  // load_numFalls_liftSpeed_phase_freq
  var volt = {
    '250_1_3_1_50': [400],
    '250_1_3_1_60': [400],
    '250_1_3_3_50': [400],
    '250_1_3_3_60': [400],
    '500_1_2_1_50': [400],
    '500_1_2_1_60': [400],
    '500_1_2_3_50': [400],
    '500_1_2_3_60': [400],
    '500_1_6_1_50': [400],
    '500_1_6_1_60': [400],
    '500_1_6_3_50': [400],
    '500_1_6_3_60': [400],
    '500_2_1_1_50': [400],
    '500_2_1_1_60': [400],
    '500_2_1_3_50': [400],
    '500_2_1_3_60': [400],
    '1000_1_2_1_50': [400],
    '1000_1_2_1_60': [400],
    '1000_1_2_3_50': [400],
    '1000_1_2_3_60': [400],
    '1000_1_5_1_50': [400],
    '1000_1_5_1_60': [400],
    '1000_1_5_3_50': [400],
    '1000_1_5_3_60': [400],
    '1000_2_0_1_50': [400],
    '1000_2_0_1_60': [400],
    '1000_2_0_3_50': [400],
    '1000_2_0_3_60': [400],
    '1000_2_4_1_50': [400],
    '1000_2_4_1_60': [400],
    '1000_2_4_3_50': [400],
    '1000_2_4_3_60': [400],
    '2000_2_0_1_50': [400],
    '2000_2_0_1_60': [400],
    '2000_2_0_3_50': [400],
    '2000_2_0_3_60': [400],
    '2000_2_4_1_50': [400],
    '2000_2_4_1_60': [400],
    '2000_2_4_3_50': [400],
    '2000_2_4_3_60': [400]
  }

  var suspension = [
    vtTans["With hook"],
    vtTans["Eye suspension"],
    vtTans["Corso Electric trolley"],
    vtTans["Corso Push Trolley"],
    vtTans["Corso Beam Clamp"],
    vtTans["Corso Push Trolley Direct Coupling"],
    vtTans["Corso Push Trolley Extended Hanger bar"]
  ];

  var electricSpeed = [
    vtTans["10 m/mn"],
    vtTans["19/6 m/mn"],
    vtTans["9/3 m/mn"]
  ];

  var limitSwitch = [
    vtTans["Yes"], 
    vtTans["No"]
  ];

  var control = [
    vtTans["Pendant control"],
    vtTans["Remote control"]
  ];

}
function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}

var searchForm = document.getElementById('gather-search-form');

function searchAction() {
    var input = document.getElementById('search-input').value;

    if (input.length > 0) {
        var codedInput = b64EncodeUnicode(input);

        window.location.href = '/' + getCurrentLocale() + '/search?q=' + codedInput + '&search=' + encodeURIComponent(input);
    }
}

function showSortByDropdown() {

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.sort-by')) {
            event.preventDefault();

            sortByZIndexToggle(false);

            var sortBy = event.target;
            var dropDown = sortBy.parentElement.querySelector('ul');
            var svg = sortBy.parentElement.querySelector('svg');

            if(dropDown.classList.contains('d-none')) {
                dropDown.classList.remove('d-none');
                dropDown.classList.add('d-block');
                svg.setAttribute('transform', 'rotate(180)')
            } else {
                dropDown.classList.remove('d-block');
                dropDown.classList.add('d-none');
                svg.setAttribute('transform', 'rotate(0)')
            }
        }
    });
}
showSortByDropdown();

var searchPage = document.querySelector('.page-search');

if(searchPage) {
    var orderChoices = document.querySelectorAll('.sort-container input');

    if(orderChoices) {
        Array.from(orderChoices).forEach(function(choice) {
            choice.addEventListener('click', function(event) {
                //TODO - Need to change this to be similar to filters in the future.
                var query = getParameterByName('q');
                var limit = getActiveLimit();
                var page = 1;

                var orderkey = this.getAttribute('orderkey');
                var ordertype = this.getAttribute('ordertype');

                var getter = '?q=' + query + '&page=' + page + '&limit=' + limit + '&from=order' + '&orderkey=' + orderkey + '&ordertype=' + ordertype;

                window.location = '/' + getCurrentLocale() + '/search/results' + getter;
            });
        });
    }
}


if(searchForm) {
    searchForm.addEventListener('submit', function (event) {
        event.preventDefault();
        searchAction();
    });

    var searchBtn = document.getElementById('gather-search-button');

    searchBtn.addEventListener('click', function (event) {
        event.preventDefault();
        searchAction();
    });
}

var autoCompleteCatch = {};

function autoCompleteSearch() {

    var searchInput = document.getElementById('search-input');

    if (searchInput) {
        var searchEvents = ['keyup', 'click'];
        Array.from(searchEvents).forEach(function(searchEvent) {
            searchInput.addEventListener(searchEvent, function (event) {

                sortByZIndexToggle(true);
                
                var value = searchInput.value.replace(/^\s+|\s+$/gm,'');

                // empty results for empty query
                if (value == '') {
                    buildAutoComplete([], '');
                } 
                // used cached result first
                else if(value in autoCompleteCatch) {
                    buildAutoComplete(autoCompleteCatch[value], value);
                }
                // get result from server
                else {

                    var options = {
                        method: 'POST',
                        body: prepareFormData(
                            {
                                'q': value
                            }
                        )
                    };

                    sendRequest('/' + getCurrentLocale() + '/autocomplete', options).then(function(success) {
                        autoCompleteCatch[value] = success.data;
                        buildAutoComplete(success.data, value);
                    }).catch(function(errors) {
                        emptyOutAutoComplete();
                    });
                }
            });
        });

        searchInput.addEventListener('focusout', function (event) {
            setTimeout(function() {
                emptyOutAutoComplete();
            }, 350);
        });
    }

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.nav__search__dropdown-row, .nav__search__dropdown-row *')) {
            var link = event.target;
            if (hasClass(link, 'nav__search__dropdown-row') == false) {
                link = link.closest('.nav__search__dropdown-row');
            }
            eeAutoCompleteClick(link);
        }
    });
}
autoCompleteSearch();

function emptyOutAutoComplete() {
    var rows = document.querySelectorAll('.nav__search__dropdown-row');

    Array.from(rows).forEach(function(row) {
        row.classList.add('d-none');
    });
}

function boldString(str, find) {
    var reg = new RegExp('('+find+')', 'gi');
    return str.replace(reg, '<b>$1</b>');
  }

function buildAutoComplete(data, query) {
    emptyOutAutoComplete();

    var rows = document.querySelectorAll('.nav__search__dropdown-row');
    var index = 0;
    var position = 1;
    Array.from(data).forEach(function(value) {
        var row = rows[index];

        val = value.name;
        if (query.length > 1) {
            val = boldString(val, query).replace(/ /g, '&nbsp;');
        }

        row.querySelector('.autocomplete-name').innerHTML = val;

        var codeCell = row.querySelector('.autocomplete-code');
        if (value.genCode == '') {
            codeCell.classList.add('d-none');
        } else {
            codeCell.classList.remove('d-none');
            codeCell.querySelector('span').innerHTML = value.genCode;
        }

        row.classList.remove('d-none');
        row.setAttribute('data-href', value.link);

        if (value['type'] == 'product') {
            row.setAttribute('data-name', value['name']);
            row.setAttribute('data-id', value['id']);
            row.setAttribute('data-currency', value['currency']);
            if (value['brand'] != '') {
                row.setAttribute('data-brand', value['brand']);
            }
            if (value['categories'] != '') {
                row.setAttribute('data-eccategory', value['categories']);
            }
            row.setAttribute('data-position', position);
            eeAutoCompleteImpression(row);
        }
        position = position + 1;
        index = index + 1;
    });
}
if (document.getElementById("configurator-quote-slider")) {
  /*
    Configurator Quote Forms
  */

  var configuratorCurrentStep = 0;

  function triggerConfiguratorQuotes() {
    document.addEventListener('click', function (event) {

      if (event.target && event.target.matches('.open-configurator-volt-trac')) {
        event.preventDefault();

        openModel('volt-trac');

        var configDots = document.querySelector('.configurator-dots');
        if (hasClass(configDots, 'd-none') == false) {
          configuratorCurrentStep = 0;
          configuratorGotoStep('start');
          startConfigurator();
        }
      }
    });
  }

  triggerConfiguratorQuotes();


  function closeConfiguratorSteps() {

    var backButtons = document.querySelectorAll('configurator-back-btn');
    Array.from(backButtons).forEach(function(button) {
      button.classList.remove('d-none');
    });

    document.querySelector('#configurator-quote-slider .dots').classList.remove('d-none');
    document.querySelector('#configurator-quote-slider .configurator-dots .u_flex').classList.remove('u_flex--h-end');
    document.querySelector('#configurator-quote-slider .configurator-dots .u_flex').classList.add('u_flex--h-spread');
    document.getElementById('configurator-intro').classList.add('d-none');
    document.getElementById('configurator-chain-hoist').classList.add('d-none');
    document.getElementById('configurator-power-supply').classList.add('d-none');
    document.getElementById('configurator-suspension-section').classList.add('d-none');
    document.getElementById('configurator-control-section').classList.add('d-none');
    document.getElementById('configurator-result-section').classList.add('d-none');
    document.getElementById('configurator-details').classList.add('d-none');
  }

  function configuratorUpdateSlide(name, direction) {
    var section = document.getElementById(name);
    section.classList.remove('d-none');
    if (direction == 'forward') {
      var sibling = getPreviousSibling(section, '#'.name);
      sibling.classList.remove('popup-next-slide');
      sibling.classList.add('popup-prev-slide');
    } else {
      var sibling = getNextSibling(section, '#'.name);
      sibling.classList.add('popup-next-slide');
      sibling.classList.remove('popup-prev-slide');
    }

    var bullets = document.getElementById("configurator-quote-slider").querySelectorAll('.configurator-dots .dots div');
    var index = 1;
    Array.from(bullets).forEach(function (bullet) {
      bullet.classList.remove('selected');
      if (index <= configuratorCurrentStep) {
        bullet.classList.add('selected');
      }
      index++;
    });

  }

  function configuratorGotoStep(direction) {
    closeConfiguratorSteps();

    document.querySelectorAll('.login-slider__slide').forEach(function (slider) {
      slider.scrollTop = 0;
    });

    switch (configuratorCurrentStep) {
      case 0:
        document.getElementById('configurator-intro').classList.remove('d-none');

        var backButtons = document.querySelectorAll('configurator-back-btn');
        Array.from(backButtons).forEach(function(button) {
          button.classList.add('d-none');
        });

        document.querySelector('#configurator-quote-slider .dots').classList.add('d-none');
        document.querySelector('#configurator-quote-slider .configurator-dots .u_flex').classList.add('u_flex--h-end');
        document.querySelector('#configurator-quote-slider .configurator-dots .u_flex').classList.remove('u_flex--h-spread');
        break;
      case 1:
        configuratorUpdateSlide('configurator-chain-hoist', direction);
        break;
      case 2:
        configuratorUpdateSlide('configurator-power-supply', direction);
        break;
      case 3:
        configuratorUpdateSlide('configurator-suspension-section', direction);
        break;
      case 4:
        configuratorUpdateSlide('configurator-control-section', direction);
        break;
      case 5:
        updateTable();
        configuratorUpdateSlide('configurator-result-section', direction);
        break;
      case 6:
        configuratorUpdateSlide('configurator-details', direction);
        break;
      case 7:

        var backButtons = document.querySelectorAll('configurator-back-btn');
        Array.from(backButtons).forEach(function(button) {
          button.classList.add('d-none');
        });

        document.getElementById('configurator-details').classList.add('d-none');
        document.getElementById('configurator-success').classList.remove('d-none');
        document.querySelector('.configurator-dots').classList.add('d-none');
        document.getElementById('configurator-quote-slider').querySelector('.popup-model__left').classList.add('d-none');
        document.getElementById('configurator-quote-slider').querySelector('.popup-model').classList.add('popup-model--notification');
        break;
    }
  }

  function submitVoltTracForm(item, form) {

    var fullQuote = document.getElementById('configurator-quote-slider');
    item.disabled = true;

    var formData = new FormData();
    var formValues = [];

    var fields = fullQuote.querySelectorAll('input, select');

    Array.from(fields).forEach(function (field) {

      var fieldName = field.getAttribute("name");
      if (fieldName === null) {
        fieldName = field.getAttribute("id");
      }
      fieldName = fieldName.replace('configurator-', '');
      fieldName = fieldName.replace('configurator', '');
      if (fieldName.indexOf('volt_trac_form[') != -1) {
        fieldName = fieldName.replace('volt_trac_form[', '');
        fieldName = fieldName.replace(']', '');
      }

      if (fieldName == "terms") {
        formData.append('terms', field.checked);
      } else if (fieldName == "_token") {
        formData.append('_token', field.value);
      } else  {
        formValues.push([
          fieldName,
          field.value
        ]);
      }

    });

    formData.append('data', JSON.stringify(formValues));

    var options = {
        method: 'POST',
        credentials: 'include',
        body: formData
    };

    sendRequest('/' + getCurrentLocale() + '/volttrac/submit', options).then(function(success) {
      configuratorCurrentStep++;
      configuratorGotoStep('forward');
    }).catch(function(errors) {
      handleFormErrors(errors.error, form, '.volttrac-form-')
      submitBtn.disabled = false;
    });

  }

  function configuratorToggleSteps() {

    document.addEventListener('click', function (event) {

      if (event.target) {

        if (event.target.matches('.js_configurator-intro')) {

          event.preventDefault();
          var item = event.target;
          configuratorCurrentStep = 1;
          configuratorGotoStep('forward');

        }
        else if (event.target.matches('.js_configurator-checkform')) {

          event.preventDefault();
          var item = event.target;
          var form = item.closest('form');

          if (form && form.checkValidity() == false) {
            form.reportValidity();
          }
          else {
            console.log(configuratorCurrentStep);
            if (configuratorCurrentStep == 6) {
              submitVoltTracForm(item, form);
            } else {
              configuratorCurrentStep++;
              configuratorGotoStep('forward');
            }
          }

          var container = item.closest('.popup-container');
          container.scrollTop = 0;
        }
        else if (event.target.matches('.configurator-back-btn')) {

          event.preventDefault();
          var item = event.target;

          if (configuratorCurrentStep > 1) {
            configuratorCurrentStep--;
            configuratorGotoStep('back');
          }

          var container = item.closest('.popup-container');
          container.scrollTop = 0;

        }
      }
    });
  }

  configuratorToggleSteps();
}
/*
    Used to make requests to quote end points.
    Action is the controller action you wish to execute whilst params are the post data.
 */
function downloadAction(id) {
    window.location.assign('/download/asset/' + parseInt(id, 10));
}

function downloadAssets() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.js_download-asset, .js_download-asset *')){
            event.preventDefault();
            var item = event.target;

            if (hasClass(item, 'js_download-asset') == false) {
                item = item.closest('.js_download-asset');
            }

            var id = item.getAttribute('asset-id');

            downloadAction(id);
        }
    });
}

downloadAssets();
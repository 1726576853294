function buildFrontendFormSubmissionLogic(formId, formRoute) {

    var form = document.getElementById(formId);
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('#' + formId + ' .js_submit-dynamic-form-data' )){
            event.preventDefault();
            var isValid = form.checkValidity();
            if (isValid == false) {
              form.reportValidity();
                return false;
            }

            var results = {};

            var fields = form.querySelectorAll('input, select, textarea');
            Array.from(fields).forEach(function (field) {
                var tagName = field.tagName.toUpperCase();
                var selectedField = null;
                if (tagName == 'SELECT') {
                    selectedField = field.options[ field.selectedIndex ];
                }
                else if (tagName == 'INPUT') {

                    if (field.type == 'radio') {
                        if (field.checked) {
                            selectedField = field;
                        }
                    }

                }

                if (selectedField !== null) {
                    if (selectedField.hasAttribute('data-products')) {
                        var productIds = selectedField.getAttribute('data-products');
                        if (productIds != '') {
                            Array.from(productIds.split(',')).forEach(function (productId) {
                                if (results.hasOwnProperty(productId)) {
                                    results[productId] = results[productId] + 1;
                                } else {
                                    results[productId] = 1;
                                }
                            });
                        }
                    }
                }

            });

            console.log(results);
            console.log(formRoute);
        }
    });
}
function productTeaserMethod() {

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('#grid-view-btn, #list-view-btn')) {
            var trigger = event.target.getAttribute('id');
            toggleListGridView(trigger);
        }
    });

}

function toggleListGridView(trigger) {

    var products = document.querySelectorAll('.product-teaser-container');
    
    if (enableListView === true) {
        var gridViewBtn = document.getElementById('grid-view-btn');
        var listViewBtn = document.getElementById('list-view-btn');
        var gridViewMsg = document.getElementById('grid-view-msg');
        var listViewMsg = document.getElementById('list-view-msg');
        var addQuoteShortMsg = document.querySelectorAll('.add-quote-short-text');
        var addQuoteLongMsg = document.querySelectorAll('.add-quote-long-text');

        gridViewBtn.classList.add('active');
        listViewBtn.classList.add('active');
        gridViewMsg.classList.add('d-none');
        listViewMsg.classList.add('d-none');

        Array.from(addQuoteShortMsg).forEach(function (label) {
            label.classList.add('d-none');
        });

        Array.from(addQuoteLongMsg).forEach(function (label) {
            label.classList.add('d-none');
        });
    }


    if (products) {
        var filterDefined = document.getElementById('js_filterfield');
        Array.from(products).forEach(function (product) {
            var productTeaser = product.querySelector('.product-teaser');
            var layout = 1;
            if (trigger == 'grid-view-btn') {
                productTeaser.classList.add('grid-view');
                productTeaser.classList.remove('list-view');
                if (filterDefined) {
                    product.classList.add('col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-4');
                } else {
                    product.classList.add('col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-3');
                }
                if (enableListView === true) {
                    listViewBtn.classList.remove('active');
                    gridViewMsg.classList.remove('d-none');
                    Array.from(addQuoteShortMsg).forEach(function (label) {
                        label.classList.remove('d-none');
                    });
                }
            } else {
                productTeaser.classList.add('list-view');
                productTeaser.classList.remove('grid-view');
                if (filterDefined) {
                    product.classList.remove('col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-4');
                } else {
                    product.classList.remove('col-sm-12', 'col-md-6', 'col-lg-4', 'col-xl-3');
                }
                if (enableListView === true) {
                    gridViewBtn.classList.remove('active');
                    listViewMsg.classList.remove('d-none');
                    Array.from(addQuoteLongMsg).forEach(function (label) {
                        label.classList.remove('d-none');
                    });
                }
                layout = 2;
            }
            document.cookie = "product_layout=" + layout + "; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
            
        });

        gridviewCompareHeightFix(trigger, true);
        readjustProductTeaserHeight();
        gridviewCompareHeightFix(trigger, false)

    }

    toggleCompareButton();
}

function gridviewCompareHeightFix(trigger, positionRel) {
    var compareBtn = document.querySelectorAll(".compareBtn");
    if (compareBtn.length > 0) {
        if (window.location.search.indexOf('compare=') > -1) {
            if (trigger == 'grid-view-btn') {
                var productTeaserPrice = document.querySelectorAll('.product-teaser__price');
                Array.from(productTeaserPrice).forEach(function (item) {
                    if (positionRel == true) {
                        item.classList.add('position-relative');
                    } else {
                        item.classList.remove('position-relative');
                    }
                });
            }
        }
    }
}

function initializeListGridView() {
    var productLayout = getCookies("product_layout");

    if ( enableListView === false) {
        productLayout = 1;
    }

    if (productLayout == 1) {
        toggleListGridView('grid-view-btn');
    } else {
        toggleListGridView('list-view-btn');
    }

    var productTeaserContainers = document.querySelectorAll('.product-teaser-container');
    Array.from(productTeaserContainers).forEach(function (productTeaser) {
        productTeaser.classList.remove('d-none');
    });

    readjustProductTeaserHeight();
}

ready(function () {
    if (typeof(enableListView) === 'undefined') {
        enableListView = false;
    }
    if (enableListView) {
        initializeListGridView();
        setCompareCheckboxes();
    }
});

function showAllCompareBtns(buttons){
    Array.from(buttons).forEach(function(button) {
        button.classList.remove('d-none');
    });
}

function hideAllCompareBtns(buttons){
    Array.from(buttons).forEach(function(button) {
        button.classList.add('d-none');
    });
}

function toggleCompareButton() {

    var compareBtn = document.querySelectorAll(".compareBtn");

    if (compareBtn.length > 0) {

        if (window.location.search.indexOf('q=') > -1) {
            hideAllCompareBtns(compareBtn);
        } else {
            showAllCompareBtns(compareBtn);
        }

        if (localStorage.getItem("compare"+compareCatId) == "") {
            hideAllCompareBtns(compareBtn);
        }

        if (localStorage.getItem("compare"+compareCatId) == "[]") {
            hideAllCompareBtns(compareBtn);
        }

        if (localStorage.getItem("compare"+compareCatId) === null) {
            hideAllCompareBtns(compareBtn);
        } 

        var compareCount = getCompareIdList().length;
        if (compareCount < 2) {
            hideAllCompareBtns(compareBtn);
        }
        var compareSelectTotal = document.getElementById('compare-select-total');
        if (compareSelectTotal) {
            compareSelectTotal.innerText = compareCount;
        }

        if (typeof(enableListView) !== 'undefined') {
            if (enableListView == false) {
                hideAllCompareBtns(compareBtn);
            }
        }

        if (window.location.search.indexOf('compare=') > -1) {
            hideAllCompareBtns(compareBtn);

            var filterItems = document.querySelectorAll('.filter__item');
            Array.from(filterItems).forEach(function (item) {
                item.classList.add('disable');
            });

            document.querySelector('.product-pagination__content').parentNode.classList.add('product-pagination__compare');

            document.querySelector('.product-pagination__content-title').classList.add('d-none');
            document.querySelector('.pagination__results-count').closest('.col-xs-12').classList.add('d-none');
            document.querySelector('.pagination__page-counts').closest('.col-xs-12').classList.add('d-none');
        }
    }
}

function getCompareIdList() {
    //get current compare list from localstorage
    if (typeof(compareCatId) === 'undefined') {
        return [];
    }
    var idList = localStorage.getItem("compare"+compareCatId)
    try {
        idList = JSON.parse(idList);
        if (idList === null){
            idList = [];
        }
    } catch (error) {
        idList = [];
    }
    return idList;
}

function setCompareCheckboxes() {
    if (hasClass(document.querySelector('body'), 'website_show_compare')) {
        var compareIdList = getCompareIdList();
        var comparePageCount = document.getElementById('compare-page-count');
        if (comparePageCount) {
            comparePageCount.innerText = '(' + compareIdList.length + ')';
            if (compareIdList.length <= 0) {
                window.history.back();
            }
        }

        //set compare checkboxes
        var i;
        for (i = 0; i < compareIdList.length; i++) {
            var product_id = compareIdList[i];
            var checkElm = document.getElementById("compareProductCheckboxId" + product_id);
            if (checkElm) {
                checkElm.checked = true;
                checkElm.closest('.product-teaser').classList.add('added-to-compare');
            }
        }
    }
}

productTeaserMethod();
toggleCompareButton();

window.onunload = function(){}; 
function onPageNavigation() {
    document.addEventListener('click', function(event) {
        if (event.target.matches('.header__on-page-nav div[data-target], .header__on-page-nav div[data-target] *')) {

            event.preventDefault();
            var link = event.target.closest('div[data-target]');
            var nav = event.target.closest('nav');
            var screenWidth  = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            var scrollTo = true;

            if (screenWidth <= 767) {
                if (hasClass(link, 'first')) {
                    if (!hasClass(nav, 'is-open')) {
                        nav.classList.add('is-open');
                        scrollTo = false;
                    }
                    else {
                        nav.classList.remove('is-open');
                    }
                }
                else {
                    nav.classList.remove('is-open');
                }
            }
            else {
                nav.classList.remove('is-open');
            }

            if (scrollTo) {
                var targetId = link.getAttribute('data-target');

                if (targetId != '') {
                    var target = document.querySelector('[data-opn="'+targetId+'"]');
                    scrollDownToSection(target, 5, 0);
                }
            }
        }
    });
}
onPageNavigation();

function initializeHeader() {
    var header = document.getElementById('mainHeaderBlock');
    header.classList.add('header--slim');
    if (showPageNavigationBar) {
    
        var menuNode = document.createElement("div");
        menuNode.setAttribute('class', 'container-fluid header__on-page-nav');

        var html = [];
        html = html.concat([
            '<nav class="container">',
            '<div data-target="" class="nav-title"><span class="index"></span><span>'+navPageContentTrans+'</span><span class="icon i_close-white"></span></div>'
        ]);

        var navs = document.querySelectorAll('[data-opn]');
        var i = 0;
        Array.from(navs).forEach(function (nav) {
            var name = nav.getAttribute('data-opn');
            if (name != '') {
                i = i + 1;
                var s = '';
                if (i < 10) {
                    s = String('0' + i);
                } else {
                    s = String(i);
                }
                var first = '';
                if (i == 1) {
                    first = 'class="first"';
                }

                html = html.concat([
                    '<div data-target="'+name+'" '+first+'><span class="index">'+s+'</span><span class="text">'+name+'</span><span class="icon i_chevron-down-white"></span></div>'
                ]);
            }
        });

        html = html.concat([
            '</nav>'
        ]);

        menuNode.innerHTML = html.join('\n');

        var referenceNode = document.getElementById('mainHeaderBlock');
        referenceNode.insertBefore(menuNode, referenceNode.children[0]);
    }
}

function addBlueBannerFunc() {
    document.getElementById('mainHeaderBlock').classList.add('add-blue-backdrop');
}
var accountForm = document.querySelector('.account-page');

if(accountForm) {
    var accountBtn = document.querySelector('.js_account-submit');

    accountBtn.addEventListener('click', function(event) {
        removeElementsByClass('error-speech-bubble');
        event.preventDefault();

        var formData = new FormData(accountForm);

        var options = {
            method: 'POST',
            credentials: 'include',
            body: formData
        };

        sendRequest('/' + getCurrentLocale() + '/api/customer/portal/accountSave', options).then(function(success) {
            accountBtn.value = jsTranslations.userFormSuccess;
            var savedText = informationForm.querySelector('.success-text');
            savedText.classList.add('d-block');
            savedText.classList.remove('d-none');
        }).catch(function(errors) {
            handleFormErrors(errors.error, accountForm, '#user-');
        });
    });
}

function removeAddressFromAccount(){
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.js_remove-address')) {

            event.preventDefault();

            var item = event.target;
            showYesNoPopup(
                removeAddressData.message,
                removeAddressData.yes,
                removeAddressData.no,
                'removeAddress',
                item.getAttribute('data-address-index'),
                '',
                ''
            );

        }
    });
}
removeAddressFromAccount();

function removeAddress(id) {
    var options = {
        method: 'POST',
        credentials: 'include',
        body: prepareFormData(
            {
                id: id,
                csrf: removeAddressData.csrf
            }
        )
    };

    sendRequest('/' + getCurrentLocale() + '/customer/portal/removeaddress' , options).then(function (success) {
        location.reload();
    }).catch(function (errors) {
        location.reload();
    });
}

function removeNotification() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.notification__close-link')){
            event.preventDefault();
            var item = event.target;
            item.closest('.notification').classList.add('d-none');

            var options = {
                method: 'POST',
                credentials: 'include',
                body: prepareFormData(
                    {
                        id: item.getAttribute('data-order-id'),
                        index: item.getAttribute('data-index')
                    }
                )
            };
        
            sendRequest('/' + getCurrentLocale() + '/customer/portal/removeNotification' , options).then(function (success) {
                console.log(success);
            }).catch(function (errors) {
                console.log(errors);
            });
        }
    });
}
removeNotification();


function resendVerification() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.js_resend-verification')){
            event.preventDefault();
            var item = event.target;
            openModel('verify-account-success');
            sendRequest('/' + getCurrentLocale() + '/customer/portal/sendVerification' , options).then(function (success) {
                console.log(success);
            }).catch(function (errors) {
                console.log(errors);
            });
        }
    });
}
resendVerification();
if (document.getElementById("configurator-quote-slider")) {
  // load_numFalls_liftSpeed_phase_freq_volt
  var bodyCode = {
    '250_1_1_1_50_230': 280209,
    '250_1_1_1_50_400': 280209,
    '250_1_1_1_60_230': 280209,
    '250_1_1_1_60_400': 280209,
    '250_1_1_3_50_230': 280209,
    '250_1_1_3_50_400': 280209,

    '250_1_1_3_60_230': 280219,
    '250_1_1_3_60_400': 280219,

    '500_2_1_1_50_230': 280219,
    '500_2_1_1_50_400': 280219,
    '500_2_1_1_60_230': 280219,
    '500_2_1_1_60_400': 280219,
    '500_2_1_3_50_230': 280219,
    '500_2_1_3_50_400': 280219,

    '500_2_1_3_60_230': 280229,
    '500_2_1_3_60_400': 280229,

    '500_1_1_1_50_230': 280229,
    '500_1_1_1_50_400': 280229,
    '500_1_1_1_60_230': 280229,
    '500_1_1_1_60_400': 280229,
    '500_1_1_3_50_230': 280229,
    '500_1_1_3_50_400': 280229,

    '500_1_1_3_60_230': 280239,
    '500_1_1_3_60_400': 280239,

    '500_1_2_1_50_230': 280239,
    '500_1_2_1_50_400': 280239,
    '500_1_2_1_60_230': 280239,
    '500_1_2_1_60_400': 280239,
    '500_1_2_3_50_230': 280239,
    '500_1_2_3_50_400': 280239,

    '500_1_2_3_60_230': 280249,
    '500_1_2_3_60_400': 280249,

    '1000_2_1_1_50_230': 280249,
    '1000_2_1_1_50_400': 280249,
    '1000_2_1_1_60_230': 280249,
    '1000_2_1_1_60_400': 280249,
    '1000_2_1_3_50_230': 280249,
    '1000_2_1_3_50_400': 280249,

    '1000_2_1_3_60_230': 280259,
    '1000_2_1_3_60_400': 280259,

    '1000_2_2_1_50_230': 280259,
    '1000_2_2_1_50_400': 280259,
    '1000_2_2_1_60_230': 280259,
    '1000_2_2_1_60_400': 280259,
    '1000_2_2_3_50_230': 280259,
    '1000_2_2_3_50_400': 280259,

    '1000_2_2_3_60_230': 280269,
    '1000_2_2_3_60_400': 280269,

    '1000_1_1_1_50_230': 280269,
    '1000_1_1_1_50_400': 280269,
    '1000_1_1_1_60_230': 280269,
    '1000_1_1_1_60_400': 280269,
    '1000_1_1_3_50_230': 280269,
    '1000_1_1_3_50_400': 280269,

    '1000_1_1_3_60_230': 280279,
    '1000_1_1_3_60_400': 280279,

    '1000_1_2_1_50_230': 280279,
    '1000_1_2_1_50_400': 280279,
    '1000_1_2_1_60_230': 280279,
    '1000_1_2_1_60_400': 280279,
    '1000_1_2_3_50_230': 280279,
    '1000_1_2_3_50_400': 280279,

    '1000_1_2_3_60_230': 280289,
    '1000_1_2_3_60_400': 280289,

    '2000_2_1_1_50_230': 280289,
    '2000_2_1_1_50_400': 280289,
    '2000_2_1_1_60_230': 280289,
    '2000_2_1_1_60_400': 280289,
    '2000_2_1_3_50_230': 280289,
    '2000_2_1_3_50_400': 280289,

    '2000_2_1_3_60_230': 280299,
    '2000_2_1_3_60_400': 280299,

    '2000_2_2_1_50_230': 280299,
    '2000_2_2_1_50_400': 280299,
    '2000_2_2_1_60_230': 280299,
    '2000_2_2_1_60_400': 280299,
    '2000_2_2_3_50_230': 280299,
    '2000_2_2_3_50_400': 280299,
    '2000_2_2_3_60_230': 280299,
    '2000_2_2_3_60_400': 280299
  }

  var suspensionCodes = {};

  suspensionCodes[vtTans["Eye suspension"]] = {
      "250_1": 270987,
      "500_1": 274007,
      "500_2": 270997,
      "1000_1": 274027,
      "1000_2": 274017,
      "2000_2": 274037,
    };
  
  suspensionCodes[vtTans["Corso Electric trolley"]] = {
      '250_1_1_0': 276257,
      '250_1_1_1': 276317,
      '250_1_1_2': 276517,

      '500_1_1_0': 276277,
      '500_1_1_1': 276337,
      '500_1_1_2': 276537,

      '500_1_2_0': 276417,
      '500_1_2_1': 276477,
      '500_1_2_2': 276577,

      '500_2_1_0': 276267,
      '500_2_1_1': 276327,
      '500_2_1_2': 276527,

      '1000_1_1_0': 276297,
      '1000_1_1_1': 276457,
      '1000_1_1_2': 276557,

      '1000_1_2_0': 276437,
      '1000_1_2_1': 276497,
      '1000_1_2_2': 276597,

      '1000_2_1_0': 276287,
      '1000_2_1_1': 276347,
      '1000_2_1_2': 276547,

      '1000_2_2_0': 276427,
      '1000_2_2_1': 276487,
      '1000_2_2_2': 276587,

      '2000_2_1_0': 276307,
      '2000_2_1_1': 276467,
      '2000_2_1_2': 276567,

      '2000_2_2_0': 276447,
      '2000_2_2_1': 276507,
      '2000_2_2_2': 276607
    };

    suspensionCodes[vtTans["Corso Push Trolley"]] = {
      "250_1": 23309,
      "500_1": 23309,
      "500_2": 23309,
      "1000_1": 23319,
      "1000_2": 23319,
      "2000_2": 23379,
    };

    suspensionCodes[vtTans["Corso Beam Clamp"]] = {
      "250_1": 7009,
      "500_1": 7009,
      "500_2": 7009,
      "1000_1": 7009,
      "1000_2": 7009,
      "2000_2": 7029,
    };

    suspensionCodes[vtTans["Corso Push Trolley Direct Coupling"]] = {
      "250_1": 276357,
      "500_1": 276377,
      "500_2": 276367,
      "1000_1": 276397,
      "1000_2": 276387,
      "2000_2": 276407,
    };

    suspensionCodes[vtTans["Corso Push Trolley Extended Hanger bar"]] = {
      "250_1": 108879,
      "500_1": 108879,
      "500_2": 108879,
      "1000_1": 108889,
      "1000_2": 108889,
      "2000_2": 108909,
    };


  var chainLengthCodes = {
    '250_1': 270917,
    '500_1': 270937,
    '500_2': 270927,
    '1000_2': 270947,
    '1000_1': 270957,
    '2000_2': 270967
  };

  var controlCodes = {
    '250_1_1': 274047,
    '500_2_1': 274047,
    '500_1_1': 274057,
    '500_1_2': 276717,
    '1000_2_1': 274057,
    '1000_1_1': 274067,
    '1000_2_2': 276717,
    '1000_1_2': 276727,
    '2000_2_1': 274067,
    '2000_2_2': 276727
  }

  var controlTrolley1Codes = {
    '250_1_1': 274087,
    '500_2_1': 274087,
    '500_1_1': 274097,
    '500_1_2': 276737,
    '1000_2_1': 274097,
    '1000_2_2': 276737,
    '1000_1_1': 274107,
    '1000_1_2': 276747,
    '2000_2_1': 274107,
    '2000_2_2': 276747
  }

  var controlTrolley2Codes = {
    '250_1_1': 276757,
    '500_2_1': 276757,
    '500_1_1': 276767,
    '500_1_2': 276787,
    '1000_2_1': 276787,
    '1000_1_1': 276777,
    '1000_2_2': 276797,
    '1000_1_2': 276797,
    '2000_2_1': 276777,
    '2000_2_2': 276797
  }
}
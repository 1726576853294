/* 
    Path to Views is in app/Resources/views/Partials/Quote/BlueWater/guardrail-quote.html.twig
*/

var bluewaterGuardrailQuoteSlider = document.querySelector('#bluewater-guardrail-quote-slider');

if (bluewaterGuardrailQuoteSlider) {

    var bluewaterGuardrailQuoteSummery = localStorage.getItem('grquote');
    try {
        bluewaterGuardrailQuoteSummery = JSON.parse(bluewaterGuardrailQuoteSummery);
        if (bluewaterGuardrailQuoteSummery === null){
            bluewaterGuardrailQuoteSummery = {};
        }
    } catch (error) {
        bluewaterGuardrailQuoteSummery = {};
    }

    function buildGuardrailSummary() {

        bluewaterGuardrailQuoteSlider.querySelector("#grq_summary--container").innerHTML = '<table class="table-fixed"><tr class="d-none"><td></td></tr></table>';
        
        bluewaterGuardrailQuoteSlider.querySelectorAll(".add-guardrail-quote").forEach(function (button) {
            button.innerText = "Add to quote";
        });

        for (var fieldClass in bluewaterGuardrailQuoteSummery) {
            var title = bluewaterGuardrailQuoteSummery[fieldClass]['title'];
            addToGuardrailSummary(title, fieldClass);
            bluewaterGuardrailQuoteSlider.querySelector("." + fieldClass + " .add-guardrail-quote").innerText = "Update quote";

            var fields = bluewaterGuardrailQuoteSummery[fieldClass]['fields'];
            for (var field in fields) {
                var value = fields[field];
                var input = bluewaterGuardrailQuoteSlider.querySelector("input[name='" + field + "']");
                
                if (input.type == 'checkbox') {
                    var checkbox = bluewaterGuardrailQuoteSlider.querySelector("input[name='" + field + "'][value='" + value + "']");
                    checkbox.checked = true;
                    checkbox.setAttribute("checked",  "checked");
                }

                if (input.type == 'radio') {
                    var radio = bluewaterGuardrailQuoteSlider.querySelector("input[name='" + field + "'][value='" + value + "']");
                    radio.checked = true;
                    radio.setAttribute("checked",  "checked");
                }

                if (input.type == 'text') {
                    input.value = value;
                    input.setAttribute("value",  value);
                }
                
            }
        }
        
    }

    function addToGuardrailSummary(title, fieldClass) {
        var newRow = document.createElement("tr");
        newRow.setAttribute("data-class", fieldClass);
        newRow.setAttribute("data-title", title);
        newRow.innerHTML = '<td class="grq_summary--title">' + title + '</td><td class="grq_summary--edit"><a href="#" class="t_color--blue">(Edit)</a></td><td class="grq_summary--remove"><a href="#" class="t_color--blue">Remove</a></td>';
        var table = bluewaterGuardrailQuoteSlider.querySelector("#grq_summary--container table")
        table.insertBefore(newRow, table.querySelector("tr.d-none")[0]);

    }

    function guardrailContinueButtonTrigger(){
        bluewaterGuardrailQuoteSlider.querySelectorAll(".js_button-continue").forEach(function (button) {
            button.addEventListener('click', function (event) {
                event.preventDefault();
                bluewaterGuardrailQuoteSlider.classList.add("field-set-selected");
                bluewaterGuardrailQuoteSlider.querySelector("#bluewater-guardrail-header").innerText = button.getAttribute('data-fieldheader');
                bluewaterGuardrailQuoteSlider.querySelector(".js_go-back-to-add-quote").classList.remove("d-none");
            });
        });
    }

    guardrailContinueButtonTrigger();

    bluewaterGuardrailQuoteSlider.querySelector(".js_go-back-to-add-quote").addEventListener('click', function (event) {
        event.preventDefault();
        bluewaterGuardrailQuoteSlider.querySelector(".js_go-back-to-add-quote").classList.add("d-none");
        resetselects();
        hideallselects();
    });

    

    function showGuardrailSummary() {
        bluewaterGuardrailQuoteSlider.querySelector("#quotesum").classList.remove('d-none');
        bluewaterGuardrailQuoteSlider.querySelector(".main-cat-list").classList.add('d-none');
        bluewaterGuardrailQuoteSlider.querySelector("#bluewater-guardrail-header").innerText = "Added to quote";
        bluewaterGuardrailQuoteSlider.querySelector("#bluewater-guardrail-quote-slider .js_gaurdrail-summary-btns").classList.remove('d-none');
    }

    if(Object.keys(bluewaterGuardrailQuoteSummery).length > 0) {
        buildGuardrailSummary();
        showGuardrailSummary();
    }

    function hideGuardrailSummary() {
        bluewaterGuardrailQuoteSlider.querySelector("#quotesum").classList.add('d-none');
        bluewaterGuardrailQuoteSlider.querySelector(".main-cat-list").classList.remove('d-none');
        bluewaterGuardrailQuoteSlider.querySelector("#bluewater-guardrail-quote-slider .js_gaurdrail-summary-btns").classList.add('d-none');
    }

    function setGaurdrailFields(selector, value) {
        bluewaterGuardrailQuoteSlider.querySelectorAll(selector).forEach(function (box) {
            box.style.display = "block";
            box.querySelectorAll(".js_button-continue").forEach(function (button) {
                button.setAttribute('data-fieldheader', value);
            });
        });
    }

    //hide all selects
    function hideallselects() {
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat22").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat23").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat24").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat25").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat31").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat41").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat51").forEach(function (box) {
            box.style.display = "none"
        });

        bluewaterGuardrailQuoteSlider.querySelectorAll(".afields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".rfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".cfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".ffields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".gfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".jfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".osfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".vfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".aafields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".bbfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".ccfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".ddfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".eefields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".fffields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".ggfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".hhfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".iifields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".jjfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".kkfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".llfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".mmfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".nnfields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".oofields").forEach(function (box) {
            box.style.display = "none"
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll(".ppfields").forEach(function (box) {
            box.style.display = "none"
        });

    }

    hideallselects();

    //reset select lists
    function resetselects() {
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat1").forEach(function (box) {
            box.selectedIndex = 0
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
            box.selectedIndex = 0
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat22").forEach(function (box) {
            box.selectedIndex = 0
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat23").forEach(function (box) {
            box.selectedIndex = 0
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat24").forEach(function (box) {
            box.selectedIndex = 0
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat25").forEach(function (box) {
            box.selectedIndex = 0
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat31").forEach(function (box) {
            box.selectedIndex = 0
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat41").forEach(function (box) {
            box.selectedIndex = 0
        });
        bluewaterGuardrailQuoteSlider.querySelectorAll("#cat51").forEach(function (box) {
            box.selectedIndex = 0
        });

        bluewaterGuardrailQuoteSlider.classList.remove("field-set-selected");
        bluewaterGuardrailQuoteSlider.querySelector("#bluewater-guardrail-header").innerText = "Build Your Quote";

    }

    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat1").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            if (this.value == "Roof Guardrail Systems") {
                bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
                    box.style.display = "block"
                });
            }
            if (this.value == "Self-Closing Safety Gates") {
                bluewaterGuardrailQuoteSlider.querySelectorAll("#cat31").forEach(function (box) {
                    box.style.display = "block"
                });
            }
            if (this.value == "Portable/Construction Guardrail") {
                bluewaterGuardrailQuoteSlider.querySelectorAll("#cat41").forEach(function (box) {
                    box.style.display = "block"
                });
            }
            if (this.value == "Equipment Guardrail") {
                bluewaterGuardrailQuoteSlider.querySelectorAll("#cat51").forEach(function (box) {
                    box.style.display = "block"
                });
            }
            bluewaterGuardrailQuoteSlider.querySelectorAll(".submit-quote").forEach(function (box) {
                box.style.display = "none"
            });
        })
    });

    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
                box.style.display = "block"
            });
            if (this.value == "SafetyRail 2000 Guardrail") {
                setGaurdrailFields(".afields", this.value);
            }
            if (this.value == "SafetyRail Specialized Models") {
                bluewaterGuardrailQuoteSlider.querySelectorAll("#cat22").forEach(function (box) {
                    box.style.display = "block"
                });
            }
            if (this.value == "Guardrail Components") {
                bluewaterGuardrailQuoteSlider.querySelectorAll("#cat23").forEach(function (box) {
                    box.style.display = "block"
                });
            }
            if (this.value == "Roof Opening Protection") {
                bluewaterGuardrailQuoteSlider.querySelectorAll("#cat24").forEach(function (box) {
                    box.style.display = "block"
                });
            }
            if (this.value == "Compatible Safety Gates") {
                bluewaterGuardrailQuoteSlider.querySelectorAll("#cat25").forEach(function (box) {
                    box.style.display = "block"
                });
            }
            if (this.value == "SafetyRail Accessories & Parts") {
                setGaurdrailFields(".rfields", this.value);
            }
        })
    });
    
    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat22").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
                box.style.display = "block"
            });
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat22").forEach(function (box) {
                box.style.display = "block"
            });
            if (this.value == "Architectural Series") {
                setGaurdrailFields(".bbfields", this.value);
            }
            if (this.value == "Stealth Rail") {
                setGaurdrailFields(".cfields", this.value);
            }
            if (this.value == "Fiberglass Guardrail") {
                setGaurdrailFields(".ccfields", this.value);
            }
            if (this.value == "Standing Seam Metal Roofs") {
                setGaurdrailFields(".ddfields", this.value);
            }
        })
    });

    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat23").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
                box.style.display = "block"
            });
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat23").forEach(function (box) {
                box.style.display = "block"
            });
            if (this.value == "Perma-line") {
                setGaurdrailFields(".ffields", this.value);
            }
            if (this.value == "ParaRail") {
                setGaurdrailFields(".gfields", this.value);
            }
            if (this.value == "Raised Mid-Rail") {
                setGaurdrailFields(".eefields", this.value);
            }
            if (this.value == "StepRail") {
                setGaurdrailFields(".fffields", this.value);

            }
            if (this.value == "Finishing Rail") {
                setGaurdrailFields(".jfields", this.value);
            }
            if (this.value == "LP Outrigger") {
                setGaurdrailFields(".ggfields", this.value);
            }
        })
    });

    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat24").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
                box.style.display = "block"
            });
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat24").forEach(function (box) {
                box.style.display = "block"
            });
            if (this.value == "Skylight Defender") {
                setGaurdrailFields(".hhfields", this.value);
            }
            if (this.value == "Hatch Defender") {
                setGaurdrailFields(".iifields", this.value);
            }
            if (this.value == "Ladder Defender") {
                setGaurdrailFields(".jjfields", this.value);
            }
        })
    });

    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat25").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat21").forEach(function (box) {
                box.style.display = "block"
            });
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat25").forEach(function (box) {
                box.style.display = "block"
            });
            if (this.value == "GuardDog Self-Closing gates") {
                setGaurdrailFields(".osfields", this.value);
            }
            if (this.value == "Swing Gates") {
                setGaurdrailFields(".kkfields", this.value);
            }
            if (this.value == "Sliding Gates") {
                setGaurdrailFields(".llfields", this.value);

            }
        })
    });

    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat31").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat31").forEach(function (box) {
                box.style.display = "block"
            });
            if (this.value == "GuardDog Self-Closing Gate") {
                setGaurdrailFields(".osfields", this.value);
            }
            if (this.value == "Hatch Defender") {
                setGaurdrailFields(".mmfields", this.value);
            }
            if (this.value == "Ladder Defender") {
                setGaurdrailFields(".nnfields", this.value);
            }
        })
    });

    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat41").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat41").forEach(function (box) {
                box.style.display = "block"
            });
            if (this.value == "Versa-Clamp") {
                setGaurdrailFields(".vfields", this.value);
            }
            if (this.value == "Para-Clamp") {
                setGaurdrailFields(".oofields", this.value);
            }
            if (this.value == "Median-Clamp") {
                setGaurdrailFields(".ppfields", this.value);
            }
        })
    });

    bluewaterGuardrailQuoteSlider.querySelectorAll("#cat51").forEach(function (box) {
        box.addEventListener('change', function (event) {
            hideallselects();
            bluewaterGuardrailQuoteSlider.querySelectorAll("#cat51").forEach(function (box) {
                box.style.display = "block"
            });
            if (this.value == "Kwik-Guard") { }
            if (this.value == "Kwik-Rail") {
                setGaurdrailFields(".aafields", this.value);
            }
            if (this.value == "Kwik-Fit") { }
        })
    });

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.grq_summary--edit a')) {
            event.preventDefault();
            var row = event.target.parentElement.parentElement;
            var fieldClass = row.getAttribute("data-class");
            bluewaterGuardrailQuoteSlider.querySelector("." + fieldClass).style.display = "block";
            bluewaterGuardrailQuoteSlider.classList.add("field-set-selected");
            bluewaterGuardrailQuoteSlider.querySelector("#bluewater-guardrail-header").innerText = row.getAttribute("data-title");
            hideGuardrailSummary();
        }
    });

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.grq_summary--remove a')) {
            event.preventDefault();
            var row = event.target.parentElement.parentElement;
            var fieldClass = row.getAttribute("data-class");
            var fieldBlock = bluewaterGuardrailQuoteSlider.querySelector("." + fieldClass);

            fieldBlock.querySelectorAll('input[type=text]').forEach(function (box) {
                box.setAttribute("value", "");
                box.value = "";
            });
            fieldBlock.querySelectorAll('input[type=checkbox]').forEach(function (box) {
                box.removeAttribute("checked");
                box.checked = false;
            });
            fieldBlock.querySelectorAll('input[type=radio]').forEach(function (box) {
                box.removeAttribute("checked");
                box.checked = false;
            });

            delete bluewaterGuardrailQuoteSummery[fieldClass];
            localStorage.setItem('grquote', JSON.stringify(bluewaterGuardrailQuoteSummery));

            buildGuardrailSummary();

            if(Object.keys(bluewaterGuardrailQuoteSummery).length === 0) {
                resetselects();
                hideGuardrailSummary();
            }
        }
    });

    bluewaterGuardrailQuoteSlider.querySelector(".js_add_another_product").addEventListener('click', function (event) {
        event.preventDefault();
        resetselects();
        hideGuardrailSummary();
    });

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.add-guardrail-quote')) {

            event.preventDefault();
            bluewaterGuardrailQuoteSlider.querySelectorAll(".submit-quote").forEach(function (box) {
                box.style.display = "block"
            });

            var fieldBlock = event.target.parentElement.parentElement;
            var fieldClass = fieldBlock.getAttribute("class").split(" ")[0];
            var sectionTitle = bluewaterGuardrailQuoteSlider.querySelector('#bluewater-guardrail-header').textContent;

            bluewaterGuardrailQuoteSummery[fieldClass] = {
                'fields': {},
                'title' : sectionTitle
            }
            
            fieldBlock.querySelectorAll('input').forEach(function (item) {

                if (item.type == 'checkbox') {
                    if (item.checked == true) {
                        bluewaterGuardrailQuoteSummery[fieldClass]['fields'][item.getAttribute('name')] = item.value;
                    }
                }

                if (item.type == 'radio') {
                    if (item.checked == true) {
                        bluewaterGuardrailQuoteSummery[fieldClass]['fields'][item.getAttribute('name')] = item.value;
                    }
                }

                if (item.type == 'text') {
                    if (item.value != '') {
                        bluewaterGuardrailQuoteSummery[fieldClass]['fields'][item.getAttribute('name')] = item.value;
                    }
                }

            });

            localStorage.setItem('grquote', JSON.stringify(bluewaterGuardrailQuoteSummery));

            fieldBlock.style.display = "none";
            bluewaterGuardrailQuoteSlider.querySelector(".js_go-back-to-add-quote").classList.add("d-none");
            resetselects();
            hideallselects();
            buildGuardrailSummary();
            showGuardrailSummary();

        }
    });

}


function confinedAccessRangeSetup() {

    var selectedConfinedAccessProductId = -1;
    var selectedConfinedAccessSelectedForm = null;
    var selectedConfinedAccessBundleName = '';

    document.addEventListener('click', function(event) {
        if (event.target && event.target.matches('.js_show-confined-access-range-model')) {
            event.preventDefault();
            var button = event.target;

            // get the product id from the button
            var productId = button.getAttribute('data-product-id');
            selectedConfinedAccessProductId = productId;

            // hide all sections
            var showHideSections = document.querySelectorAll('.js_step1-product-drill-down');
            if (showHideSections) {
                Array.from(showHideSections).forEach(function (section) {
                    section.classList.add('d-none');
                });
            }

            // now only show the one we want to see based on 'data-product-id'
            selectedConfinedAccessSelectedForm = document.querySelector('.js_step1-product-drill-down[data-product-id="' + productId + '"]');
            selectedConfinedAccessSelectedForm.classList.remove('d-none');

            resetModelSteps('confined-access-configurator-wizard');

            // ready to show model
            openModel('confined-access-configurator-wizard');

        }
    });

    function confinedAccessResetFormFields(section) {
        var thumbnails = section.querySelectorAll('.product-thumbnail-selection .img-thumbnail');
        Array.from(thumbnails).forEach(function (thumbnail) {
            thumbnail.classList.remove('img-thumbnail__active');
        });
        var select = section.querySelector('.selectTypeOfAdapter select');
        if (select) {
            select.value = '';
        }
        var applicationSelected = section.querySelector('.application-selected');
        applicationSelected.innerHTML = '';
        applicationSelected.setAttribute('data-type', '');
        applicationSelected.classList.add('d-none');
    }

    function confinedAccessNestSelectionBuilder() {
        var showHideSections = document.querySelectorAll('.js_step1-product-drill-down');
        if (showHideSections) {
            Array.from(showHideSections).forEach(function (section) {

                var product_id = section.getAttribute('data-product-id').toString();

                if (['149236'].indexOf(product_id) != -1) { 
                    var fieldData = DavitracPlusUserJourney;
                    var fieldTrans = DavitracPlusUserJourneyTranslations;
                }

                if (['43199', '145376'].indexOf(product_id) != -1) { 
                    var fieldData = DavitracUserJourney;
                    var fieldTrans = DavitracUserJourneyTranslations;
                }

                if (['6468'].indexOf(product_id) != -1) {
                    var fieldData = TracpodUserJourney;
                    var fieldTrans = TracpodUserJourneyTranslations;
                }

                if (['150707'].indexOf(product_id) != -1) {
                    var fieldData = TripodUserJourney;
                    var fieldTrans = TripodUserJourneyTranslations;
                }

                var applicationType = section.querySelector('.application-type select');
                var applicationTransportation = section.querySelector('.application-transportation select');
                var applicationWeight = section.querySelector('.application-weight select');
                var applicationSuspended = section.querySelector('.application-suspended select');
                var applicationHeight = section.querySelector('.selectHeights select');

                var options = applicationType.querySelector('option').outerHTML;
                for (var key in fieldData) {
                    options = options + '<option value="' + key + '">' + fieldTrans[key] + '</option>';
                }
                applicationType.innerHTML = options;

                applicationType.addEventListener('change', function(event) {
                    applicationTransportation.closest('.field').classList.add('d-none');
                    applicationWeight.closest('.field').classList.add('d-none');
                    applicationSuspended.closest('.field').classList.add('d-none');
                    confinedAccessGotoSelectHeights(section, false, {}, '');
                    confinedAccessResetFormFields(section);

                    var item = event.target.value;
                    var data = fieldData[item];
                    if( (typeof data === 'string') || (typeof data['text'] === 'string') ) {
                        confinedAccessGotoSelectHeights(section, true, fieldTrans, data);
                    } else {
                        var options = applicationTransportation.querySelector('option').outerHTML;
                        for (var key in data) {
                            options = options + '<option value="' + key + '">' + fieldTrans[key] + '</option>';
                        }
                        applicationTransportation.innerHTML = options;
                        applicationTransportation.closest('.field').classList.remove('d-none');
                    }
                });

                applicationTransportation.addEventListener('change', function(event) {
                    applicationWeight.closest('.field').classList.add('d-none');
                    applicationSuspended.closest('.field').classList.add('d-none');
                    confinedAccessGotoSelectHeights(section, false, {}, '');
                    confinedAccessResetFormFields(section);

                    var item = event.target.value;
                    var data = fieldData[applicationType.value][item];

                    if( (typeof data === 'string') || (typeof data['text'] === 'string') ) {
                        confinedAccessGotoSelectHeights(section, true, fieldTrans, data);
                    } else {

                        var selectedOption = applicationWeight;
                        for (var key in data) {
                            if (key.toLocaleLowerCase().indexOf('suspended') !== -1) {
                                var selectedOption = applicationSuspended;
                            }
                            break
                        }

                        var options = selectedOption.querySelector('option').outerHTML;

                        for (var key in data) {
                            options = options + '<option value="' + key + '">' + fieldTrans[key] + '</option>';
                        }

                        selectedOption.innerHTML = options;
                        selectedOption.closest('.field').classList.remove('d-none');
                    }
                });

                applicationWeight.addEventListener('change', function(event) {
                    applicationSuspended.closest('.field').classList.add('d-none');
                    confinedAccessGotoSelectHeights(section, false, {}, '');
                    confinedAccessResetFormFields(section);

                    var item = event.target.value;
                    var data = fieldData[applicationType.value][applicationTransportation.value][item];
                    if( (typeof data === 'string') || (typeof data['text'] === 'string') ) {
                        confinedAccessGotoSelectHeights(section, true, fieldTrans, data);
                    } else {
                        var options = applicationSuspended.querySelector('option').outerHTML;
                        for (var key in data) {
                            options = options + '<option value="' + key + '">' + fieldTrans[key] + '</option>';
                        }
                        applicationSuspended.innerHTML = options;
                        applicationSuspended.closest('.field').classList.remove('d-none');
                    }
                });
                
                applicationSuspended.addEventListener('change', function(event) {
                    confinedAccessGotoSelectHeights(section, false, {}, '');
                    confinedAccessResetFormFields(section);

                    var item = event.target.value;
                    var data = fieldData[applicationType.value][applicationTransportation.value];
                    var value = '';
                    if (typeof(data[applicationWeight.value]) !== 'undefined') {
                        value = data[applicationWeight.value][item];
                    } else {
                        value = data[item];
                    }
                    confinedAccessGotoSelectHeights(section, true, fieldTrans, value);
                    
                });


                applicationHeight.addEventListener('change', function(event) {
                    var productThumbnail = section.querySelector('.product-thumbnail-selection');
                    productThumbnail.classList.remove('disabled-item');
                });

                var applicationAdapterSelect = section.querySelector('.selectTypeOfAdapter select');
                var applicationAdapterThumbnails = section.querySelectorAll('.product-thumbnail-selection .img-thumbnail');

                if (applicationAdapterThumbnails) {
                    Array.from(applicationAdapterThumbnails).forEach(function (thumbnail) {
                        thumbnail.addEventListener('click', function(event) {

                            var thumbs = applicationAdapterThumbnails;
                            Array.from(thumbs).forEach(function (thumb) {
                                thumb.classList.remove('img-thumbnail__active');
                            });
                            var item = event.target;
                            if (hasClass(item, 'img-thumbnail') == false) {
                                item = item.closest('.img-thumbnail');
                            }
                            item.classList.add('img-thumbnail__active');
                            applicationAdapterSelect.value = item.getAttribute('data-name');
                        });
                    });
                }
            });
        }
    }
    confinedAccessNestSelectionBuilder();

    function confinedAccessGotoSelectHeights(section, enableHeight, fieldTrans, applicationType) {

        var applicationSelected = section.querySelector('.application-selected');

        var text = applicationType['text'];
        applicationSelected.innerHTML = text == '' ? '' : fieldTrans[text];
        applicationSelected.setAttribute('data-type', text);
        applicationSelected.classList.remove('d-none');

        var productThumbnail = section.querySelector('.product-thumbnail-selection');
        productThumbnail.classList.add('disabled-item');

        var selectHeights = section.querySelectorAll('.selectHeights');
        Array.from(selectHeights).forEach(function (heights) {
            if (enableHeight) {
                heights.classList.remove('disabled-item');
            } else {
                heights.classList.add('disabled-item');
            }
        });

        if (enableHeight == false) {
            section.querySelector('.selectHeights select').value = '';
        } else {
            var usedHeights = applicationType['height'];
            var options = section.querySelectorAll('.selectHeights select option');
            var index = 0;

            Array.from(options).forEach(function (option) {
                if (index > 0) {
                    if (usedHeights[index-1] == 1) {
                        option.classList.remove('d-none');
                    } else {
                        option.classList.add('d-none');
                    }
                }
                index++;
            });
        }
    }

    document.addEventListener('click', function(event) {
        if (event.target && event.target.matches('#car-step1-btn')) {
            event.preventDefault();
            var button = event.target;

            var selects = selectedConfinedAccessSelectedForm.querySelectorAll('select');

            $params = {};
            for (var i = 0; i < selects.length; i++) {
                var select = selects[i];
                if (select.value == '') {
                    if (hasClass(select.closest('.field'), 'd-none') == false) {
                        select.reportValidity();
                        return;
                    }
                }
                if (select.name.indexOf('application-') === -1) {
                    $params[select.name] = select.value;
                }
            }

            $params['selectApplications'] = selectedConfinedAccessSelectedForm.querySelector('.application-selected').getAttribute('data-type');

            var fields = {
                'productId': selectedConfinedAccessProductId,
                'params': JSON.stringify($params)
            };

            var options = {
                method: 'POST',
                body: prepareFormData(fields)
            };

            button.disabled = true;

            sendRequest(window.location.href.split('?')[0]+ '?get-bundle', options).then(function (success) {

                if (success.data.length == 0) {
                    openModel('confined-access-configurator-no-bundles');
                } else {
                    var html = [];
                    var downloads = false;
                    Array.from(success.data.products).forEach(function (product) {

                        html = html.concat([
                            '<tr>',
                                '<td class="product-thumbnail">',
                                    '<picture>',
                                        '<img alt="" src="'+product.image+'">',
                                    '</picture>',
                                '</td>',
                                '<td>'+product.name+'</td>',
                                '<td class="text-right">'+product.genCode+'</td>',
                                '<td>'+product.description+'</td>',
                                '<td class="downloadColumn">'
                            ]);

                            if (product.link != '') {
                                html = html.concat([
                                        '<div class="u_flex">',
                                            '<a href="'+product.link+'" target="_blank" rel="noopener noreferrer" class="is-a-link">Download</a>',
                                            '<img src="/static/bundles/img/svg/download-file.svg" class="c_card__link-icon" alt="">&nbsp;',
                                        '</div>'
                                ]);
                                downloads = true;
                            }

                            html = html.concat([
                                '</td>',
                            '</tr>'
                        ]);
                    });

                    var rows = document.getElementById('bundle-product-rows');
                    var table = rows.closest('table');

                    var downloadColumns = table.querySelectorAll('.downloadColumn');

                    for (var i = 0; i < downloadColumns.length; i++) {
                        if (downloads) {
                            downloadColumns[i].classList.remove('d-none');
                        } else {
                            downloadColumns[i].classList.add('d-none');
                        }
                    }

                    selectedConfinedAccessBundleName = success.data.bundle;

                    rows.innerHTML = html.join('\n');
                    document.getElementById('car-modal-step-2-title').innerHTML = '<b>' + selectedConfinedAccessSelectedForm.getAttribute('data-product-name') + '</b>';

                    var bundleInfo = document.getElementById('bundle-info');
                    if (success.data.info != '') {
                        bundleInfo.innerHTML = '<b>' + success.data.info + '</b>';
                        bundleInfo.classList.remove('d-none');
                    } else {
                        bundleInfo.innerText = '';
                        bundleInfo.classList.add('d-none');
                    }

                    popupToggleNextStep(button);
                }

                button.disabled = false;

            }).catch(function (error) {
                console.log(error);
                button.disabled = false;
            });

        }
    });

    document.addEventListener('click', function(event) {
        if (event.target && event.target.matches('#car-step2-btn')) {
            event.preventDefault();
            var button = event.target;
            popupToggleNextStep(button);
        }
    });

    document.addEventListener('click', function(event) {
        if (event.target && event.target.matches('#car-submit-btn')) {
            event.preventDefault();
            var button = event.target;

            removeElementsByClass('error-speech-bubble');

            var form = document.getElementById('confined_access_range_form');
            
            var isValid = form.checkValidity();
            if (isValid == false) {
                form.reportValidity();
                return;
            } 

            var selected_bundle = form.querySelector('.selected_bundle');
            selected_bundle.value = selectedConfinedAccessBundleName;
            selected_bundle.setAttribute('value', selectedConfinedAccessBundleName);

            var selected_product_id = form.querySelector('.selected_product_id');
            selected_product_id.value = selectedConfinedAccessProductId;
            selected_product_id.setAttribute('value', selectedConfinedAccessProductId);

            var inputs = form.querySelectorAll('input');

            $params = {};
            for (var i = 0; i < inputs.length; i++) {
                var input = inputs[i];
                if (input.type == 'checkbox') {
                    $params[input.name] = input.checked ? 'yes' :  'no';
                } else {
                    $params[input.name] = input.value;
                }
            }

            var textareas = form.querySelectorAll('textarea');

            for (var i = 0; i < textareas.length; i++) {
                var textarea = textareas[i];
                $params[textarea.name] = textarea.value;
            }

            var selects = form.querySelectorAll('select');

            for (var i = 0; i < selects.length; i++) {
                var select = selects[i];
                $params[select.name] = select.options[select.selectedIndex].text;
            }

            var fields = {
                'confined_access_range_form': JSON.stringify($params),
            };

            var options = {
                method: 'POST',
                body: prepareFormData(fields)
            };

            button.disabled = true;

            sendRequest(window.location.href.split('?')[0], options).then(function (success) {
                
                if (typeof (website_confined_access_success_page) !== 'undefined') {
                    document.cookie = "thank_you_page=cac; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
                    setTimeout(function() {
                        window.location.href = website_confined_access_success_page;
                    }, 250);
                } else {
                    button.disabled = false;
                    closeModel('confined-access-configurator-wizard');
    
                    document.getElementById('car-success-product').innerHTML = selectedConfinedAccessSelectedForm.getAttribute('data-product-name');
                    openModel('confined-access-configurator-success');
                }

            }).catch(function (errors) {
                handleFormErrors(errors.data, form, '.confined_access_range_form-');
                console.log(errors);
                button.disabled = false;
            });
        }
    });

}
confinedAccessRangeSetup();
function inputValueChange() {

  document.addEventListener('input', function (event) {
    if (event.target && event.target.matches('.js_form-input')) {
      event.preventDefault();
      var item = event.target;
      if (item.value == '') {
        item.classList.remove("full");
      } else {
        item.classList.add("full");
      }
    }
  });

}
inputValueChange();
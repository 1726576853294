var changePasswordForm = document.querySelector('.change-password-page');

if(changePasswordForm) {
    var changePasswordBtn = changePasswordForm.querySelector('.js_form-submit');

    changePasswordBtn.addEventListener('click', function(event) {
       event.preventDefault();
       removeElementsByClass('error-speech-bubble');

       var password = changePasswordForm.querySelector('#reset-password').value;
       var confirmPassword = changePasswordForm.querySelector('#reset-confirm').value;

       if(password !== confirmPassword) {
           var error = {form: jsTranslations.passwordMismatch};
           handleFormErrors(error, changePasswordForm, '#reset-');
       } else {
           changePasswordForm.submit();
       }
    });
}
function hideDropDowns(dropdowns) {
    var i;
    for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('d-block')) {
            openDropdown.classList.remove('d-block');
        }
    }
}
function toggleJsSelect() {
    var dropdowns = document.getElementsByClassName("dropdown-content");

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.dropbtn, .dropbtn *')) {
            event.preventDefault();
            hideDropDowns(dropdowns);

            var parentElm = event.target;
            while (parentElm.querySelector('.dropdown') === null) {
                parentElm = parentElm.parentElement;
            }

            if (parentElm.querySelector('.disabled') === null) {
                parentElm.querySelector('.dropdown-content').classList.toggle("d-block");
            }
        }
    });

    if (dropdowns.length > 0) {
        window.addEventListener('click', function (event) {
            if (!event.target.matches('.dropbtn, .dropbtn *')) {
                hideDropDowns(dropdowns);
            }
        });
    }

}
toggleJsSelect();
var dataEquipmentList = document.getElementById('markerData');
if(dataEquipmentList) {
    dataEquipmentList = JSON.parse(dataEquipmentList.innerHTML);

    function addMarkers(equipmentbox, markers) {
        var html = [];
        markers.forEach(function(marker) {
            var top = 'calc(' + marker.top + '% - 16px)';
            var left = 'calc(' + marker.left + '% - 16px)';

            html = html.concat([
                '<div class="md-mindmap__item" style="top: ' + top + '; left: ' + left + '">',
                '<button class="md-circle md-circle--mid">',
                '<span class="md-bubble__bg"></span>',
                '<span class="md-bubble__number bold">' + marker.id + '</span>',
                '</button>',
                '</div>'
            ]);
        });

        equipmentbox.innerHTML += html.join('\n');
    }

    var equipmentRemove = false,
        backButton = document.querySelector(".md-mindmap__back-button"),
        homeButton = document.querySelector(".md-mindmap__home-button"),
        equipmentList = document.querySelectorAll(".md-bubble");

    if(homeButton) {
        homeButton.addEventListener('click', function(event) {
            window.location.href = '/' + getCurrentLocale();
        });
    }

    Array.from(equipmentList).forEach(function(item) {
        var equipmentId = parseInt(item.getAttribute("data-equipment-id"), 10);
        var mindMap = document.querySelector(".md-mindmap");

        var obj = dataEquipmentList.find(function(data) {
            return data.equipmentId == equipmentId;
        });

        if (typeof(obj.productList) === 'undefined') {
            console.log(obj.equipmentId);
        }

        item.addEventListener("click", function() {
            var mainImg = document.querySelector(".md-mindmap__main-img");
            var animateSpeed = 1;
            var imageScale = 20;

            mainImg.style.transition = animateSpeed + "s";
            mainImg.style.transform = "scale(" + imageScale + ")";

            var obj = dataEquipmentList.find(function(data) {
                return data.equipmentId == equipmentId;
            });

            if (obj) {
                var equipmentBox = [
                    '<div class="md-equipment-box__outer">',
                    '<div class="md-equipment-box__inner">',

                    '<article class="md-product-box">',

                    '<header class="md-product-box__header md-product-box__border-bottom">',
                        '<div class="nav__button md-close u_flex u_flex--h-centre u_flex--v-centre">',
                            '<div style="height: 10px;" class="icon i_close"></div>',
                        '</div>',
                        '<div class="text-center block-link">',
                        '<span class="block u_mb--2">RECOMMENDED PRODUCTS</span>',
                        '<h3 class="fs_oak t_color--highlight u_mb--0 u_mt--0">',
                        obj.equipmentId,
                        ". ",
                        obj.equipmentTitle,
                        "</h3>",
                        "</div>",
                    "</header>",

                    '<div class="md-product-box__list">',

                    Array.from(obj.productList)
                        .map(function(list, index) {
                            return [
                                '<div class="md-product-box__row">',
                                '<div class="md-product-box__row-outer">',
                                '<div class="md-product-box__row-inner">',

                                '<div class="slider">',

                                '<div class="md-product-box__item-number-wrap">',
                                '<span class="md-circle md-circle--mid md-circle--green md-circle--inverse">',
                                list.rowId,
                                "</span>",
                                "</div>",

                                '<div class="md-slide-product__item-list slider__wrapper">',
                                list.toolList
                                    .map(function(tool, index) {
                                        return [
                                            '<div class="md-slide-product__item slider__item">',
                                            '<div class="md-slide-product__main-sidebar">',
                                            '<div class="md-slide-product__card">',
                                            '<img class="position-center" src="',
                                            tool.toolPicture,
                                            '" alt="',
                                            tool.toolPictureAlt,
                                            '">',
                                            "</div>",
                                            "</div>",
                                            '<div class="md-slide-product__main-content">',
                                            '<div class="md-slide-product__header">',
                                            '<h3 class="md-slide-product__title"><b>',
                                            tool.toolName,
                                            "</b> ",
                                            "</h3>",
                                            '<span class="md-slide-product__sub-title">',
                                            tool.toolDescription,
                                            "</span>",
                                            "</div>",
                                            '<a href="',
                                            tool.toolUrl,
                                            '" class="md-slide-product__link"> View Product</a>',
                                            "</div>",
                                            "</div>"
                                        ].join("");
                                    })
                                    .join(""),
                                "</div>",

                                "</div>",

                                "</div>",
                                "</div>",
                                "</div>"
                            ].join("");
                        })
                        .join(""),

                    "</div>",

                    "</article>",

                    "</div>",
                    "</div>"
                ].join("");

                var equipmentBoxNode = document.createElement("div");
                equipmentBoxNode.className = "md-equipment-box";
                equipmentBoxNode.style.backgroundImage = "url(" + obj.equipmentMainBgImg + ")";
                equipmentBoxNode.innerHTML = equipmentBox;

                mindMap.appendChild(equipmentBoxNode);

                equipmentBoxNode.style.opacity = "0";
                equipmentBoxNode.style.transform = "scale(0.7)";
                equipmentBoxNode.style.transition = ".5s linear";

                setTimeout(function() {
                    equipmentBoxNode.style.opacity = "1";
                    equipmentBoxNode.style.transform = "scale(1)";
                }, 270);

                var productBox = document.querySelector(".md-product-box");
                productBox.style.opacity = "0";
                productBox.style.transition = ".5s linear";

                setTimeout(function() {
                    productBox.style.opacity = "1";
                    mainImg.style.transform = "scale(1)";
                    addMarkers(equipmentBoxNode, obj.markers);
                    carouselCode();

                    document.querySelector(".md-close").addEventListener("click", function() {
                        console.log('click');
                        productBox.style.transition = ".27s linear";
                        productBox.style.opacity = "0";
                        setTimeout(function() {
                            equipmentBoxNode.style.opacity = "0";
                            equipmentBoxNode.style.transform = "scale(0.5)";
                        }, 200);

                        setTimeout(function() {
                            equipmentBoxNode.remove();
                        }, 700);

                        setTimeout(function() {
                            mainImg.style.transform = "scale(1)";
                        }, 270);
                    });

                }, 620);

            } else {
                console.error("Item not found");
            }

        });
    });


    function carouselCode() {
        function generateList(items) {
            var ul = document.createElement("ul");
            ul.className = "slider-toggles";

            for (var i = 1; i <= items.length; i++) {
                var li = document.createElement("li");
                if (i === 1) {
                    li.className = "slider-toggles__item is-active";
                } else {
                    li.className = "slider-toggles__item";
                }
                li.setAttribute("data-slide", i);
                ul.appendChild(li);
            }
            return ul;
        }

        Array.from(document.querySelectorAll(".slider"), function(slider) {
            var items = slider.querySelectorAll(".slider__item");
            if (items.length > 1) {
                slider.appendChild(generateList(items));
            }
        });

        var step = 440;
        var slidesCount = document.querySelectorAll(".slider__item").length;
        var toggles = document.querySelectorAll(".slider-toggles__item");

        for (var i = 0; i < toggles.length; i++) {
            toggles[i].addEventListener("click", changeSlide);
        }

        function changeSlide(e) {
            var slider = e.target.closest(".slider");
            var blockToMove = slider.querySelector(".slider__wrapper");
            function makeCurrentToogleActive(elem) {
                toggles = slider.querySelectorAll(".slider-toggles__item");

                for (var i = 0; i < toggles.length; i++) {
                    toggles[i].classList.remove("is-active");
                }
                elem.classList.add("is-active");
            }
            makeCurrentToogleActive(e.target);

            var num = e.target.dataset.slide;
            var moveValue = (num - 1) * step;
            if (moveValue == 0) {
                blockToMove.style.marginLeft = "0px";
                return;
            }
            blockToMove.style.marginLeft = -moveValue + "px";
        }
    }

}

/* 
    Path to Views is in app/Resources/views/Partials/Quote/BlueWater/skylight-defender-quote.html.twig
*/

var bluewaterSkylightDefenderQuoteSlider = document.querySelector('#bluewater-skylight-defender-quote-slider');

if (bluewaterSkylightDefenderQuoteSlider) {

    bluewaterSkylightDefenderQuoteSlider.querySelector("#js_skyline-defender-frame-selector").addEventListener('change', function (event) {
        event.preventDefault();
        if (this.value == "") {
            bluewaterSkylightDefenderQuoteSlider.querySelector(".js_button-continue-step2").classList.add("d-none");
        } else {
            bluewaterSkylightDefenderQuoteSlider.querySelector(".js_button-continue-step2").classList.remove("d-none");
        }
    });

    bluewaterSkylightDefenderQuoteSlider.querySelectorAll(".js_button-continue-step2").forEach(function (button) {
        button.addEventListener('click', function (event) {
            event.preventDefault();
            bluewaterSkylightDefenderQuoteSlider.querySelector(".step1").classList.add("d-none");
            bluewaterSkylightDefenderQuoteSlider.querySelector(".frame1").classList.add("d-none");
            bluewaterSkylightDefenderQuoteSlider.querySelector(".frame2").classList.add("d-none");
            bluewaterSkylightDefenderQuoteSlider.querySelector(".frame3").classList.add("d-none");
            bluewaterSkylightDefenderQuoteSlider.querySelector(".frame4").classList.add("d-none");
            var selectedFrame = bluewaterSkylightDefenderQuoteSlider.querySelector("#js_skyline-defender-frame-selector").value;
            bluewaterSkylightDefenderQuoteSlider.querySelector(".frame" + selectedFrame).classList.remove("d-none");
            
            bluewaterSkylightDefenderQuoteSlider.querySelector(".step2").classList.remove("d-none");
            document.querySelectorAll('.login-slider__slide').forEach(function (slider) {
                slider.scrollTop = 0;
            });
        });
    });
    
}
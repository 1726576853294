"use strict";
var mapPage = document.getElementById('map');

if (mapPage) {
    google.maps.event.addDomListener(window, "load", function () {

        var props = {
            mapConfig: {
                zoom: 2,
                center: new google.maps.LatLng(0, 0),
                disableDefaultUI: true,
                styles: [
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e9e9e9"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 17
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 29
                            },
                            {
                                "weight": 0.2
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 18
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dedede"
                            },
                            {
                                "lightness": 21
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": 36
                            },
                            {
                                "color": "#333333"
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            },
                            {
                                "lightness": 19
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 17
                            },
                            {
                                "weight": 1.2
                            }
                        ]
                    }
                ],
            },
            markerOffice: {
                path: 'm9.492815,0.142522a9.5,9.5 0 0 1 9.5,9.5c0,5.247 -9.5,14.25 -9.5,14.25s-9.5,-9.003 -9.5,-14.25a9.5,9.5 0 0 1 9.5,-9.5z',
                fillColor: '#f4ad10',
                fillOpacity: 1,
                anchor: new google.maps.Point(10, 24),
                strokeWeight: 0,
                scale: 1
            },
            markerHeadOffice: {
                url: '/assets/img/mapassets/head-office_small-icon.svg',
                size: new google.maps.Size(32, 38),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(10, 24),
            },
            markerDistributor: {
                url: '/assets/img/mapassets/distributor_icon.svg',
                size: new google.maps.Size(32, 38),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(10, 24),
            }
        };

        var state = {
            map: undefined,
            markers: undefined,
            results: undefined,
            currentPlace: undefined,
            currentMarker: undefined
        };

        function fetchLocations(headOffices, offices, distributors, country, latitude, longitude) {
            var ho = headOffices ? '1' : '0',
                o = offices ? '1' : '0',
                d = distributors ? '0' : '0',
                url = '/' + getCurrentLocale() + '/api/location-search?ho=' + ho + '&o=' + o + '&d=' + d,
                options = [];

            sendRequest(url, options).then(function (response) {
                clearResults();
                clearMarkers();

                if (response.data) {
                    for (var i in response.data) {
                        if (response.data.hasOwnProperty(i)) {
                            createMapMarker(response.data[i]);
                            createResult(response.data[i]);
                        }
                    }
                }

                if (state.place) {
                    updateMapPlace();
                }

                if (window.innerWidth < 768) {
                    state.map.setZoom(2);
                }

                // setMapCenter(state.map.getCenter()); // includes the offset

            }).catch(function (errors) {
                console.log(errors);
            });
        }

        function setMapCenter(latlng) {


            var offsetx = 50;
            var offsety = 50;

            if (window.innerWidth < 992) {
                var offsetx = 150;
                var offsety = 60;
            }

            if (window.innerWidth < 768) {
                var offsetx = 0;
                var offsety = -60;
            }

            var scale = Math.pow(2, state.map.getZoom());
            var worldCoordinateCenter = state.map.getProjection().fromLatLngToPoint(latlng);
            var pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);

            var worldCoordinateNewCenter = new google.maps.Point(
                worldCoordinateCenter.x - pixelOffset.x,
                worldCoordinateCenter.y + pixelOffset.y
            );

            var newCenter = state.map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

            state.map.setCenter(newCenter);
        }

        function clearMarkers() {
            state.currentMarker = undefined;

            for (var i in state.markers) {
                if (state.markers.hasOwnProperty(i)) {
                    state.markers[i].setMap(null);
                    state.markers[i] = null;
                }
            }
            state.markers = [];
        }

        function clearResults() {
            var msr = document.getElementById('map-search-results');
            msr.classList.add('d-none');
            msr.classList.remove('d-flex');
            for (var i in state.results) {
                if (state.results.hasOwnProperty(i)) {
                    var r = document.getElementById(state.results[i]);
                    if (r) {
                        r.remove();
                    }
                }
            }
            state.results = [];
        }

        function createMapMarker(data) {
            var icon = props.markerOffice;

            if (data.locationType === 'head-office') {
                icon = props.markerHeadOffice;
            } else if (data.locationType === 'distributor') {
                icon = props.markerDistributor;
            }
            // Filter office location to be displayed on a website
            var localLang = getCurrentLocale();
            localLang = (localLang === 'global') ? 'en_001' : (localLang === 'ca') ? 'en_CA' : (localLang === 'us') ? 'en_US' : (localLang === 'cn') ? 'zh_Hans' : localLang;

            try {
                var localSelector = data.showCountries.indexOf(localLang);
            } catch (e) {
                var localSelector = 0;
            }

            if (localSelector > -1) {
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(data.latitude, data.longitude),
                    title: data.name,
                    map: state.map,
                    icon: icon,
                    locationData: data
                });

                marker.addListener('click', function () {
                    focusOnMarker([marker], false);
                });

                state.bounds.extend(marker.getPosition());
                state.map.fitBounds(state.bounds);

                state.markers.push(marker);
            }
        }

        function getMarkerById(id) {
            var id = parseInt(id);
            for (var i in state.markers) {
                if (state.markers.hasOwnProperty(i)) {
                    if (state.markers[i].locationData.id === id) {
                        return state.markers[i];
                    }
                }
            }

            return null;
        }

        function getResultHtmlId(id) {
            return 'result-' + id;
        }

        function createResult(mapData) {
            var htmlId = getResultHtmlId(mapData.id);
            var div = document.createElement('div');
            div.innerHTML = [
                '<address style="display:none;" id="', htmlId, '" class="location-result-item" data-marker-id="', mapData.id, '">',
                '<h3 class="location-result-item__title">',
                mapData.name,
                '</h3>',

                (mapData.address ? [
                    '<div class="location-result-item__info">',
                    '<span class="location-result-item__info-icon"><img class="location-result-item__img" src="/assets/img/svg/pin-blue.svg" /></span>',
                    '<span class="location-result-item__info-desc">', mapData.address, '</span>',
                    '</div>',
                ].join('') : ''),

                (mapData.information ? [
                    '<div class="location-result-item__info extra-info">',
                    '<span class="location-result-item__info-icon"><img class="location-result-item__img" src="/assets/img/svg/info-blue.svg" /></span>',
                    '<span class="location-result-item__info-desc-small">', mapData.information, '</span>',
                    '</div>'
                ].join('') : ''),


                '<div class="location-result-item__info extra-info">',
                (mapData.telephone ? [
                    '<span class="location-result-item__info-icon"><img class="location-result-item__img" src="/assets/img/svg/phone-blue.svg" /></span>',
                    '<span class="location-result-item__info-desc t_color--highlight">T ', mapData.telephone, '</span>',
                ].join('') : ''),

                (mapData.fax ? [
                    '<span class="location-result-item__info-desc t_color--highlight">F ', mapData.fax, '</span>',
                ].join('') : ''),
                '</div>',


                (mapData.email ? [
                    '<div class="location-result-item__info extra-info">',
                    '<span class="location-result-item__info-icon"><img class="location-result-item__img" src="/assets/img/svg/email.svg" /></span>',
                    '<span class="location-result-item__info-desc t_color--highlight">', mapData.email, '</span>',
                    '</div>',
                ].join('') : ''),

                (mapData.email ? [
                    '<div class="location-result-item__info u_mt-1 extra-info">',
                    '<span class="location-result-item__info-icon"><img class="location-result-item__img-arrow" src="/assets/img/svg/arrow-right-blue.svg" /></span>',
                    '<a href="mailto:', mapData.email, '" class="t_color--highlight link link--pop location-result-item__link">', mapData.cto, '</a>',
                    '</div>',
                ].join('') : ''),

                [
                    '<div class="location-result-item__info extra-info-show">',
                    '<span class="location-result-item__info-icon"><img class="location-result-item__img-more" src="/assets/img/svg/arrow-down.svg" /></span>',
                    '<span class="t_color--highlight link link--pop location-result-item__more">', mapData.more, '</span>',
                    '</div>',
                ].join(''),

                '</address>'
            ].join('');

            document.getElementById('map-address-list').appendChild(div.firstChild);
            state.results.push(htmlId);
        }

        function updateResultsFromMapBounds() {
            // now get only the markers viewable in the current bounds of the map
            // and show their details in the display box
            for (var i = 0; i < state.markers.length; i++) {
                var result = document.getElementById(getResultHtmlId(state.markers[i].locationData.id));
                if (result && state.currentPlace && state.map.getBounds().contains(state.markers[i].getPosition())) {
                    result.style.display = 'block';

                } else {
                    result.style.display = 'none';
                }
            }
        }

        function focusOnMarker(marker, showHeader) {
            setMapCenter(marker[0].getPosition());
            state.currentMarker = marker[0];
            var display = false;
            var addresses = document.querySelectorAll('#map-address-list address');
            Array.from(addresses).forEach(function (address) {
                address.style.display = 'none';
            });
            for (var j = 0; j < marker.length; j++) {
                document.getElementById('result-' + marker[j].locationData.id).style.display = 'block';
                display = true;
            }

            if (display) {
                var msr = document.getElementById('map-search-results');
                msr.classList.add('d-flex');
                msr.classList.remove('d-none');
                var header = msr.querySelector('.point-of-contact__result-header');
                if (showHeader) {
                    header.style.display = 'block';
                    header.querySelector('div').innerText = document.getElementById('map-location-select').querySelector('.value').innerText + ' / ' + document.getElementById('map-interest-select').querySelector('.value').innerText;
                } else {
                    header.style.display = 'none';
                }
                if (marker.length > 1) {
                    msr.classList.add('location-result-multiple');
                } else {
                    msr.classList.remove('location-result-multiple');
                }
            }

        }

        function unfocusMarkers() {
            //uset the marker
            state.currentMarker = undefined;

            // hide all results
            for (var i = 0; i < state.results.length; i++) {
                var result = document.getElementById(state.results[i]);
                if (result) {
                    result.style.display = 'none';
                }
            }

        }

        //create the map and add the markers
        state.map = new google.maps.Map(document.getElementById("map"), props.mapConfig);
        state.bounds = new google.maps.LatLngBounds();

        // fetch the main offices
        fetchLocations('1', '1', '1');

        //listen for clicks on the result area
        document.getElementById('map-search-results').addEventListener('click', function (e) {
            if (e.target.matches('address')) {
                e.stopPropagation();
                var markerId = e.target.getAttribute('data-marker-id'),
                    marker = getMarkerById(markerId);
                if (marker) {
                    setMapCenter(marker.getPosition());
                    state.map.setZoom(15);
                }
            }
        });

        //listen for filter changes
        var headOfficeFilter = document.getElementById('headOfficeFilter'),
            officeFilter = document.getElementById('officeFilter'),
            distributorFilter = document.getElementById('distributorFilter');

        function onFilterChange() {
            fetchLocations(
                headOfficeFilter && headOfficeFilter.checked,
                officeFilter && officeFilter.checked,
                distributorFilter && distributorFilter.checked
            )
        }

        function updateMapPlace() {
            unfocusMarkers();

            // If the place has a geometry, then present it on a map.
            if (state.currentPlace.geometry.viewport) {
                state.map.fitBounds(state.currentPlace.geometry.viewport);
                setMapCenter(state.map.getCenter()); // includes the offset
            } else {
                setMapCenter(state.currentPlace.geometry.location);
                state.map.setZoom(17);  // Why 17? Because it looks good.
            }
        }

        if (headOfficeFilter) {
            headOfficeFilter.addEventListener('change', onFilterChange);
        }

        if (officeFilter) {
            officeFilter.addEventListener('change', onFilterChange);
        }

        if (distributorFilter) {
            distributorFilter.addEventListener('change', onFilterChange);
        }

        function searchGlobalPresence() {
            document.addEventListener('click', function (event) {
                if (event.target && event.target.matches('#map-location-select .dropdown-item')) {
                    event.preventDefault();
                    var value = event.target;

                    var btn = document.getElementById('map-location-select').querySelector('.dropbtn');
                    btn.querySelector('.edit').classList.remove('d-none');
                    btn.querySelector('.value').innerText = value.innerText;

                    var interest = document.getElementById('map-interest-select');
                    var btn = interest.querySelector('.dropbtn');
                    btn.classList.remove('disabled');

                    var items = interest.querySelectorAll('.dropdown-item[data-value]');
                    var index = value.getAttribute('data-index');
                    var interestPoints = pointsOfContactData['countries'][index]['v'];

                    var enquireMsg = false;
                    Array.from(items).forEach(function (item) {
                        item.classList.add('d-none');
                        Array.from(interestPoints).forEach(function (point) {
                            if (point['k'] == item.getAttribute('data-value')) {
                                item.classList.remove('d-none');
                                item.setAttribute('data-offices', point['v']);
                                if (point['k'] > 0) {
                                    enquireMsg = true;
                                }
                            }
                        });
                    });

                    var header = interest.querySelector('.dropdown-item-header');
                    if (enquireMsg) {
                        header.classList.remove('d-none');
                    } else {
                        header.classList.add('d-none');
                    }

                    setTimeout(function () {
                        btn.click();
                    }, 50);
                }
            });

            document.addEventListener('click', function (event) {
                if (event.target && event.target.matches('#map-interest-select .dropdown-item')) {
                    event.preventDefault();
                    var value = event.target;

                    var btn = document.getElementById('map-interest-select').querySelector('.dropbtn');
                    btn.querySelector('.edit').classList.remove('d-none');
                    btn.querySelector('.value').innerText = value.innerText;

                    var offices = value.getAttribute('data-offices');
                    offices = offices.split(',');


                    var marker = [];
                    for (var i = 0; i < offices.length; i++) {
                        marker.push(getMarkerById(offices[i]));
                    }

                    if (marker.length > 0) {
                        state.map.setZoom(6);
                        focusOnMarker(marker, true);
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                    }
                }
            });

            document.addEventListener('click', function (event) {
                if (event.target && event.target.matches('.location-result-multiple .location-result-item__more')) {
                    var btn = event.target;
                    var extraInfo = btn.closest('.location-result-item').querySelectorAll('.extra-info');
                    Array.from(extraInfo).forEach(function (info) {
                        info.classList.add('d-block');
                    });
                    btn.closest('.extra-info-show').classList.add('d-none');
                }
            });

            document.addEventListener('click', function (event) {
                if (event.target && event.target.matches('.zoom-control-plus, .zoom-control-plus *')) {
                    state.map.setZoom(state.map.getZoom() + 1);
                }
            });

            document.addEventListener('click', function (event) {
                if (event.target && event.target.matches('.zoom-control-minus, .zoom-control-minus *')) {
                    if (state.map.getZoom() > 2) {
                        state.map.setZoom(state.map.getZoom() - 1);
                    }
                }
            });

        }
        searchGlobalPresence();

    });
}
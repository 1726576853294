function contactOrderForm() {

    var contactOrderForm = document.querySelector('.form__nav-contactOrder');

    if (contactOrderForm) {

        function contactOrderFormErrors(errors) {
            for(var i = 0; i < errors.length; i++) {
                
                for (var key in errors[i]) {
                    var node = createErrorNode(errors[i][key]);

                    if (key == 'form') {
                        contactOrderForm.appendChild(node);
                    } else {
                        var elem = contactOrderForm.querySelector('.contactOrder-' + key);
                        elem.parentElement.appendChild(node);
                    }
                }
            }
        }

        var submitBtn = document.querySelector('.js_contactOrder-submit');

        submitBtn.addEventListener('click', function(event) {
            event.preventDefault();
            submitBtn.disabled = true;
            removeElementsByClass('error-speech-bubble');

            var formData = new FormData(contactOrderForm);

            var options = {
                method: 'POST',
                credentials: 'include',
                body: formData
            };

            sendRequest('/' + getCurrentLocale() + '/customer/portal/contactOrderSubmit', options).then(function(success) {
                formTogglePopupSuccess(contactOrderForm);
                submitBtn.disabled = false;
            }).catch(function(errors) {
                contactOrderFormErrors(errors.error);
                submitBtn.disabled = false;
            });
        });

    }

    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.open-contactOrder, .open-contactOrder *')){
            event.preventDefault();

            openModel('contact-order');
        }
    });


}

contactOrderForm();
function expandCollapseFilterSection() {

  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_filter-dropdown, .js_filter-dropdown *')) {
      event.preventDefault();
      var section = event.target.closest('.js_product-filter__section');
      section.classList.toggle('product-filter__section--is-expanded');
      section.querySelector('.product-filter__dropdown-icon').classList.toggle('product-filter__dropdown-icon--is-toggled');
    }
  });

  function expandCollapseFilters(index, open) {
    var section = document.querySelectorAll('.js_product-filter__section')[index];
    if (section) {
      if (open == true) {
        section.classList.add('product-filter__section--is-expanded');
        section.querySelector('.product-filter__dropdown-icon').classList.add('product-filter__dropdown-icon--is-toggled');
      } else {
        section.classList.remove('product-filter__section--is-expanded');
        section.querySelector('.product-filter__dropdown-icon').classList.remove('product-filter__dropdown-icon--is-toggled');
      }
    }
  }
  expandCollapseFilters(0, true);
  expandCollapseFilters(1, true);
  expandCollapseFilters(2, true);

  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_product-filter-trigger-btn')) {
      event.preventDefault();
      var sections = document.querySelectorAll('.js_product-filter__section');
      var counter = 0;
      Array.from(sections).forEach(function(section) {
        expandCollapseFilters(counter, false);
        counter++;
      });
      document.querySelector(".js_product-filter").classList.add('product-filter--is-visible');
      document.querySelector("body").classList.add('is-open');
    }
  });

  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_close-filter')) {
      event.preventDefault();
      document.querySelector(".js_product-filter").classList.remove('product-filter--is-visible');
      document.querySelector("body").classList.remove('is-open');
    }
  });

  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_clear-all-filters')) {
      event.preventDefault();
      var inputs = document.querySelectorAll('#js_filterfield input[type=checkbox], #js_filterfield input[type=radio]');
      var isChecked = false;
      Array.from(inputs).forEach(function(input) {
        if (input.checked) {
          isChecked = true;
        }
        input.checked = false;
      });
      if (isChecked) {
        document.getElementById('js_filterfield').submit();
      } else {
        location.reload();
      }
    }
  });
  
  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_product-filter-unselect')) {
      event.preventDefault();
      var inputs = event.target.closest('ul').querySelectorAll('input[type=checkbox], input[type=radio]');
      var isChecked = false;
      Array.from(inputs).forEach(function(input) {
        if (input.checked) {
          isChecked = true;
        }
        input.checked = false;
      });
      if (isChecked) {
        document.getElementById('js_filterfield').submit();
      } else {
        location.reload();
      }
    }
  });
  
}
expandCollapseFilterSection();

function createFilterBoxes() {

  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_product-filter-close')) {
      event.preventDefault();
      var close = event.target;
      var id = close.getAttribute('data-id');
      var input = document.getElementById(id);
      input.checked = false;
      document.getElementById('js_filterfield').submit();
    }
  });

  var inputs = document.querySelectorAll('#js_filterfield input[type=checkbox][checked=checked], #js_filterfield input[type=radio][checked=checked]');
  if (inputs.length > 0) {
    var container = document.getElementById('filter-box-container');
    var title = document.getElementById('filter-box-title');
    title.classList.remove('d-none');
    document.querySelector('.product-view__filters-section--right').classList.add('filters-added');
    var counter = 0;
    Array.from(inputs).forEach(function(input) {
      if (input.checked) {
        var span = document.createElement("span");
        span.classList.add('product-view__filter-box');
        var id = input.getAttribute('id');
        var caption = input.parentElement.querySelector('.caption').innerText.replace(/(\r\n|\n|\r)/gm, "");
        span.innerHTML = caption+'<span class="product-view__filter-close js_product-filter-close" data-id="'+id+'">&times;</span>';
        container.appendChild(span);
        counter++;
      }
    });
    if (counter > 0) {
      document.querySelector('.js_product-filter-trigger-btn .counter').innerText = '(' + counter + ')';
    }
  }
}

ready(function() {
  createFilterBoxes();
});
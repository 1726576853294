var faqSearch = document.getElementById('faq-search-form');

function faqSearchAction() {
    var input = document.querySelector('.faq-search__input').value;

    if (input.length > 0) {
        window.location.href = '/' + getCurrentLocale() + '/faq?search=' + input;
    }
}

if(faqSearch) {
    searchForm.addEventListener('submit', function (event) {
        event.preventDefault();
        faqSearchAction();
    });

    var searchBtn = document.querySelector('.faq-search__icon');

    searchBtn.addEventListener('click', function (event) {
        event.preventDefault();
        faqSearchAction();
    });
}

var faqProductSearch = document.getElementById('faq-product-search');

if(faqProductSearch) {
    faqProductSearch.addEventListener('submit', function (event) {
        event.preventDefault();
        faqSearchAction();
    });
}

var faqForm = document.querySelector('.faq-form');

if (faqForm) {
    //TODO - Make these generic enough similar to validateForms in order to allow for one function to handle them all.
    function faqFormErrors(errors) {
        for(var key in errors) {
            var node = createErrorNode(errors[key]);

            if(key == 'form') {
                faqForm.appendChild(node);
            } else {
                var elem = faqForm.querySelector('.faq-' + key);
                elem.parentElement.appendChild(node);
            }
        }
    }

    var submitBtn = faqForm.querySelector('.js_form-submit');

    if(submitBtn) {
        submitBtn.addEventListener('click', function(event) {
            event.preventDefault();
            removeElementsByClass('error-speech-bubble');

            var formData = new FormData(faqForm);

            var options = {
                method: 'POST',
                credentials: 'include',
                body: formData
            };

            sendRequest('/' + getCurrentLocale() + '/faq/submit', options).then(function(success) {
                var successPage = document.querySelector('.faq-success');
                formToggleSuccess(faqForm, successPage);
            }).catch(function(errors) {
                faqFormErrors(errors.error);
            });
        });
    }
}
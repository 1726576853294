function customerLoginInitialize() {
    var login = document.querySelector('.js_popup[data-model="login"]');

    if(login) {

        var loginLinks = document.querySelectorAll('.js_open-login');

        if (loginLinks) {
            Array.from(loginLinks).forEach(function (trigger) {
                trigger.addEventListener('click', function (event) {
                    togglePageSlider(login);
                });
            });
        }

        var loginClose = document.querySelectorAll('.js_close-login');

        if (loginClose) {
            Array.from(loginClose).forEach(function (close) {
                close.addEventListener('click', function (event) {
                    var loginForm = login.querySelector('.login-form');

                    if (loginForm.classList.contains('d-none')) {
                        var resetPasswordPage = login.querySelector('.password-form');
                        var accountRequestPage = login.querySelector('.account-form');

                        if(resetPasswordPage.classList.contains('d-block')) {
                            resetPasswordPage.classList.add('d-none');
                            resetPasswordPage.classList.remove('d-block');
                        } else {
                            accountRequestPage.classList.add('d-none');
                            accountRequestPage.classList.remove('d-block');
                        }

                        loginForm.classList.add('d-block');
                        loginForm.classList.remove('d-none');
                    } else {
                        togglePageSlider(login);
                    }
                });
            })
        }

        var forms = document.querySelectorAll('.login-form');

        Array.from(forms).forEach(function (form) {
            var loginBtn = form.querySelector('.js_login-submit');

            loginBtn.addEventListener('click', function (event) {
                event.preventDefault();

                var button = event.target;
                var form = button.closest('form');
    
                var isValid = form.checkValidity();
                if (isValid == false) {
                    form.reportValidity();
                    return false;
                }

                var fields = {
                    _username: form.querySelector('.login-email').value,
                    _password: form.querySelector('.login-password').value,
                    _csrf_token: form.querySelector('.login-token').value,
                };
                
                var rememberMe = form.querySelector('.login-remember_me');
                if (rememberMe.checked == true) {
                    fields['_remember_me'] = rememberMe.value;
                }

                var options = {
                    method: 'POST',
                    body: prepareFormData(fields)
                };

                removeElementsByClass('error-speech-bubble');

                var url = '/' + getCurrentLocale() + '/customer/portal/login';

                button.disabled = true;

                sendRequest(url, options).then(function (success) {
                    console.log(success);
                    if ((location.pathname+'/').indexOf('/customer/portal/checkout') !== -1) {
                    var url = '/' + getCurrentLocale() + '/customer/portal/cart';
                        window.location.href = url;
                    } else {
                        location.reload();
                    }
                    button.disabled = false;
                }).catch(function (errors) {
                    var loginError = document.getElementById('login-password-field');
                    var node = createErrorNode(errors.error.form);
                    loginError.appendChild(node);
                    button.disabled = false;
                });
            });

            var resetPasswordBtn = form.querySelector('.reset-password');

            if(resetPasswordBtn) {
                var resetPasswordPage = form.parentElement.querySelector('.password-form');

                function resetPasswordSuccessToggle() {
                    var resetPreSubmission = resetPasswordPage.querySelector('.reset-password-pre-submission');
                    var resetPostSubmission = resetPasswordPage.querySelector('.reset-submission-successful');

                    resetPreSubmission.classList.add('d-none');
                    resetPreSubmission.classList.remove('d-block');

                    resetPostSubmission.classList.add('d-block');
                    resetPostSubmission.classList.remove('d-none');
                }

                document.addEventListener('click', function (event) {
                    if (event.target && event.target.matches('.reset-password')) {
                        resetPasswordPage.classList.add('d-block');
                        resetPasswordPage.classList.remove('d-none');

                        form.classList.add('d-none');
                        form.classList.remove('d-block');

                        var resetForm = resetPasswordPage.querySelector('.reset-password-form');

                        var resetSubmit = resetForm.querySelector('.js_form-password-reset-submit');

                        resetSubmit.addEventListener('click', function (event) {
                            event.preventDefault();

                            var options = {
                                method: 'POST',
                                credentials: 'include',
                                body: prepareFormData(
                                    {
                                        email: resetForm.querySelector('.reset-email').value,
                                        csrf: resetForm.querySelector('.reset-password-token').value
                                    }
                                )
                            };

                            resetSubmit.disabled = true;

                            var item = event.target;

                            sendRequest('/' + getCurrentLocale() + '/customer/portal/password-reset' , options).then(function (success) {
                                formTogglePopupSuccess(resetForm);
                                resetForm.querySelector('.reset-email').value = '';
                                
                                resetPasswordPage.classList.add('d-none');
                                resetPasswordPage.classList.remove('d-block');
                                form.classList.add('d-block');
                                form.classList.remove('d-none');

                            }).catch(function (errors) {
                                console.log(errors);
                                resetSubmit.disabled = false;
                            });
                        });
                    }
                });
            }

            var accountRequestBtn = form.querySelector('.account-request');

            if(accountRequestBtn) {
                var accountRequestPage = form.parentElement.querySelector('.account-form');

                function accountFormErrors(errors) {
                    var accountForms = document.querySelectorAll('.account-request-form');
                    Array.from(accountForms).forEach(function (form) {
                        for (var key in errors) {
                            var node = createErrorNode(errors[key]);

                            if (key == 'form') {
                                form.appendChild(node);
                            } else {
                                var elem = form.querySelector('.account-' + key);
                                elem.parentElement.appendChild(node);
                            }
                        }
                    });
                }

                function resetAccountSuccessToggle() {
                    var accountPreSubmission = accountRequestPage.querySelector('.account-pre-submission');
                    var accountPostSubmission = accountRequestPage.querySelector('.account-submission-successful');

                    accountPreSubmission.classList.add('d-none');
                    accountPreSubmission.classList.remove('d-block');

                    accountPostSubmission.classList.add('d-block');
                    accountPostSubmission.classList.remove('d-none');
                }

                accountRequestBtn.addEventListener('click', function(event) {
                    accountRequestPage.classList.add('d-block');
                    accountRequestPage.classList.remove('d-none');

                    form.classList.add('d-none');
                    form.classList.remove('d-block');

                    var accountForm = accountRequestPage.querySelector('.account-request-form');

                    var accountSubmit = accountForm.querySelector('.js_form-submit');

                    accountSubmit.addEventListener('click', function (event) {
                        event.preventDefault();
                        var accountFormData = new FormData(accountForm);

                        var options = {
                            method: 'POST',
                            credentials: 'include',
                            body: accountFormData
                        };

                        removeElementsByClass('error-speech-bubble');

                        sendRequest('/' + getCurrentLocale() + '/api/customer/portal/accountRequest', options).then(function (success) {
                            resetAccountSuccessToggle();
                        }).catch(function (errors) {
                            accountFormErrors(errors.error);
                        });
                    });
                });
            }
        });
    }
}
customerLoginInitialize();

function customerRegistrationInitialize() {
    var register = document.querySelector('.register-slider');

    if (register) {
        var registerLinks = document.querySelectorAll('.js_open-register');
        var forms = document.querySelectorAll('.register-form');

        if (registerLinks) {
            Array.from(registerLinks).forEach(function (trigger) {
                trigger.addEventListener('click', function (event) {
                    togglePageSlider(register);
                });
            });
        }

        var registerClose = document.querySelectorAll('.js_close-register');

        if (registerClose) {
            Array.from(registerClose).forEach(function (close) {
                close.addEventListener('click', function (event) {
                    togglePageSlider(register);
                });
            });
        }

        Array.from(forms).forEach(function (form) {
            var regBtn = form.querySelector('.js_register-submit');

            regBtn.addEventListener('click', function (event) {
                event.preventDefault();

                var fields = {
                    email: form.querySelector('.register-email').value,
                    firstname: form.querySelector('.register-firstname').value,
                    lastname: form.querySelector('.register-lastname').value,
                    password: form.querySelector('.register-password').value,
                    _token: form.querySelector('.register-token').value,
                    terms: form.querySelector('.register-terms').checked,
                };

                var formData = new FormData();
                formData.append('data', JSON.stringify(fields));

                var options = {
                    method: 'POST',
                    credentials: 'include',
                    body: formData
                };
                removeElementsByClass('error-speech-bubble');

                var url = '/' + getCurrentLocale() + '/customer/portal/register';

                sendRequest(url, options).then(function (success) {
                    //activate-account-message
                    var regForm = form.querySelector('.d-block');
                    regForm.classList.add('d-none');
                    regForm.classList.remove('d-block');

                    var activateMsg = form.querySelector('.activate-account-message');
                    activateMsg.classList.remove('d-done');
                    activateMsg.classList.add('d-block');

                }).catch(function (errors) {
                    var formElm = form.querySelector('form');
                    handleFormErrors(errors.error, formElm, '.register-');
                });
            });
        });

    }
}
customerRegistrationInitialize();


function openRegister() {
    var openSliders = document.querySelectorAll('.step-1');
    Array.from(openSliders).forEach(function(openSlider) {
        openSlider.classList.remove('step-1');
    });
    var register = document.querySelector('.register-slider');
    togglePageSlider(register);
}

ready(function () {
    if(window.location.href.indexOf("#show-login") > -1) {
        openLoginModel();
        var path = window.location.pathname.split('#show-login')[0];
        window.history.replaceState({}, document.title, path);
    }
});

function resetPasswordFromAccount(){
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.account-password-reset .reset-link')) {

            var item = event.target;

            var icon = item.getAttribute('data-icon');
            var title = item.getAttribute('data-title');
            var message = item.getAttribute('data-message');

            openModelMessage(title, message, icon);

            event.preventDefault();

            var item = event.target;
            var options = {
                method: 'POST',
                credentials: 'include',
                body: prepareFormData(
                    {
                        email: item.getAttribute('data-email'),
                        csrf: item.getAttribute('data-csrf')
                    }
                )
            };

            sendRequest('/' + getCurrentLocale() + '/customer/portal/password-reset' , options).then(function (success) {
                console.log(success);
            }).catch(function (errors) {
                console.log(errors);
            });
        }
    });
}
resetPasswordFromAccount();


function createAnAccountMobile() {
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.create-an-account-mobile')) {
            window.location.href = '/' + getCurrentLocale() + '/customer/portal/register';
        }
    });

}
createAnAccountMobile();
function scrollRelatedProducts() {
  document.addEventListener('click', function(event){

    var container = document.querySelector('.js_rp-container');
  
    if(event.target && event.target.matches('.js_rp-prev')) {
      container.scrollLeft -= 200;
    };
  
    if(event.target && event.target.matches('.js_rp-next')) {
      container.scrollLeft += 200;
    };
  });
};

scrollRelatedProducts();


var feedbackForms = document.querySelectorAll('.form__nav-feedback-form');

if (feedbackForms.length > 0) {

    function feedbackFormErrors(errors) {
        Array.from(feedbackForms).forEach(function (feedback) {
            for (var key in errors) {
                var node = createErrorNode(errors[key]);

                if (key == 'form') {
                    feedback.appendChild(node);
                } else {
                    var elem = feedback.querySelector('.feedback-form' + key);
                    elem.parentElement.appendChild(node);
                }
            }
        });
    }

    var submitBtn = document.querySelector('.js_feedback-form-submit');

    if(submitBtn) {
        Array.from(feedbackForms).forEach(function(feedback) {
            var submitBtn = feedback.querySelector('.js_form-submit');

            if(submitBtn) {

                submitBtn.addEventListener('click', function(event) {
                    event.preventDefault();
                    submitBtn.disabled = true;
                    removeElementsByClass('error-speech-bubble');

                    var formData = new FormData(feedback);

                    var options = {
                        method: 'POST',
                        credentials: 'include',
                        body: formData
                    };

                    sendRequest('/' + getCurrentLocale() + '/feedback-form/submit', options).then(function(success) {
                        var successPage = feedback.parentElement.querySelector('.feedback-form-success');
                        formToggleSuccess(feedback, successPage);
                        submitBtn.disabled = false;
                    }).catch(function(errors) {
                        feedbackFormErrors(errors.error);
                        submitBtn.disabled = false;
                    });
                });
            }
        });
    }
}
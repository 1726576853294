function getAccountStats() {
    
    var getStats = true;

    var cartTotal = document.getElementById('cart-total');

    if (getStats) {
        
        var url = '/' + getCurrentLocale() + '/cart/getAccountStats';

        sendRequest(url,{}).then(function (success) {

            if (success.data.cartTotal > 0) {
                cartTotal.innerHTML = '('+success.data.cartTotal+')';
                cartTotal.setAttribute('data-total', success.data.cartTotal);
            }

        }).catch(function (errors) {
            console.log(errors);
        });
    }
}
ready(function () {
getAccountStats();
});

function setCartTotal(amount) {
    var cartTotal = document.getElementById('cart-total');
    cartTotal.innerHTML = '('+amount+')';
    cartTotal.setAttribute('data-total', amount);
}

function updateOrderItem() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.js_update-order-item')){
            event.preventDefault();
            var button = event.target;
            var item = event.target.closest('.t_table-product__row');
            var product_id = item.getAttribute('data-itemKey');
            var qtyInput = item.querySelector('.cart__order-quantity-input');
            var qty = qtyInput.value;
            var oldQty = qtyInput.getAttribute('data-old-qty');
            var diff = Number(qty) - Number(oldQty);

            if (diff != 0) {

                var params = {
                    product_id: product_id,
                    quantity: qty
                };

                cartAction('update', params).then(function (success) {
                    var getPage = button.getAttribute('data-page');
                    var url = '/' + getCurrentLocale() + '/customer/portal/' + getPage;
                    if (getPage == 'cart') {
                        document.cookie = "check_est_shipping=1; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
                    }
                    if (diff < 0) {
                        removeFromCartTracking(item, url, Math.abs(diff));
                    } else {
                        addToCartTracking(item, 'checkout-row', diff, url);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
        }
    });
}
updateOrderItem();

function checkEstShippingChange() {
    if (document.getElementById("cart-summary") !== null) {
        var ced = getCookie('check_est_shipping');
        if (ced != "") {
            var leadTimeData = document.getElementById("newLeadTime");
            if (leadTimeData.getAttribute('data-old') != leadTimeData.getAttribute('data-new')) {
                document.getElementById("changeInLeadTimeMessage").classList.remove("d-none");
            }
            document.cookie = "check_est_shipping=1; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;SameSite=Lax";
        }
    }
}

ready(function() {
    checkEstShippingChange();
});

function removeOrderItem() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.js_remove-order-item')){
            event.preventDefault();
            var button = event.target;
            var item = event.target.closest('.t_table-product__row');
            var product_id = item.getAttribute('data-itemKey');
            var params = {
                product_id: product_id,
            };

            cartAction('remove', params).then(function (success) {
                var getPage = button.getAttribute('data-page');
                var url = '/' + getCurrentLocale() + '/customer/portal/' + getPage;
                var qty = item.querySelector('.cart__order-quantity-input').value;
                removeFromCartTracking(item, url, qty);
            }).catch(function (error) {
                console.log(error);
            });
        }
    });
}
removeOrderItem();

function removeAllItems() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('#js_clear-cart')){
            event.preventDefault();

            cartAction('removeAll', {}).then(function (success) {
                var url = '/' + getCurrentLocale() + '/customer/portal/cart';
                window.location.href = url;
                console.log(success);
            }).catch(function (error) {
                console.log(error);
            });
        }
    });
}
removeAllItems();

function loginFromCheckout() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('#js_checkout-signin')){
            event.preventDefault();

            var button = event.target;
            var form = button.closest('form');

            var isValid = form.checkValidity();
            if (isValid == false) {
                form.reportValidity();
                return false;
            }

            var fields = {
                _username: document.getElementById('checkout-signin-email').value,
                _password: document.getElementById('checkout-signin-password').value,
                _csrf_token: document.getElementById('checkout-signin-csrf').value,
            };
            var options = {
                method: 'POST',
                body: prepareFormData(fields)
            };

            removeElementsByClass('error-speech-bubble');

            var url = '/' + getCurrentLocale() + '/customer/portal/login';

            button.disabled = true;

            sendRequest(url, options).then(function (success) {
                console.log(success);
                gotoCheckoutPage();
                button.disabled = false;
            }).catch(function (errors) {
                var messageLocation = document.querySelector('.checkout-signin-password').parentNode;
                handleFormErrors(
                    {form: errors.error.form}, 
                    messageLocation, 
                    '.checkout-signin-'
                );
                button.disabled = false;
            });
        }
    });

    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.js_checkout-toggle-login-section')){
            event.preventDefault();
            var checkoutModalSection = document.querySelectorAll('.checkout-modal__login-section');
            Array.from(checkoutModalSection).forEach(function(section) {
                section.classList.toggle('d-none');
            });
        }
    });
    
}
loginFromCheckout() 

function resetPasswordFromCheckout() {
    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('#js_checkout-reset-password')){
            event.preventDefault();
            var form = event.target.closest('form');
            var isValid = form.checkValidity();
            if (isValid == false) {
              form.reportValidity();
                return false;
            }

            var options = {
                method: 'POST',
                credentials: 'include',
                body: prepareFormData(
                    {
                        email: document.getElementById('checkout-password-reset-email').value,
                        csrf: document.getElementById('checkout-password-reset-csrf').value
                    }
                )
            };

            sendRequest('/' + getCurrentLocale() + '/customer/portal/password-reset' , options).then(function (success) {
                formTogglePopupSuccess(form);
            }).catch(function (errors) {
                console.log(errors);
            });
        }
    });
}
resetPasswordFromCheckout();

function gotoCheckoutPage() {
    var url = '/' + getCurrentLocale() + '/customer/portal/checkout';
    var userType = 'Guest';
    if (typeof (shopUserType) !== 'undefined') {
        userType = shopUserType;
    }
    var option = userType + ' User';
    checkoutStepTracking(1, url, option);
}

function checkoutFormErrors(errors, section, prefix) {
    for(var key in errors) {

        var errorNode = createErrorNode(errors[key]);
       
        var elem = section.querySelector(prefix + key);

        if (key == 'terms-conditions') {
            elem.parentElement.appendChild(errorNode);
        } 
        else if (key == 'intended-use') {
            elem.appendChild(errorNode);
        }
        else {
            elem.parentNode.parentNode.insertBefore(errorNode, elem.parentNode.nextSibling);
        }
        
        var target = getOffsetTop(errorNode) - 100;
        window.scrollTo(0, target);
    }
}

function checkoutValidateForms(forms) {
    var isValid = true;
    for(var i = 0; i < forms.length; i++) {
        var form = document.getElementById(forms[i]);
        if (form.checkValidity() == false) {
            isValid = false;
            break;
        }
    }

    var btns = document.querySelectorAll('.js_checkout-review-order');
    for(var b = 0; b < btns.length; b++) {
        btns[b].disabled = !isValid;
    }
}

function checkoutActivateSubmitButton() {

    var forms = [
        'checkout-contact-form',
        'checkout-shipping-form',
        'checkout-billing-address-form'
    ];

    var submitBtn = document.querySelector('.js_checkout-review-order');
    if (submitBtn) {
        checkoutValidateForms(forms);
        var confirmEmail = document.getElementById('checkout-form-confirm-email');

        for(var i = 0; i < forms.length; i++) {
            var form = document.getElementById(forms[i]);

            var inputs = form.querySelectorAll('input, textarea');

            for(var a = 0; a < inputs.length; a++) {
                inputs[a].addEventListener('input', function(event) {
                    if (event.target.getAttribute('id') == 'checkout-form-confirm-email') {
                        event.target.setCustomValidity('');
                    }
                    else if (event.target.getAttribute('id') == 'checkout-form-email') {
                        if (event.target.value != confirmEmail.value) {
                            confirmEmail.closest('.field--has-border').classList.remove('d-none-fade');
                            confirmEmail.value = '';
                        }
                    }
                    checkoutValidateForms(forms);
                });
            }

            var selects = form.querySelectorAll('select');

            for(var b = 0; b < selects.length; b++) {
                selects[b].addEventListener('change', function(event) {
                    checkoutValidateForms(forms);
                });
            }
        }
    }
}
checkoutActivateSubmitButton();

ready(function () {
    var checkoutMainContainer = document.getElementById('checkout-main-container');
    if (checkoutMainContainer) {
        if(window.location.href.indexOf("#") > -1) {
            var bookmark = location.hash.substring(1);
            setTimeout(function() {
                var path = window.location.pathname.split('#'+bookmark)[0];
                window.history.replaceState({}, document.title, path);
                var section = document.getElementById(bookmark);
                expandClosestSection(section);
            }, 250);
        }
    }
});

function checkoutEmailsNotMatching(confirmEmail) {
    confirmEmail.setCustomValidity(jsTranslations["The e-mail addresses you have entered do not match. Please try again."]);
    confirmEmail.reportValidity();
    setTimeout(function() {
        var y = getOffsetTop(confirmEmail.parentElement.parentElement);
        window.scrollTo(0, y - 60);
    }, 250);
}

function commitToCheckoutReview(checkoutMethod, item) {

    removeElementsByClass('error-speech-bubble');

    var formsValidated = true;
    var forms = [
        'checkout-contact-form',
        'checkout-shipping-form',
        'checkout-billing-address-form'
    ];

    for(var i = 0; i < forms.length; i++) {
        var form = document.getElementById(forms[i]);
        var fields = form.querySelectorAll('input, textarea');
        for(var j = 0; j < fields.length; j++) {
            var field = fields[j];
            var isValid = field.checkValidity();
            if (isValid == false) {
                expandClosestSection(field);
                
                field.reportValidity();
                setTimeout(function() {
                    var y = getOffsetTop(field.parentElement.parentElement);
                    window.scrollTo(0, y - 60);
                }, 250);
                formsValidated = false;
                break;
            }
        }

        if (formsValidated == false) {
            break;
        }
    }

    var email = document.getElementById('checkout-form-email');
    var confirmEmail = document.getElementById('checkout-form-confirm-email');

    if(email.value != confirmEmail.value) {
        checkoutEmailsNotMatching(confirmEmail);
        formsValidated = false;
    }

    if (formsValidated == true) {
        var intendedUse = document.querySelector('input[name="checkout-form-intended-use"]:checked');
        var fields = {

            'checkout_form[first-name]': document.getElementById('checkout-form-first-name').value,
            'checkout_form[last-name]': document.getElementById('checkout-form-last-name').value,
            'checkout_form[email]': email.value,
            'checkout_form[confirm-email]': confirmEmail.value,
            'checkout_form[phone]': document.getElementById('checkout-form-phone').value,
            'checkout_form[company]': document.getElementById('checkout-form-company').value,
            'checkout_form[intended-use]': intendedUse ? intendedUse.value : '',
            
            'checkout_form[street]': document.getElementById('checkout-form-street').value,
            'checkout_form[city]': document.getElementById('checkout-form-city').value,
            'checkout_form[region]': document.getElementById('checkout-form-region').value,
            'checkout_form[zip]': document.getElementById('checkout-form-zip').value,

            'checkout_form[save-address]': document.getElementById('checkout-form-save-address').checked,
            'checkout_form[specific-date-check]': document.getElementById('checkout-form-specific-date-check').checked,
            'checkout_form[delivery-note-check]': document.getElementById('checkout-form-delivery-note-check').checked,


            'checkout_form[specific-date]': document.getElementById('checkout-form-specific-date-check').checked ? document.getElementById('checkout-form-specific-date').value : '',
            'checkout_form[delivery-note]': document.getElementById('checkout-form-delivery-note-check').checked ? document.getElementById('checkout-form-delivery-note').value : '',

            'checkout_form[billing-with-shipping-check]': document.getElementById('checkout-form-billing-with-shipping-check').checked,
            'checkout_form[billing-street]': document.getElementById('checkout-form-billing-street').value,
            'checkout_form[billing-city]': document.getElementById('checkout-form-billing-city').value,
            'checkout_form[billing-region]': document.getElementById('checkout-form-billing-region').value,
            'checkout_form[billing-zip]': document.getElementById('checkout-form-billing-zip').value,


            'checkout_form[_token]': document.getElementById('checkout-form-token').value,
            'checkout_form[terms-conditions]': document.getElementById('checkout-form-terms-conditions').checked,
            'checkout_form[professional-use-conditions]': document.getElementById('checkout-form-professional-use-conditions').checked,


            'checkout_form[shipping-shipmentid]': document.getElementById('checkout-shipping-shipmentid').value,
            'checkout_form[shipping-carriercode]': document.getElementById('checkout-shipping-carriercode').value,
            'checkout_form[shipping-methodcode]': document.getElementById('checkout-shipping-methodcode').value
        };

        var options = {
            method: 'POST',
            body: prepareFormData(fields)
        };


        item.disabled = true;
        sendRequest('/' + getCurrentLocale() + '/checkout/validateCheckoutDetails', options).then(function (success) {


            if (checkoutMethod == 'validate') {
                var option = 'Intended Use: ' + intendedUse.value.toString().replace('-tag', '');
                checkoutStepTracking(2, '', option);
                buildShippingSelector(success);
                openModel('shipping-options');
                item.disabled = false;
            } else {
                var url = '/' + getCurrentLocale() + '/customer/portal/checkout/review';
                var radio = document.querySelector('input[name="shipping-radio"]:checked');
                var option = radio.parentElement.querySelector('label').innerText;
                checkoutStepTracking(3, url, option);
            }
        }).catch(function (error) {
            expandAllSections(true);
            var container = document.getElementById('checkout-main-container');
            checkoutFormErrors(error.error, container, '#checkout-form-');
            item.disabled = false;
        });

    }

}


function gotoCart() {
    document.addEventListener('click',function(event) {
        if(event.target && event.target.matches('.goto-cart, .goto-cart *')){
            event.preventDefault();

            var cartTotal = document.getElementById('cart-total');
            if (cartTotal.getAttribute('data-total') == 0) {
                var item = document.getElementById('model-empty-cart');
                var icon = item.getAttribute('data-icon');
                var title = item.getAttribute('data-title');
                var message = item.getAttribute('data-message');
                openModelMessage(title, message, icon);
            } else {
                var url = '/' + getCurrentLocale() + '/customer/portal/cart';
                window.location.href = url;
            }

        }
    });
    
    
}
gotoCart();

function showItemAddedModel(name, price) {
    document.cookie = "store_url=" + window.location.href + "; expires=Thu, 18 Dec 2043 12:00:00 UTC;path=/;SameSite=Lax";
    document.querySelector('.item-added-to-cart__product').innerHTML = name;
    document.querySelector('.item-added-to-cart__price').innerHTML = price;
    openModel('item-added-to-cart');
}

function gotoStoreUrl() {
    var url = getCookie('store_url');
    if (url != "") {
        window.location.href = url;
    } else {
        window.history.back();
    }
}

function addressSelector() {
    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.js_address-selector')) {
            event.preventDefault();
            var radio = document.querySelector('input[name="address-list-radio"]:checked');

            document.getElementById('checkout-form-street').value = radio.getAttribute('data-street');
            document.getElementById('checkout-form-city').value = radio.getAttribute('data-city');
            document.getElementById('checkout-form-region').value = radio.getAttribute('data-region');

            var checkoutFormZip = document.getElementById('checkout-form-zip');
            checkoutFormZip.value = radio.getAttribute('data-zip');
            checkoutFormZip.setAttribute('value', radio.getAttribute('data-zip'));
            checkoutFormZip.setCustomValidity('');

            if (document.getElementById('checkout-form-billing-with-shipping-check').checked == true) {
                document.getElementById('checkout-form-billing-street').value = radio.getAttribute('data-street');
                document.getElementById('checkout-form-billing-city').value = radio.getAttribute('data-city');
                document.getElementById('checkout-form-billing-region').value = radio.getAttribute('data-region');
                document.getElementById('checkout-form-billing-zip').value = radio.getAttribute('data-zip');
            }

            closeModel('show-all-addresses');
        }
    });

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('.js_show-saved-addresses')) {
            event.preventDefault();
            openModel('show-all-addresses');
        }
    });
    
}
addressSelector();

function buildShippingSelector(shippingData) {
    
    var shipmentsData = shippingData.data.data.retrieveFullShippingQuote.shipments;
    var html = [];

    for (var s in shipmentsData) {
        var shipments = shipmentsData[s];
        for (var c in shipments.carriers) {
            var carrier = shipments.carriers[c];
            for (var m in carrier.methods) {

                if (m != 'contains') {
                    var shipMethod = carrier.methods[m];

                    var shippingId = shipments.shipmentDetail.shipmentId + carrier.carrierDetail.carrierCode + shipMethod.methodDetails.methodCode;

                    var totalCharges = shipMethod.methodDetails.totalCharges;
                    var charges = totalCharges.toLocaleString(shippingOptionLocale, { style: 'currency', currency: shippingOptionCurrency });
                    var freeShipLabel = '';
                    if (totalCharges == 0) {
                        freeShipLabel = document.getElementById('js_free-shipping-checkout').innerHTML;
                    }
                    html = html.concat([
                        '<div class="f_radio-container u_mb-1">',
                        '<input class="f_radio js_shipping-method" type="radio" name="shipping-radio" id="' + shippingId + '"  data-shipmentid="' + shipments.shipmentDetail.shipmentId + '" data-carriercode="' + carrier.carrierDetail.carrierCode + '" data-methodcode="' + shipMethod.methodDetails.methodCode + '">',
                        '<label class="t_generic-text f_radio-label" for="' + shippingId + '">' + shipMethod.methodDetails.methodTitle + ' - <b>(' + charges + ')</b>' + freeShipLabel + '</label>',
                        '</div>'
                    ]);
                }
            }
        }
    }
    var shippingOptionContainer = document.getElementById('shipping-option-container');
    shippingOptionContainer.innerHTML = html.join('\n');
}


function selectShippingMethod() {

    document.addEventListener('click',function(event){
        if(event.target && event.target.matches('.js_checkout-review-order')){
            event.preventDefault();

            commitToCheckoutReview('validate', event.target);
        }
    });

    document.addEventListener('change', function (event) {
        if (event.target && event.target.matches('.js_shipping-method')) {
            event.preventDefault();
        }
    });

    document.addEventListener('click', function (event) {
        if (event.target && event.target.matches('#js_shipping-options-selector')) {
            event.preventDefault();

            var message = document.getElementById('select-shipping-message');
            message.classList.add('d-none');
            message.classList.remove('error-speech-bubble');
            
            var radio = document.querySelector('input[name="shipping-radio"]:checked');

            if (radio !== null) {

                document.getElementById('checkout-shipping-shipmentid').value = radio.getAttribute('data-shipmentid');
                document.getElementById('checkout-shipping-carriercode').value = radio.getAttribute('data-carriercode');
                document.getElementById('checkout-shipping-methodcode').value = radio.getAttribute('data-methodcode');

                commitToCheckoutReview('commit', event.target);

            } else {

                message.classList.remove('d-none');
                message.classList.add('error-speech-bubble');

            }
        }
    });
}
selectShippingMethod();

function checkFreeShippingInCart() {

    var message = document.querySelector('.free-ship__cart');
    var loader = document.querySelector('.cart-free-shipping-loader');
    
    sendRequest('/' + getCurrentLocale() + '/cart/getFreeShipping').then(function (success) {
        loader.classList.add('d-none');
        if (success.data == 'yes') {
            message.classList.remove('d-none');
            message.classList.add('d-inline-block');
        }
    }).catch(function (errors) {
        if (loader) {
            loader.classList.add('d-none');
        }
    });
}
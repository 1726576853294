function productDropdown() {

  var dropDownItem = document.querySelector('.js_menu-dropdown-parent');
  var dropDownBlock = document.querySelector('.js_menu-dropdown-child');
  if (dropDownBlock && dropDownItem) {
    dropDownItem.closest('.u_list-reset').classList.add('mega-menu__menu-list--products');
    dropDownItem.closest('.mega-menu').classList.add('mega-menu--product-dropdown');
  }

  var childBlocks = document.querySelectorAll('.js_menu-dropdown-child');
  if (childBlocks.length > 0) {
    childBlocks[0].classList.add('initial');
  }

  document.addEventListener('mouseover', function (event) {
    if (event.target && event.target.matches('.js_menu-dropdown-parent, .js_menu-dropdown-parent > *')) {

      var item = event.target;
      var parentMenu = item.closest('.mega-menu--product-dropdown');
      if (hasClass(item, 'js_menu-dropdown-parent') == false) {
        item = item.closest('.js_menu-dropdown-parent');
      }

      sortByZIndexToggle(true);

      Array.from(childBlocks).forEach(function (block) {
        block.classList.remove('initial');
      });
      
      var targetBlock = item.getAttribute('data-dropdown');
      var contentBlock = document.querySelector('.js_menu-dropdown-child[data-dropdown="'+targetBlock+'"]');
      if (contentBlock) {
        parentMenu.classList.remove('mega-menu--reset-dropdown-height');
        contentBlock.classList.add('initial');
      } else {
        if (parentMenu) {
          parentMenu.classList.add('mega-menu--reset-dropdown-height');
        }
      }
    }
  });

};

productDropdown();

var checkoutMainContainer = document.getElementById('checkout-main-container');

if (checkoutMainContainer) {

  function emailExistAction(email) {
    var options = {
        method: 'POST',
        credentials: 'include',
        body: prepareFormData(
            {
                email: email
            }
        )
    };

    var button = document.querySelector('.js_checkout-next-1');

    sendRequest('/' + getCurrentLocale() + '/customer/portal/emailExist', options).then(function (success) {

        button.disabled = false;
        if (success.data == 'yes') {
            openModel('check-email-exist');
        } else {
            scrollToNextSection(2);
        }

    }).catch(function (errors) {
        button.disabled = false;
        scrollToNextSection(2);
    });
}

  function scrollToNextSection(index) {
    var nextSection = document.querySelector('.js_checkout--section-' + (index));
    nextSection.classList.add('checkout--section-' + (index) + '--is-visible');
    document.querySelector('.js__checkout__drop-icon-' + (index)).classList.add('checkout__drop-icon-' + (index) + '--is-pointing-down');
    scrollDownToSection(nextSection, 20, 0);
  }

  function toggleAccordion(index) {
    document.addEventListener('click', function (event) {
      if (event.target && event.target.matches('.js__checkout__drop-' + index)) {
        document.querySelector('.js_checkout--section-' + index).classList.toggle('checkout--section-' + index + '--is-visible');
        document.querySelector('.js__checkout__drop-icon-' + index).classList.toggle('checkout__drop-icon-' + index + '--is-pointing-down');
      }
    });
  }

  function expandAndCollapseAccordion(index) {
    document.addEventListener('click', function (event) {
      if (event.target && event.target.matches('.js_checkout-next-' + index)) {
        event.preventDefault();
        var button = event.target;
        var form = button.closest('form');
        var isValid = form.checkValidity();
        if (isValid == false) {
          form.reportValidity();
        } else {
          
          var scrollToNext = true;
          if (index == 1) {
            var email = document.getElementById('checkout-form-email');
            var confirmEmail = document.getElementById('checkout-form-confirm-email');
        
            if(email.value != confirmEmail.value) {
              checkoutEmailsNotMatching(confirmEmail);
              scrollToNext = false;
            } else {
              if (userLoggedIn == false) {
                scrollToNext = false;
                button.disabled = true;
                emailExistAction(email.value);
              }
            }
          }

          if (scrollToNext) {
            scrollToNextSection(index+1);
          }
        }
      }
    });
  }

  function expandClosestSection(element) {
    if (element.hasAttribute('data-section')) {
      var index = element.getAttribute('data-section');
    } else {
      var index = element.closest('section').getAttribute('data-section');
    }
    document.querySelector('.js_checkout--section-' + index).classList.add('checkout--section-' + index + '--is-visible');
    document.querySelector('.js__checkout__drop-icon-' + index).classList.add('checkout__drop-icon-' + index + '--is-pointing-down');
  }

  function expandAllSections(noTransition) {
    for (var index = 1; index <= 3; index++) {
      if (noTransition == true) {
        document.querySelector('.js_checkout--section-' + index).classList.add('notransition');
        document.querySelector('.js__checkout__drop-icon-' + index).classList.add('notransition');
      }
      document.querySelector('.js_checkout--section-' + index).classList.add('checkout--section-' + index + '--is-visible');
      document.querySelector('.js__checkout__drop-icon-' + index).classList.add('checkout__drop-icon-' + index + '--is-pointing-down');
    }
  }

  // expand and collapse accordions when pressing "next" button.

  // goto shipping
  expandAndCollapseAccordion(1);

  // goto billing
  expandAndCollapseAccordion(2);

  // expand and collapse accordions when pressing "down-chevron" icon.

  // toggle contact-info
  toggleAccordion(1);

  // toggle shipping
  toggleAccordion(2);

  // toggle billing
  toggleAccordion(3);


  function checkoutShippingDatePicker() {
    var checkoutShippingDate = document.getElementById('checkout-form-specific-date');
    var leadTime = checkoutShippingDate.getAttribute('data-leadtime');
    leadTime = parseInt(leadTime);
    if (leadTime == 0) {
      leadTime = 1;
    } else {
      leadTime = leadTime;
    }

    if (checkoutShippingDate.value == "") {
      var defaultDate = new Date(new Date().getTime()+(leadTime*24*60*60*1000));
    } else {
      var defaultDate = checkoutShippingDate.value;
    }

    flatpickr(checkoutShippingDate, {
      "disable": [
          function (date) {
              var today = new Date();
              today.setHours(0, 0, 0, 0);
              var freeDay = today.setDate(new Date().getDate()+leadTime);
              return (date < freeDay); // return true to disable
          }
      ],
      dateFormat: "d/m/Y",
      defaultDate: defaultDate
    });
  }
  checkoutShippingDatePicker();

  function setBillingWithShipping() {
    // set billing details with shipping when clicking on the checkbox
    var checkBox = document.getElementById('checkout-form-billing-with-shipping-check');
    checkBox.addEventListener('change', function (event) {
      if (checkBox.checked == true) {
        var fieldCheck = [
          'street',
          'city',
          'region',
          'zip'
        ];
        Array.from(fieldCheck).forEach(function (field) {
          var value = document.getElementById('checkout-form-'+field).value;
          var billingField = document.getElementById('checkout-form-billing-'+field);
          billingField.value = value;
          if (value == '') {
            billingField.closest('.field').querySelector('.field__label').classList.remove('field__label-force');
          } else {
            billingField.closest('.field').querySelector('.field__label').classList.add('field__label-force');
          }
        });
      }
    });

    // update billing details when changes are made to shipping
    document.addEventListener('input', function (event) {
      if (event.target && event.target.matches('.shipping-fields')) {
        if (checkBox.checked == true) {
          var shippingField = event.target;
          var id = shippingField.getAttribute('id').replace('checkout-form-', '');
          var billingField = document.getElementById('checkout-form-billing-'+id);
          billingField.value = shippingField.value;
        }
      }
    });
  }
  setBillingWithShipping();
  
  function displayMaxCharForDeliveryMsg() {
    var textArea = document.getElementById('checkout-form-delivery-note');
    var charCounter = document.getElementById('delivery-note-characters');
    charCounter.innerText = 500 - textArea.value.length;
    textArea.addEventListener('input', function (event) {
      charCounter.innerText = 500 - textArea.value.length;
    });
  }
  displayMaxCharForDeliveryMsg();
}

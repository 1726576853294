if (document.getElementById("configurator-quote-slider")) {
  function handleLoadChange(e) {
    var value = e.target.value;

    if (value == '') {
      state.load = null;
      return false;
    }

    state.load = value;

    numFallsEl.innerHTML = emptyOption + optionsCreator(numFalls[value], '');
  }


  function handleNumFallsChange(e) {
    var value = e.target.value;

    if (value == '') {
      state.numFalls = null;
      return false;
    }

    state.numFalls = parseInt(value);

    var liftSpeedData = liftSpeed[state.load + "_" + value].map(function (index) {
      return liftSpeedValue[index];
    });
    liftSpeedEl.innerHTML = emptyOption + optionsCreator(liftSpeedData, 'm/min');
  }


  function handleLiftSpeedChange(e) {
    var value = e.target.value;

    if (value == '') {
      state.liftSpeed = null;
      return false;
    }

    state.liftSpeed = liftSpeedValue.indexOf(value);

    var phaseData = phase[state.load + "_" + state.numFalls + "_" + state.liftSpeed];
    phaseEl.innerHTML = emptyOption + optionsCreator(phaseData, '');
  }


  function handlePhaseChange(e) {
    var value = e.target.value;

    if (value == '') {
      state.phase = null;
      return false;
    }

    state.phase = parseInt(value);

    var freqData = frequency[state.load + "_" + state.numFalls + "_" + state.liftSpeed + "_" + state.phase];
    freqEl.innerHTML = emptyOption + optionsCreator(freqData, '');
  }


  function handleFreqChange(e) {
    var value = e.target.value;

    if (value == '') {
      state.freq = null;
      return false;
    }

    state.freq = parseInt(value);

    var voltData = volt[state.load + "_" + state.numFalls + "_" + state.liftSpeed + "_" + state.phase + "_" + state.freq];
    voltEl.innerHTML = emptyOption + optionsCreator(voltData, '');
  }


  function handleVoltChange(e) {
    var value = e.target.value;

    if (value == '') {
      state.volt = null;
      return false;
    }

    state.volt = parseInt(value);

    // updateTable();
  }


  function handleSuspensionChange(e) {
    document.querySelector('#configurator-suspension-section .effects').classList.add('d-none');

    if (suspEl.value == vtTans["Corso Electric trolley"]) {
      document.querySelector('#configurator-suspension-section .effects').classList.remove('d-none');
      // if(state.electricSpeed == ''){
      //   suspCodeEl.innerHTML = '';
      //   suspDescEl.innerHTML = '';
      //   suspQtyEl.innerHTML = '';
      //   suspUnitPriceEl.innerHTML = '';
      //   suspTotalPriceEl.innerHTML = '';
      // }
    }

    state.suspension = suspEl.value;

    var thumbs = document.getElementById('configurator-thumbnails').querySelectorAll('img');

    Array.from(thumbs).forEach(function (img) {
      img.classList.add('d-none');
    });

    var selectedThumb = document.getElementById('configurator-thumbnails').querySelector('img[data-index="' + suspEl.selectedIndex + '"]');

    if (selectedThumb) {
      selectedThumb.classList.remove('d-none');
    }

    // updateTable();
  }


  function handleLiftHeightChange(e) {
    state.liftHeight = parseInt(this.value);

    // updateTable();
  }


  function handleExtendBarChange(e) {
    state.extendBar = this.value;

    // updateTable();
  }


  function handleLimitSwitchChange(e) {
    state.limitSwitch = this.value;

    // updateTable();
  }


  function handleElectricSpeedChange(e) {
    state.electricSpeed = this.value;

    // updateTable();
  }


  function handleControlChange(e) {
    state.control = this.value;

    document.querySelector('#configurator-control-section .effects').classList.add('d-none');

    if (this.value == vtTans['Pendant control']) {
      controlLabelEl.innerHTML = vtTans['Additional pendant control cable'];
      document.querySelector('#configurator-control-section .effects').classList.remove('d-none');
    }
    else if (this.value == vtTans['Remote control']) {
      controlLabelEl.innerHTML = vtTans['Remote control'];
    }
  }


  function handleCtrlCableChange(e) {
    state.ctrlCable = this.value;

    // updateTable();
  }


  function updateTable(testState) {

    isTest = typeof testState !== 'undefined';
    emptyTable();
    /*if(!isTest)
      emptyTable();
    else{
      state = testState;
  
      loadExtVariables();
  
      // return 'test';
    }*/

    if (!state.load || !state.numFalls || !state.phase || !state.freq || !state.volt)
      return false;

    state.total = 0;

    updateBody();
    updateSuspension();
    updateControl();

    var total = formatMoney(state.total);

    if (!isTest)
      totalEl.innerHTML = total;

    return total;
  }


  function loadExtVariables() {
    loadDataVariables();
    loadCodesVariables();
    loadPricelistVariables();
  }


  function loadDataVariables() {
    var data = require('./data.js');

    load = data.load;
    numFalls = data.numFalls;
    liftSpeed = data.liftSpeed;
    phase = data.phase;
    frequency = data.frequency;
    volt = data.volt;
    suspension = data.suspension;
    electricSpeed = data.electricSpeed;
    limitSwitch = data.limitSwitch;
    control = data.control;

    bodyCodeDesc = data.bodyCodeDesc;
    extendBarCodeDesc = data.extendBarCodeDesc;
    chainLengthCodeDesc = data.chainLengthCodeDesc;
    chainBagCodeDesc = data.chainBagCodeDesc;
    controlCodeDesc = data.controlCodeDesc;
  }


  function loadCodesVariables() {
    var codes = require('./codes.js');

    bodyCode = codes.bodyCode;
    suspensionCodes = codes.suspensionCodes;
    chainLengthCodes = codes.chainLengthCodes;
    controlCodes = codes.controlCodes;
    controlTrolley1Codes = codes.controlTrolley1Codes;
    controlTrolley2Codes = codes.controlTrolley2Codes;
  }


  function emptyTable() {
    bodyCodeEl.innerHTML = '';
    bodyCodeDescEl.innerHTML = '';
    bodyQtyEl.innerHTML = '';

    bodyUnitPriceEl.innerHTML = '';
    bodyTotalPriceEl.innerHTML = '';

    suspCodeEl.innerHTML = '';
    suspDescEl.innerHTML = '';
    suspQtyEl.innerHTML = '';

    suspUnitPriceEl.innerHTML = '';
    suspTotalPriceEl.innerHTML = '';

    extendBarRowEl.style.display = 'none';
    chainLengthRowEl.style.display = 'none';
    limitSwitchRowEl.style.display = 'none';

    totalEl.innerHTML = '';

    state.total = null;
  }


  function formatMoney(value) {

    if (isTest)
      return value;

    else if (currencyLocal == 'en')
      return value.toLocaleString('en-gb', { style: 'currency', currency: 'GBP' });

    else if (state.currency == 'pl')
      return value.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });

    else if (state.currency == 'en-US')
    return value.toLocaleString('pl-PL', { style: 'currency', currency: 'USD' });

    else
      return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
  }


  function updateBody() {
    var liftSpeedIndex = liftSpeed[state.load + '_' + state.numFalls].indexOf(state.liftSpeed) + 1;
    var code = bodyCode[state.load + "_" + state.numFalls + "_" + liftSpeedIndex + "_" + state.phase + "_" + state.freq + "_" + state.volt];


    var bodyPrice = bodyPricelist[code];

    displayBodyValues(code, formatMoney(bodyPrice))

    if (state.liftHeight > 3)
      updateChainLength()

    state.total += bodyPrice;
  }


  function displayBodyValues(code, price) {
    if (isTest)
      return;

    bodyCodeEl.innerHTML = code;
    bodyCodeDescEl.innerHTML = bodyCodeDesc[code];
    bodyQtyEl.innerHTML = 1;

    bodyUnitPriceEl.innerHTML = price;
    bodyTotalPriceEl.innerHTML = price;
  }


  function updateSuspension(e) {
    if (state.suspension == '' || state.suspension == vtTans['With hook'])
      return 0;
    if (state.suspension == vtTans["Corso Electric trolley"]) {
      if (state.electricSpeed == '')
        return 0;

      if (state.extendBar == vtTans['Yes'])
        updateExtendBar();

      if (state.limitSwitch == vtTans['Yes'])
        updateLimitSwitch();

      var speed = electricSpeed.indexOf(state.electricSpeed);
      var liftSpeedIndex = liftSpeed[state.load + '_' + state.numFalls].indexOf(state.liftSpeed) + 1;

      var code = suspensionCodes[state.suspension][state.load + "_" + state.numFalls + "_" + liftSpeedIndex + "_" + speed];

      var suspDesc = state.suspension + ' - ' + state.load + 'kg ' + state.numFalls + 'f/' + liftSpeedIndex + 's ' + state.electricSpeed;
    }
    else {
      var code = suspensionCodes[state.suspension][state.load + "_" + state.numFalls];
      var suspDesc = state.suspension + ' - ' + state.load + 'kg ' + state.numFalls + 'f';
    }

    var suspPrice = 0;

    if ([vtTans["Corso Push Trolley"], vtTans["Corso Beam Clamp"]].indexOf(state.suspension) != -1)
      displaySuspensionValues(code, suspDesc, vtTans["Local Price List"]);
    else {
      suspPrice = suspPricelist[code];

      displaySuspensionValues(code, suspDesc, formatMoney(suspPrice));
    }

    state.total += suspPrice;
  }


  function displaySuspensionValues(code, desc, suspPrice) {
    if (isTest)
      return;

    suspCodeEl.innerHTML = code;
    suspQtyEl.innerHTML = 1;

    suspDescEl.innerHTML = desc;

    suspUnitPriceEl.innerHTML = suspPrice;
    suspTotalPriceEl.innerHTML = suspPrice;
  }


  function updateExtendBar() {
    if (state.load == 2000) {
      var code = 108947;
    }
    else {
      var code = 108937;
    }

    extendBarPrice = suspPricelist[code];

    displayExtendBarValues(code, formatMoney(extendBarPrice));

    state.total += extendBarPrice;
  }


  function displayExtendBarValues(code, price) {
    if (isTest)
      return;

    extendBarRowEl.classList.remove('d-none');
    extendBarRowEl.style.display = 'table-row';

    extendBarCodeEl.innerHTML = code;
    extendBarDescEl.innerHTML = extendBarCodeDesc[code];
    extendBarQtyEl.innerHTML = 1;

    extendBarUnitPriceEl.innerHTML = price;
    extendBarTotalPriceEl.innerHTML = price;
  }


  function updateLimitSwitch() {
    var code = 75257;

    var limitSwitchPrice = suspPricelist[code];

    displayLimitSwitchValues(code, formatMoney(limitSwitchPrice));

    state.total += limitSwitchPrice;
  }


  function displayLimitSwitchValues(code, price) {
    if (isTest)
      return;

    limitSwitchRowEl.classList.remove('d-none');
    limitSwitchRowEl.style.display = 'table-row';

    limitSwitchCodeEl.innerHTML = code;
    limitSwitchDescEl.innerHTML = vtTans['Trolley Limit Switch'];
    limitSwitchQtyEl.innerHTML = 1;

    limitSwitchUnitPriceEl.innerHTML = price;
    limitSwitchTotalPriceEl.innerHTML = price;
  }


  function updateChainLength() {
    var length = parseInt(state.liftHeight) - 3;

    var code = chainLengthCodes[state.load + "_" + state.numFalls];

    chainLengthPrice = chainLengthPricelist[code];

    displayChainLenghtValues(code, length, chainLengthPrice);

    state.total += chainLengthPrice * length;

    updateChainBag();
  }


  function displayChainLenghtValues(code, length, price) {
    if (isTest)
      return;

    chainLengthRowEl.classList.remove('d-none');
    chainLengthRowEl.style.display = 'table-row';

    chainLengthCodeEl.innerHTML = code;
    chainLengthDescEl.innerHTML = chainLengthCodeDesc[code];
    chainLengthQtyEl.innerHTML = length;

    chainLengthUnitPriceEl.innerHTML = formatMoney(price);
    chainLengthTotalPriceEl.innerHTML = formatMoney(price * length);
  }


  function updateChainBag() {
    var loadFalls = state.load + "_" + state.numFalls;
    var liftHeight = parseInt(state.liftHeight);
    var code;

    switch (loadFalls) {
      case '250_1': {
        if (liftHeight > 13 && liftHeight < 25)
          code = 276617;
        break;
      }
      case '500_1': {
        if (liftHeight > 13 && liftHeight < 25)
          code = 276647;
        break;
      }
      case '500_2': {
        if (liftHeight > 7 && liftHeight < 12)
          code = 276617;
        else if (liftHeight > 13 && liftHeight < 18)
          code = 276627;
        else if (liftHeight > 19 && liftHeight < 25)
          code = 276637;
        break;
      }
      case '1000_1': {
        if (liftHeight > 7 && liftHeight < 18)
          code = 276677;
        else if (liftHeight > 19 && liftHeight < 25)
          code = 276687;
        break;
      }
      case '1000_2': {
        if (liftHeight > 7 && liftHeight < 12)
          code = 276647;
        else if (liftHeight > 13 && liftHeight < 18)
          code = 276657;
        else if (liftHeight > 19 && liftHeight < 25)
          code = 276667;
        break;
      }
      case '2000_2': {
        if (liftHeight > 4 && liftHeight < 9)
          code = 276677;
        else if (liftHeight > 10 && liftHeight < 9)
          code = 276697;
        else if (liftHeight > 16 && liftHeight < 25)
          code = 276707;
        break;
      }
    }

    if (!code)
      return;

    chainBagPrice = chainBagPricelist[code];

    displayChainBagValues(code, formatMoney(chainBagPrice));

    state.total += chainBagPrice;
  }


  function displayChainBagValues(code, price) {
    if (isTest)
      return;

    chainBagRowEl.classList.remove('d-none');
    chainBagRowEl.style.display = 'table-row';

    chainBagCodeEl.innerHTML = code;
    chainBagDescEl.innerHTML = chainBagCodeDesc[code];
    chainBagQtyEl.innerHTML = 1;

    chainBagUnitPriceEl.innerHTML = price;
    chainBagTotalPriceEl.innerHTML = price;
  }


  function updateControl() {
    var liftSpeedIndex = liftSpeed[state.load + '_' + state.numFalls].indexOf(state.liftSpeed) + 1;
    var qty = state.ctrlCable - 2;

    if (state.control == '' || state.control == 'Without')
      return;
    else if (state.control == vtTans['Pendant control'] && state.suspension == vtTans['Corso Electric trolley'])
      var code = 239597;
    else if (state.control == vtTans['Pendant control'])
      var code = 195127;
    else if (state.control == vtTans['Remote control'] && state.suspension == vtTans['Corso Electric trolley']) {
      qty = 1;
      var speed = electricSpeed.indexOf(state.electricSpeed);
      if (speed == 0)
        var code = controlTrolley1Codes[state.load + '_' + state.numFalls + '_' + liftSpeedIndex];
      else if (speed > 0)
        var code = controlTrolley2Codes[state.load + '_' + state.numFalls + '_' + liftSpeedIndex];
      else
        return;
    }
    else if (state.control == vtTans['Remote control']) {
      qty = 1;
      var code = controlCodes[state.load + '_' + state.numFalls + '_' + liftSpeedIndex];
    }

    if (typeof code === 'undefined') {
      return;
    }

    controlPrice = controlPricelist[code];

    displayControlValues(code, qty, controlPrice);

    state.total += controlPrice * qty;
  }


  function displayControlValues(code, qty, price) {
    if (isTest)
      return;

    controlRowEl.classList.remove('d-none');
    controlRowEl.style.display = 'table-row';

    controlCodeEl.innerHTML = code;
    controlDescEl.innerHTML = controlCodeDesc[code];
    controlQtyEl.innerHTML = qty;

    controlPrice = controlPricelist[code];

    controlUnitPriceEl.innerHTML = formatMoney(price);
    controlTotalPriceEl.innerHTML = qty ? formatMoney(price * qty) : '-';
  }
}
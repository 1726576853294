function setupModel() {

  document.addEventListener('click', function(event) {

    if(event.target && (event.target.matches('.js_open-model'))) {
      event.preventDefault();
      var name = event.target.getAttribute('data-model');
      openModel(name);
    }

    if(event.target && (event.target.matches('.js_close-popup, .popup-container'))) {
      event.preventDefault();
      var item = event.target;
      var isClose = hasClass(item, 'js_close-popup');
      var isContainer = hasClass(item, 'popup-container');

      if (isClose || isContainer) {
        if (isClose) {
          item = item.closest('.popup-container');
        }

        if (hasClass(item, 'popup-mobile-slider') == false) {
          document.body.classList.remove('is-open');
          item.classList.add('d-none');
      }
      }
    }

  });

};

setupModel();

function openModel(name) {
  var model = document.querySelector('.popup-model[data-model="'+name+'"]');
  document.body.classList.add('is-open');
  var container = model.closest('.popup-container');
  container.classList.remove('d-none');
  container.scrollTop = 0;
}

function resetModelSteps(name) {
  var model = document.querySelector('.popup-model[data-model="'+name+'"]');
  var steps = model.querySelectorAll('.popup-prev-slide, .popup-next-slide');

  Array.from(steps).forEach(function (step) {
    if (Number(step.getAttribute('data-index') )== 1) {
        step.classList.add('popup-prev-slide');
        step.classList.remove('popup-next-slide');
        step.classList.remove('d-none');
    } else {
        step.classList.remove('popup-prev-slide');
        step.classList.add('popup-next-slide');
        step.classList.add('d-none');
    }
  });

  var bullets = model.querySelectorAll('.popup-bullet');
  Array.from(bullets).forEach(function (bullet) {
    bullet.classList.remove('popup-bullet--active');
  });
  bullets[0].classList.add('popup-bullet--active');

}

function openReadyModel(name) {
  openModel(name);

  if(window.location.href.indexOf("?error=com") > -1) {
    var path = window.location.pathname.split('?error=com')[0];
    window.history.replaceState({}, document.title, path);
  }
}

function closeModel(name) {
  var model = document.querySelector('.popup-model[data-model="'+name+'"]');
  if (model !== null) {
    document.body.classList.remove('is-open');
    var container = model.closest('.popup-container');
    container.classList.add('d-none');
  }
}

function closeAllModels() {
  var popups = document.querySelectorAll('.popup-container');
  for(var i = 0; i < popups.length; i++) {
    popups[i].classList.add('d-none');
  }
}

function openModelMessage(title, message, icon) {
  var model = document.querySelector('.popup-model[data-model="message"]');
  model.querySelector('.popup-model--notification__icon').setAttribute('src', icon);
  model.querySelector('.popup-model--notification__title span').innerHTML = title;
  model.querySelector('.popup-model--notification__lead').innerHTML = message;
  openModel('message');
}


function enquiryPopup() {

  document.addEventListener('click', function(event) {

    if (event.target && event.target.matches('.js_product-enquiry-bullet-left, .js_add-to-quote-back')) {
      event.preventDefault();
      triggerEnquiryLeft();
    }

    if (event.target && event.target.matches('.js_product-enquiry-bullet-right, .js_product-enquiry-next')) {
      event.preventDefault();
      triggerEnquiryRight();
    }

  });
};

enquiryPopup();

var leftEnquirySection = document.querySelector('.js_popup-enquiry-left');
var rightEnquirySection = document.querySelector('.js_popup-enquiry-right');
var leftEnquiryBullet = document.querySelector('.js_product-enquiry-bullet-left');
var rightEnquiryBullet = document.querySelector('.js_product-enquiry-bullet-right');

function triggerEnquiryLeft() {

  leftEnquirySection.classList.add('d-block');
  leftEnquirySection.classList.remove('d-none');
  rightEnquirySection.classList.add('d-none');
  rightEnquirySection.classList.remove('d-block');
  leftEnquiryBullet.classList.add('popup-enquiry-bullet--active');
  rightEnquiryBullet.classList.remove('popup-enquiry-bullet--active');
  leftEnquirySection.closest('.popup-container').scrollTop = 0;
  
  // leftEnquirySection.classList.add('popup-prev-slide');
}

function triggerEnquiryRight() {
  rightEnquirySection.classList.add('d-block');
  rightEnquirySection.classList.remove('d-none');
  leftEnquirySection.classList.add('d-none');
  leftEnquirySection.classList.remove('d-block');
  rightEnquiryBullet.classList.add('popup-enquiry-bullet--active');
  leftEnquiryBullet.classList.remove('popup-enquiry-bullet--active');
  leftEnquirySection.closest('.popup-container').scrollTop = 0;
}


function closeAllMobilePopups() {
  var popups = document.querySelectorAll('.popup-mobile-slider');
  for(var i = 0; i < popups.length; i++) {
    popups[i].classList.add('d-none');
    popups[i].classList.remove('popup-mobile-slider');
  }
}

function openMobilePopupSlider(name) {
  closeAllMobilePopups();

  var model = document.querySelector('.popup-model[data-model="' + name + '"]');
  var container = model.closest('.popup-container');
  container.classList.add('popup-mobile-slider');
  container.classList.remove('d-none');
  container.scrollTop = 0;
}

function slideHome() {
  closeAllMobilePopups();
  var slideId = '__home__';
  var nextSlide = document.querySelector('.js_slide-nav-slide[data-navid="' + slideId + '"]');
  var currentSlide = document.querySelector('.js_slide-nav-slide.is-active');
  var oldSlide = document.querySelector('.js_slide-nav-slide.was-active');

  if (nextSlide) {
      addClass(nextSlide, 'is-active');
      removeClass(nextSlide, 'was-active');
  }

  if (currentSlide) {
      addClass(currentSlide, 'was-active');
      removeClass(currentSlide, 'is-active');
  }

  if (oldSlide && (oldSlide !== nextSlide)) {
      removeClass(oldSlide, 'is-active');
      removeClass(oldSlide, 'was-active');
  }

  var slideNav = document.querySelector('.js_slide-nav');

  setData(slideNav, 'currentlevel', getData(nextSlide, 'level'));
  removeClass(currentSlide, 'was-active');

}

function popupTogglePrevStep() {
  document.addEventListener('click', function(event) {
    if (event.target && event.target.matches('.js_popup-prev-btn')) {
      event.preventDefault();
      var button = event.target;
      var curSection = button.closest('[data-index]');
      curSection.classList.add('d-none');
      curSection.classList.remove('popup-prev-slide');
      curSection.classList.add('popup-next-slide');
      var index = Number(curSection.getAttribute('data-index')) - 1;
      var base = curSection.closest('.popup-model__right');
      var nxtSection = base.querySelector('[data-index="' + index  + '"]');
      nxtSection.classList.remove('d-none');
      var activeBullet = base.querySelector('.popup-bullet--active');
      activeBullet.classList.remove('popup-bullet--active');
      activeBullet.previousSibling.classList.add('popup-bullet--active');
      var container = activeBullet.closest('.popup-container');
      container.classList.remove('d-none');
      container.scrollTop = 0;
    }
  });
}
popupTogglePrevStep();

function popupToggleNextStep(button) {
  var curSection = button.closest('[data-index]');
  curSection.classList.add('d-none');
  curSection.classList.add('popup-prev-slide');
  curSection.classList.remove('popup-next-slide');
  var index = Number(curSection.getAttribute('data-index')) + 1;
  var base = curSection.closest('.popup-model__right');
  var prevSection = base.querySelector('[data-index="' + index  + '"]');
  prevSection.classList.remove('d-none');
  setupQuestionnaireSummary(prevSection);
  var activeBullet = base.querySelector('.popup-bullet--active');
  activeBullet.classList.remove('popup-bullet--active');
  activeBullet.nextSibling.classList.add('popup-bullet--active');
  var container = activeBullet.closest('.popup-container');
  container.classList.remove('d-none');
  container.scrollTop = 0;
}

function setupPopupToggleNextStep() {
  document.addEventListener('click', function (event) {
    if (event.target && event.target.matches('.js_popup-next-btn')) {
      event.preventDefault();
      var button = event.target;
      var form = button.closest('form');
      var isValid = form.checkValidity();
      if (isValid == false) {
        form.reportValidity();
        return;
      }
      popupToggleNextStep(button);
    }
  });
}
setupPopupToggleNextStep();

function popupGotoSection(item, index) {
  var base = item.closest('.popup-model__right');
  var sections = base.querySelectorAll('[data-index]');
  for(var i = 0; i < sections.length; i++) {
    var section = sections[i];
    if (i == index) {
      section.classList.remove('d-none');
      section.classList.add('popup-prev-slide');
      section.classList.remove('popup-next-slide');
    } else {
      section.classList.add('d-none');
      section.classList.remove('popup-prev-slide');
      section.classList.add('popup-next-slide');
    }
  }
  var bullets = base.querySelectorAll('.popup-bullet');
  Array.from(bullets).forEach(function (bullet) {
    bullet.classList.remove('popup-bullet--active');
  });
  if (bullets.length > 0) {
    bullets[index].classList.add('popup-bullet--active');
  }
}